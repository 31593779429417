import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Switch, message } from "antd";
import { useTranslation } from "react-i18next";
import Money from "../Shared/Money";
import { SaveOutlined, LeftOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import DatePickerW from "../Shared/DatePickerW";
import OtherMovementCategory from "./OtherMovementCategory";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const OtherMovementForm = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
                form.setFieldValue("contactId",props.contactId);
            }else form.setFieldsValue(props.value);
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoIncomeExpense",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoIncomeExpense",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }

    const currency=Form.useWatch("currency",form);
    const isIncome=Form.useWatch("isIncome",form)||false;
    return <div>
        <Modal visible={props.visible} onCancel={props.onCancel} footer={[
            <Button  icon={<CloseOutlined />} onClick={props.onCancel}>{t("Close")}</Button>,
             <Button type="primary" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>

        ]} title={t("Movement")}>
             <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id} >{t("Delete")}</Button></Popconfirm> 
            </div>
            <Form form={form} layout="vertical">
                <Form.Item name={"id"} hidden><Input disabled/></Form.Item>
                <Form.Item name={"processId"} hidden><Input disabled/></Form.Item>
                <Form.Item name={"paymentId"} hidden><Input disabled/></Form.Item>
                <Form.Item name={"transferId"} hidden><Input disabled/></Form.Item>
                <Form.Item name={"date"} hidden><Input disabled/></Form.Item>

                <Form.Item label={t("Type")} name={"isIncome"} valuePropName="checked"><Switch checkedChildren={t("In")} unCheckedChildren={t("Ex")} onChange={()=>form.setFieldValue("categoryCode",undefined)} /></Form.Item>
                <Space>
                    <Form.Item label={t("Amount")} name={"amount"} rules={[{required:true}]}><Money currency={currency} /></Form.Item>
                    <Form.Item label={t("Currency")} name={"currency"} rules={[{required:true}]} ><Currency/></Form.Item>
                </Space>
                <Form.Item label={t("Contact")} name={"contactId"} rules={[{required:true}]}><SimpleContactSelect /></Form.Item>
                <Form.Item label={t("Concept")} name={"name"} ><Input /></Form.Item>
                    <Form.Item label={t("Category")} name={"categoryCode"} rules={[{required:true}]} ><OtherMovementCategory isIncome={isIncome} /></Form.Item>
                    <Form.Item label={t("Status")} name={"status"} initialValue={0}>
                    <Select disabled>
                        <Select.Option value={0}>{t("Draft")}</Select.Option>
                        <Select.Option value={1}>{t("Executed")}</Select.Option>
                        <Select.Option value={2}>{t("Requested")}</Select.Option>
                    </Select>
                </Form.Item>
              
            </Form>
        </Modal>

    </div>
}
export default OtherMovementForm;