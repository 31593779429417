import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Popover, Row, Space, Table, message } from "antd";
import { useTranslation } from "react-i18next";
import Money from "../Shared/Money";
import { SaveOutlined, LeftOutlined, DeleteOutlined, PlusOutlined, GlobalOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import DatePickerW from "../Shared/DatePickerW";
import AccountSelect from "../Shared/AccountSelect";
import FrequencySelect from "../Shared/FrequencySelect";
import Percentage from "../Shared/Percentage";
import PolicySelect from "../Health/PolicySelect";
import PolicyLink from "../Life/PolicyLink";
import { round2 } from "../../Lib/Helpers";
import Accounts from "../Life/Accounts";

const ProviderClaimsFundForm = (props) => {
    const [loading,setLoading]=useState(false);
    const [policies,setPolicies]=useState([]);
    const [selectedPolicy,setSelectedPolicy]=useState(null);
    const [addPolicyVisible,setAddPolicyVisible]=useState(false);
    const [accountSelected,setAccountSelected] = useState(null);
    
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
                form.setFieldValue("providerCode",props.providerCode);
            }else{
                form.setFieldsValue(props.value);
                getRelatedPolicies();
            }
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoClaimsFund",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoClaimsFund",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }
    const onChangeAmount=(v)=>{
        //calculating percentage using constitutionAmount
        const percent=v/form.getFieldValue("constitutionAmount")*100;
        form.setFieldsValue({
            reconstitutionThresholdPercent:round2(percent),
        });
    }
    const onChangePercentage=(v)=>{
        //calculating amount using constitutionAmount
        const amount=form.getFieldValue("constitutionAmount")*v/100;
        form.setFieldsValue({
            reconstitutionThresholdAmount:round2(amount),
        });
    }
    const getRelatedPolicies=()=>{
        setLoading(true);
        exe("RepoLifePolicy",{operation:"GET",filter:`claimsFundId=${form.getFieldValue("id")}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setPolicies(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }
    const onRemovePolicy=(record)=>{
        if(!selectedPolicy) return;
        setLoading(true);
        exe("SetPolicyClaimsFund",{policyId:record.id,claimsFundId:null}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                getRelatedPolicies();
            }else{
                message.error(r.msg);
            }
        })
    }
    const onAddPolicy=()=>{
        if(!selectedPolicy) return;
        setLoading(true);
        exe("SetPolicyClaimsFund",{policyId:selectedPolicy,claimsFundId:form.getFieldValue("id")}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                getRelatedPolicies();
                setAddPolicyVisible(false);
            }else{
                message.error(r.msg);
            }
        })
    }

    const currency=Form.useWatch("currency",form);
    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
        {/* <Button type="link" icon={<GlobalOutlined />} disabled={!form.getFieldValue("accountId")} onClick={()=>setAccountSelected(form.getFieldValue("accountId"))}>{t("Account")}</Button> */}
        
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item label={t("id")} name={"id"} hidden ><Input disabled/></Form.Item>
            <Form.Item label={t("providerCode")} name={"providerCode"} hidden initialValue={props.providerCode} ><Input disabled/></Form.Item>
            <Space>
                <Form.Item label={t("Currency")} name={"currency"} rules={[{required:true}]} ><Currency/></Form.Item>
                <Form.Item label={t("Constitution Amount")} name={"constitutionAmount"} rules={[{required:true}]}><Money currency={currency} /></Form.Item>
            </Space>
            <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input /></Form.Item>
            <Form.Item label={t("Account")} name={"accountId"} rules={[{required:true}]}><AccountSelect /></Form.Item>
            <Space>
                <Form.Item label={t("Reconstitution Threshold")} name={"reconstitutionThresholdAmount"} rules={[{required:true}]}><Money currency={currency} onChange={onChangeAmount} /></Form.Item>
                <Form.Item label={t("Rate")} name={"reconstitutionThresholdPercent"} rules={[{required:true}]}><Percentage onChange={onChangePercentage} /></Form.Item>
            </Space>            
            <Form.Item label={t("Reconstitution Frequency")} name={"reconstitutionFrequency"} rules={[{required:true}]}><FrequencySelect /></Form.Item>
        </Form>
        <div>
            <Modal visible={addPolicyVisible} onCancel={()=>setAddPolicyVisible(false)} onOk={onAddPolicy} title={t("Add Policy")}>
                <Form.Item label={t("Policy")}><PolicySelect onChange={v=>setSelectedPolicy(v)} /></Form.Item>
            </Modal>
 
            <Button type="link" icon={<PlusOutlined />} disabled={!form.getFieldValue("id")} onClick={()=>setAddPolicyVisible(true)}>{t("Add Policy")}</Button>
            <Table dataSource={policies} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Policy ID")} dataIndex="id" />
                <Table.Column title={t("Policy")} dataIndex="code" render={(v,r)=><PolicyLink policyId={r.id} code={v} /> } />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<DeleteOutlined />} onClick={()=>onRemovePolicy(r)} />
                </div>} />
            </Table> 
        </div>
        {accountSelected&&<div>
        <Button type="link" icon={<LeftOutlined />} onClick={()=>setAccountSelected(false)}>{t("Back")}</Button>
        <Accounts value={[accountSelected]} />
    </div>
    }
    </div>
}
export default ProviderClaimsFundForm;