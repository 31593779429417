import React, { useState, useEffect } from "react";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Typography,
  Input,
  Button,
  Row,
  Col,
  Switch,
  Descriptions,
  Tabs,
  Popconfirm,
  Space,
} from "antd";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import { formatDate } from "../../Lib/Helpers";
import Inspector from "react-inspector";
import moment from "moment";
import TriggerLog from "./TriggerLog";
import { useTranslation } from "react-i18next";

const TriggerForm = (props) => {
  const [t] = useTranslation();

  const [updateMode, setUpdateMode] = useState(false);
  const [result, setResult] = useState(null);
  const field = props.form.getFieldDecorator;

  useEffect(() => {
    loadTrigger(props.value);
  }, props.value);

  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RepoTrigger", { operation: updateMode ? "UPDATE" : "ADD", entity: { ...props.value, ...values } }, undefined, true).then((r) => {
        props.form.setFieldsValue(r.outData[0]);
        if (props.onExit) props.onExit();
      });
    });
  };
  const onExecute = (job) => {
    const jobCmd = eval("window.a=" + job.command);
    exe(jobCmd.cmd, jobCmd.data, null, true).then((r) => {
      props.form.setFieldsValue({ lastExecution: moment(), lastResultOk: r.ok, lastResultDto: JSON.stringify(r) });
      setResult(r);
    });
  };
  const loadTrigger = (trigger) => {
    if (trigger.id) setUpdateMode(true);
    props.form.setFieldsValue(trigger);
  };
  const onDelete = () => {
    exe("RepoTrigger", { operation: "DELETE", entity: props.value }, null, true).then((r) => {
      if (props.onExit) props.onExit();
    });
  };
  const values = props.form.getFieldsValue();

  return (
    <div>
      <Tabs>
        <Tabs.TabPane key="trigger" tab={t("Trigger")}>
          <Form>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>

                <Form.Item label={t("Listened Command")}>{field("listensTo", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                <Form.Item label={t("Condition")}>{field("condition", { rules: [{ required: true }] })(<Input.TextArea />)}</Form.Item>
                <Form.Item label={t("Client Code")}>{field("uiCode", { rules: [{ required: false }] })(<Input.TextArea disabled={!values.logToCommand} />)}</Form.Item>
                <Space>
                  <Form.Item label={t("Enabled")}>{field("enabled", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                  <Form.Item label={t("Log Full DTO")}>{field("logDto", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                  <Form.Item label={t("Log To Command")}>{field("logToCommand", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                <Form.Item label={t("Command")}>{field("action", { rules: [{ required: true }] })(<Input.TextArea />)}</Form.Item>
                <Form.Item label={t("Context")}>{field("context", { rules: [{ required: true }] })(<Input.TextArea />)}</Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave} style={{ marginRight: 5, marginRight: "auto" }}>
              {t("Save")}
            </Button>
            <Popconfirm title={t("Are you sure delete this trigger?")} onConfirm={onDelete} okText={t("Yes")} cancelText={t("No")}>
              <Button type="danger" icon={<DeleteOutlined />}>
                {t("Delete")}
              </Button>
            </Popconfirm>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="log" tab={t("Log")}>
          <TriggerLog triggerId={props.triggerId} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Form.create()(TriggerForm);
