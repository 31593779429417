import React from "react";
import { Button, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import { LeftOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import PolicyLink from "../Life/PolicyLink";

const CheckBalances = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(props.policyId){
            load();
        }
    },[props.policyId])

    const load=()=>{
        setLoading(true);
        exe("CheckBalances",{policyId:props.policyId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.balances);
            }else{
                message.error(r.msg);
            }
        })
    }


    return <div>
        <Modal visible={props.visible} onCancel={props.onCancel} title={t("Policyholder Balances")} onOk={props.onCancel}>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"policyId"} loading={loading}>
                <Table.Column title={t("Policy")} dataIndex="policyId" render={(v,r)=><PolicyLink policyId={v} code={r.code} />}  />
                <Table.Column title={t("Balance")} dataIndex="balance" render={(v,r)=>formatMoney(v,r.currency)} />
            </Table>
        </Modal>
        </div>
       
    };

    export default CheckBalances;