import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, FileSyncOutlined, FilterOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, Popover, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import TemplateSelect from "../Shared/TemplateSelect";
import moment from "moment";
import config from "../../config";
import { exe, safeGet, safeGetRaw } from "../../Lib/Dal";
import Title from "antd/lib/typography/Title";
import FileUpload from "../Life/FileUpload";
import ContactRelatedDocuments from "./ContactRelatedDocuments";
import { useTranslation } from "react-i18next";
import DocStatusSelect from "../Shared/DocStatusSelect";
import { getConfigProfile } from '../../Lib/Helpers';
const { Column, ColumnGroup } = Table;

const ContactDocuments = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState(undefined);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [statusFilter,setStatusFilter]=useState();
  const [docTypes,setDocTypes]=useState([]);
  const [userSelectableTemplates,setUserSelectableTemplates]=useState([]);
  const [validFileTypes,setValidFileTypes]=useState([]);
  const [changeControlForFileRemoval,setChangeControlForFileRemoval]=useState(false);

  useEffect(() => {
    if (props.contactId) load(props.contactId);
  }, [props.contactId]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      if (profile) {
        const profileDocTypes = safeGet(["Contacts","docAssociations"], profile, []);
        setDocTypes(profileDocTypes);
        const validFileTypes = safeGet(["Contacts","validFileTypes"], profile, []);
        setValidFileTypes(validFileTypes);
        const changeControlForFileRemovalConfigValue = safeGetRaw(["Contacts","changeControlForFileRemoval"], profile, false);
        setChangeControlForFileRemoval(changeControlForFileRemovalConfigValue);
      }
    });
  }, []);
  useEffect(() => {
    if(props.contact){
      getConfigProfile().then((profile) => {
        const userSelectableFormula=safeGet(["Contacts", "userSelectableTemplates"], profile, []);
        //if it is an array, it is already in the correct format
        if(Array.isArray(userSelectableFormula)) setUserSelectableTemplates(userSelectableFormula);
        else compututeUserSelectableFormula(userSelectableFormula);
      });
    }
  }, [props.contact,props.personType]);
  const compututeUserSelectableFormula = (userSelectableFormula) => {
    const context={con:props.contact,personType:props.personType};
    setLoading(true);
    exe("ExeFormula", { formula: userSelectableFormula,context:JSON.stringify(context) }).then(r => {
      setLoading(false);
      if (r.ok) {
        //checking if the result is an array
        if(Array.isArray(r.outData)) setUserSelectableTemplates(r.outData[0]);
        else{
          console.log("Error: userSelectableTemplates formula must return an array");
          setUserSelectableTemplates([]);
        }
      } else {
        console.log("Error: userSelectableTemplates returned an error",r.msg);
      }
    });
  };

  const load = (contactId) => {
    setLoading(true);
    exe("RepoContactDocument", { operation: "GET", filter: "contactId=" + contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const generateContactDoc = (name) => {
    setLoading(true);
    exe("GenerateContactDoc", { contactId: props.contactId, template: name }).then((r) => {
      setLoading(false);
      setTemplateName(undefined);
      if (r.ok) {
        message.success(r.msg);
        load(props.contactId);
      } else {
        message.error(r.msg);
      }
    });
  };

  const getIconType = (record) => {
    switch (record.name) {
      case "Uploaded":
        return "upload";
        break;
      default:
        return "file-pdf";
        break;
    }
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  const beforeAddFile = (file) => {
    if(!file) return;
    if(validFileTypes.length===0) return;
    if(!validFileTypes.includes(file.fileExtension.toLowerCase())){
      message.error(t("This file type is not allowed by the system configuration")+" : "+ file.fileExtension);
      file.abortLoad();
      return;
    }
  }

  const onAddDocument = (file) => {
    if (!file) return;
    setLoading(true);
    file.forEach((p,i) => {
      p.contactId = props.contactId;
        exe("RepoContactDocument", { operation: "ADD", entity:p }).then((r) => {
          if(i+1===file.length) setLoading(false);
          if (r.ok) {
            if(i+1===file.length) load(props.contactId);
          } else {
            message.error(r.msg);
          }
        });
    });
  };
  const onStatusChange=(doc,status)=>
  {
    doc.status=status;
    setLoading(true)
    exe("RepoContactDocument", { operation: "UPDATE", entity: doc }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Status updated"));
        setData(data.map((p) => p.id == doc.id?({...p,status:status}):p));
      }
    });
  }
  const onAssociationChange=(doc,association)=>
  {
    doc.association=association;
    setLoading(true)
    exe("RepoContactDocument", { operation: "UPDATE", entity: doc }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Association updated"));
        setData(data.map((p) => p.id == doc.id?({...p,association:association}):p));
      }
    });
  }
  const onDelete = (record) => {
    if(changeControlForFileRemoval){
      //this action requires approval through cahnge control. Asking user to confirm
      Modal.confirm({
        title: t("Restricted change"),
        content: t("This actions requires authorization. Would you like to create a change request?"),
        onOk() {
          const change = {
            contactId: record.contactId,
            jBefore: JSON.stringify(record),
            jAfter: JSON.stringify({}),
            operation: "DELETEFILE",
          };
          exe("AddContactChange", change).then((r) => {
            if (r.ok) {
              message.success(r.msg);
              props.form.setFieldsValue({ Changes: r.outData });
            } else {
              message.error(r.msg);
            }
          });
        },
      });
      return;
    }
    setLoading(true);
    exe("RepoContactDocument", { operation: "DELETE", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Record deleted"));
        load(props.contactId);
      }else {
        message.error(r.msg);
      }
    });
  }
  return (
    <div>
      <Title level={4}>{t("Contact Documents")}</Title>
      <Button type="link" icon={<UploadOutlined />} onClick={() => setUploadVisible(!uploadVisible)}>
        {t("Upload")}
      </Button>
      <FileUpload hidden={!uploadVisible} onChange={(file) => onAddDocument(file)} beforeAddFile={file=>beforeAddFile(file)} />
      <Popover
        content={
          <div>
            <TemplateSelect onChange={(v) => generateContactDoc(v)} value={templateName} disabled={loading} loading={loading} userSelectableTemplates={userSelectableTemplates} />
          </div>
        }
        title={t("On demand document generation")}
        trigger="click">
        <Button type="link" icon={<FileSyncOutlined />}>
          {t("Generate")}
        </Button>
      </Popover>
      <Popover
          content={
            <div>
              <DocStatusSelect value={statusFilter} onChange={(v)=>setStatusFilter(v)} style={{width:"100%"}} />
            </div>
          }
          title={t("Document Status Filter")}
          trigger="click">
        <Button type="link" icon={<FilterOutlined />}>
          {t("Filter")}
        </Button>
      </Popover>
      <Button type="link" icon={<ReloadOutlined />} onClick={()=>load(props.contactId)}>
          {t("Reload")}
        </Button>
      <Table dataSource={statusFilter?data.filter(p=>p.status===statusFilter):data} scroll={{ x: true }} rowKey="id">
        <Column render={(t, r) => <LegacyIcon type={getIconType(r)} style={{ fontSize: "20px" }} />} />
        <Column title={t("ID")} dataIndex="id" />
        <Column title={t("Type")} dataIndex="name" />
        <Column
          title={t("File")}
          dataIndex="url"
          render={(v, r) => (
            <Button type="link" onClick={() => openFile(r.url)}>
              {t("Open")}
            </Button>
          )}
        />
        <Column title={t("File Name")} dataIndex="fileName" />
        {docTypes.length>0&&<Column title={t("Association")} dataIndex="association" render={(v,r)=><Select value={v} onChange={v=>{r.docType=v;onAssociationChange(r,v)}}>{docTypes.filter(p=>p.personType==props.personType).map(p=><Select.Option key={p.code} value={p.code}>{p.name}</Select.Option>)}</Select>} />}
        <Table.Column
            title={t("Status")}
            dataIndex="status"
            width={150}
            render={(v, r) => (<DocStatusSelect style={{width:"100%"}} value={v} onChange={(status)=>onStatusChange(r,status)} />)}
        />
        <Column title={t("Created")} dataIndex="created" render={(t) => moment.utc(t).fromNow()} />
        <Column title={t("Issue")} dataIndex="issueId" />
        <Column title={t("Actions")} key="actions" render={(r) =><div>
          <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={()=>onDelete(r)}>
            <Button type="link" icon={<DeleteOutlined />}  />
          </Popconfirm>
          </div>} />
      </Table>
      <ContactRelatedDocuments contactId={props.contactId} />
    </div>
  );
};

export default ContactDocuments;
