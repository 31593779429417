import React, { useState, useEffect } from "react";
import ButtonGroup from "antd/lib/button/button-group";
import { FileOutlined, FilterOutlined, UserOutlined, ReloadOutlined, FolderOpenOutlined, ExportOutlined, ImportOutlined, UndoOutlined, SearchOutlined, DollarOutlined } from '@ant-design/icons';
import { Table, Button, Divider, Tag, Form, message, Drawer, Select, InputNumber } from "antd";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import OtherMovementForm from "./OtherMovementForm";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import OtherMovementCategory from "./OtherMovementCategory";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const OtherMovements = (props) => {
  const [t, i18n] = useTranslation();
  const [selected, setSelected] = useState();
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [matches, setMatches] = useState(null);
  const [loading,setLoading]=useState(false);
    const [form] = Form.useForm();

  useEffect(() => {
    //cehcking if movId in the url is set
    if(props.match.params.movId){
      //setting the id of the filter form
      form.setFieldsValue({id:props.match.params.movId});
      onSearch();
    }else load();
  }, []);

  const load = () => {
    setLoading(true);
    exe("RepoIncomeExpense", { operation: "GET", include:["Category","Contact"] }).then((r) => {
        setLoading(false);
        if(r.ok){
            setData(r.outData);
        }else message.error(r.msg);
    });
  };
  const onReload = () => {
    load();
  };

  //-------------------------------------------Filter
  const onSearch = () => {
    form.validateFields().then((values) => {
        let queries = [];
        if(props.match.params.movId){
          values.id = props.match.params.movId; //setting the id of the filter form here bc setting is async
          //clearing the movId from the url
          props.history.push("/othermovs");
        }
        if (values.isIncome !== undefined) queries.push(`isIncome=${values.isIncome?1:0}`);
        if(values.categoryCode) queries.push(`categoryCode='${values.categoryCode}'`);
        if(values.contactId) queries.push(`contactId=${values.contactId}`);
        if(values.id) queries.push(`id=${values.id}`);
          
        const filterQuery = queries.join(" AND ");
        exe("RepoIncomeExpense", { operation: "GET", filter:filterQuery, include:["Category","Contact"] }).then((r) => {
            if(r.ok){
                setData(r.outData);
                setMatches(r.total);
            }else message.error(r.msg);
        });
    });
    };
    //-------------------------------------------Filter
            
  const onChangePagination = (pag) => {
    setPagination(pag);
    load(pag.current);
  };
  const getStatus=value=>{
    if(value==0) return <Tag>{t("Draft")}</Tag>;
    if(value==1) return <Tag color={"green"}>{t("Executed")}</Tag>;
    if(value==2) return <Tag color={"orange"}>{t("Requested")}</Tag>;
    return <Tag>{t("Unknown")}</Tag>;
  }
  const onRequest = (record) => {
    setLoading(true);
    exe("RequestExpense", { expenseId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Requested"));
        load();
      } else message.error(r.msg);
    });
  }

  return (
    <DefaultPage
      title={t("Other Movements")}
      icon="bulb"
      extra={
        <ButtonGroup>
          <Button icon={<FileOutlined />} onClick={() => setSelected({ id: 0 })}>
            {t("New")}
          </Button>
          <Button icon={<ReloadOutlined />} onClick={() => onReload()}>{t("Reload")}</Button>
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        </ButtonGroup>
      }>
      <div>
        <Table rowKey="id" dataSource={data} pagination={pagination} onChange={(pag) => onChangePagination(pag)} loading={loading}>
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Type")} dataIndex="isIncome" render={v=>v?<ImportOutlined />:<ExportOutlined style={{color:"red"}} />} />
          <Table.Column title={t("Category")} dataIndex={["Category","name"]} />
          <Table.Column title={t("Concept")} dataIndex="name" />
          <Table.Column title={t("Contact")} dataIndex={["Contact","FullName"]} />
          <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => formatMoney(v, r.currency)}/>
          <Table.Column title={t("Status")} dataIndex="status" render={v=>getStatus(v)} />
          <Table.Column title={t("Date")} dataIndex="date" render={v=>formatDateShort(v)} />

          <Table.Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <Button type="link" icon={<FolderOpenOutlined />} onClick={()=>setSelected(record)} >{t("Open")}</Button>
                <Button type="link" icon={<DollarOutlined />} onClick={()=>onRequest(record)} disabled={record.isIncome||[1,2].includes(record.status)} >{t("Request")}</Button>
              </span>
            )}
          />
        </Table>
      </div>
      <OtherMovementForm visible={selected} onCancel={() => setSelected(null)} onSave={(values) => load()} value={selected} />

        {/* ---------------------------------------FILTER------------------------------------------ */}
      <Drawer title={t("Other Movement Filter")} placement="right" width={512} onClose={()=>setFilterVisible(false)} visible={filterVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form form={form} layout="vertical">
        <Form.Item label={t("Id")} name={"id"}>
            <InputNumber min={0} />
        </Form.Item>
        <Form.Item label={t("Type")} name={"isIncome"}>
            <Select allowClear>
                <Select.Option value={true}>{t("Income")}</Select.Option>
                <Select.Option value={false}>{t("Expense")}</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item label={t("Category")} name={"categoryCode"}>
            <OtherMovementCategory />
        </Form.Item>
        <Form.Item label={t("Contact")} name={"contactId"}>
            <SimpleContactSelect />
        </Form.Item>

                
              
            </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()} loading={loading}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
        {/* ---------------------------------------FILTER------------------------------------------ */}
    </DefaultPage>
  );
};

export default OtherMovements;
