import React from "react";

import {
  AlertOutlined,
  BankOutlined,
  ClockCircleOutlined,
  EditOutlined,
  EuroOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
  FormOutlined,
  GlobalOutlined,
  HomeOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  InfoOutlined,
  LockOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  ReloadOutlined,
  SafetyOutlined,
  SaveOutlined,
  UndoOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WarningOutlined,
  LeftOutlined,
  DownOutlined,
  RetweetOutlined
} from '@ant-design/icons';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Card,
  Table,
  Menu,
  Dropdown,
  Breadcrumb,
  Badge,
  Select,
  DatePicker,
  Tabs,
  Row,
  Col,
  Input,
  Divider,
  message,
  Drawer,
  Radio,
  Switch,
  Modal,
  InputNumber,
  Tooltip,
} from "antd";
import { exe, safeGet, safeGetRaw } from "../Lib/Dal";
import moment from "moment";
import Relationships from "./Contact/Relationships";
import MedicalRecords from "./Health/MedicalRecords";
import ClaimSummary from "./Health/ClaimSummary";
import RequirementsTable from "./Health/RequirementsTable";
import DefaultPage from "./Shared/DefaultPage";
import CountrySelect from "./Shared/CountrySelect";
import IdTypeSelect from "./Shared/IdTypeSelect";
import BirthdaySelect from "./Shared/BirthdaySelect";
import ContactPolicies from "./Contact/ContactPolicies";
import ContactClaims from "./Contact/ContactClaims";
import ContactChanges from "./Contact/ContactChanges";
import Title from "antd/lib/typography/Title";
import GenericCatalogs from "./Contact/GenericCatalogs";
import RoleSelect from "./Contact/RoleSelect";
import AddressTypeSelect from "./Contact/AddressTypeSelect";
import MaritalStatusSelect from "./Contact/MaritalStatusSelect";
import {computeFormula, getAge, getColor, round2} from "../Lib/Helpers";
import OccupationSelect from "./Contact/OccupationSelect";
import RiskSelect from "./Contact/RiskSelect";
import AmlRiskSelect from "./Contact/AmlRiskSelect";
import LocationSelect from "./Contact/LocationSelect";
import Money from "./Shared/Money";
import Currency from "./Shared/Currency";
import OccupationSectorSelect from "./Contact/OccupationSectorSelect";
import AmlCases from "./Contact/AmlCases";
import CommunicationsData from "./Contact/CommunicationsData";
import EditableFormField from "./Shared/EditableFormField";
import Section from "./Shared/Section";
import Address from "./Contact/Address";
import Addresses from "./Contact/Addresses";
import PreferedCommunicationMethod from "./Contact/PreferedCommunicationMethod";
import NotificationChannel from "./Contact/NotificationChannel";
import BankSelect from "./Contact/BankSelect";
import Phones from "./Contact/Phones";
import Emails from "./Contact/Emails";
import { CNP } from "../Lib/CNP";
import ContactTags from "./Contact/ContactTags";
import BillingSummaryData from "./Collection/BillingSummaryData";
import ContactDocuments from "./Contact/ContactDocuments";
import HoldingSelect from "./Contact/HoldingSelect";
import CustomForm from "./Shared/CustomForm";
import Comments from "./Life/Comments";
import { withTranslation } from "react-i18next";
import ContactPayments from "./Contact/ContactPayments";
import ContactIssues from "./Contact/ContactIssues";
import SimpleContactSelect from "./Contact/SimpleContactSelect";
import ReceiptTypeSelect from "./Tax/ReceiptTypeSelect";
import UserSelect from "./Bpm/UserSelect";
import ContactBranches from "./Contact/ContactBranches";
import ContactAccounts from "./Contact/ContactAccounts";
import DateSelect from "./Shared/DateSelect";
import Status from "./Shared/Status";
import ContactIncome from "./Contact/ContactIncome";
import ContactIncomeForm from "./Contact/ContactIncomeForm";
import DatePickerW from "./Shared/DatePickerW";

const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;

const tabFields=[
  {
    tab:"1",
    fields:[
  "name",
  "middleName",
  "surname1",
  "surname2",
  "Tags",
  "Roles",
  "cnp",
  "legalRepresentativeId",
  "coLegalRepName",
  "personOfContactId",
  "coPersonOfContact1",
  "coPersonOfContact2",
  "title",
  "idType",
  "passport",
  "coTradeRegister",
  "coHqCountry",
  "coWeb",
  "nationality",
  "citizenship",
  "maritalStatus",
  "nif",
  "gender",
  "birth",
  "nationalId",
  "nationalIdExpiration"
]
  },{
    tab:"2",
    fields:["Addresses", "phone", "email"]
  },
  {
    tab:"details",
    fields:[
      "notificationChannel",
      "preferedCommunicationMethod",
      "occupationSectorCode",
      "occupationId",
      "docsLink",
      "workplace",
      "migrationCode",
      "fatcaTaxCode",
      "fatcaTinGinCode",
      "bankAccount",
      "bank",
      "cif",
      "holdingId",
      "receiptTypeCode",
      "risk"
    ]
  },
  {
    tab:"aml",
    fields:["amlRiskId", "income", "incomeCurrency"]
  },
  {
    tab:"6",
    fields:["height","weight"]
  },
  {
    tab:"3",
    fields:["user","username","password"]
  }
]

class Contact extends React.Component {
  initialState = () => ({
    contact: {},
    loading: false,
    isPerson: true,
    activeKeyTab: "1",
    contactPolicies: 0,
    amlFormId: undefined,
    customFormValues: undefined, //single form values
    customFormId: undefined, //single form definition
    customForms: [], //tabbed forms definition
    customFormsValues: undefined, //tabbed form values
    activityPanel: false,
    numberOfComments: 0,
    issueCount: 0,
    fieldRules : [], //one time loaded
    customValidators: [], //one time loaded
    legalRepContactRoles:[],
    process:null,
  });
  state = this.initialState();
  
  componentDidMount() {
    if (this.props.match.params.tab) this.changeHash(this.props.match.params.tab.substring(3));
    this.setState(
      {
        customFormId: safeGet(["Contacts", "customFormId"], this.props.configProfile, undefined),
        amlFormId: safeGet(["Contacts", "amlFormId"], this.props.configProfile, undefined),
        defaultCountry: safeGet(["Main", "defaultCountry"], this.props.configProfile, undefined),
        hasSector: safeGet(["Contacts", "hasSector"], this.props.configProfile, false),
        customForms: safeGet(["Contacts", "customForms"], this.props.configProfile, []),
        legalRepContactRoles: safeGet(["Contacts", "legalRepContactRoles"], this.props.configProfile, []),
        mandatoryLegalAddress: safeGet(["Contacts", "mandatoryLegalAddress"], this.props.configProfile, false),
      },
      () => this.loadContact(this.props.match.params.contactId)
    );

    this.loadFieldRules();
    this.loadCustomValidators();
    window.contactForm = this.props.form; //Exposed contact form for custom manipulation via script
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.contactId !== this.props.match.params.contactId) this.loadContact(this.props.match.params.contactId); //update de url
    if (prevProps.configProfile !== this.props.configProfile) {
      //loaded
      this.setState({
        customFormId: safeGet(["Contacts", "customFormId"], this.props.configProfile, undefined),
        amlFormId: safeGet(["Contacts", "amlFormId"], this.props.configProfile, undefined),
        defaultCountry: safeGet(["Main", "defaultCountry"], this.props.configProfile, undefined),
        hasSector: safeGet(["Contacts", "hasSector"], this.props.configProfile, false),
        customForms: safeGet(["Contacts", "customForms"], this.props.configProfile, []),
        legalRepContactRoles: safeGet(["Contacts", "legalRepContactRoles"], this.props.configProfile, []),
        mandatoryLegalAddress: safeGet(["Contacts", "mandatoryLegalAddress"], this.props.configProfile, false),
      });
    }
  }
  componentWillUnmount() {
    delete window.contactForm;
  }
  loadFieldRules = () => {
    exe("RepoFieldRule", { operation: "GET", filter: "entity='CONTACT'" }).then((r) => {
      if (r.ok) {
        r.outData.forEach((p) => {
          if (p.frontValidation) {
            p.frontValidation = this.evalInContext(p.frontValidation, { cnpValidator: this.cnpValidator });
          }
        }); //parsing string to function
        this.setState({fieldRules:r.outData});
      }
    });
  };
  loadCustomValidators = () => {
    exe("RepoChain", { operation: "GET", filter: "category='VALIDATOR'" }).then((r) => {
      if (r.ok) {
        r.outData.forEach((p) => (p.function = this.evalInContext(p.code, { exe: exe }))); //parsing string to function
        this.setState({customValidators:r.outData});
      }
    });
  };
  getRules = (fieldName) => {
    const rules=[];
    //required
    if(this.isMandatory(fieldName)) rules.push({required:true,message:this.props.t(fieldName)+" "+this.props.t("is required") });
    
    //front validation
    const fieldRule = this.state.fieldRules.find((p) => p.field == fieldName);
    if (fieldRule && fieldRule.frontValidation) {
      try {
        const parsed = fieldRule.frontValidation;
        const validator = parsed[0].validator;
        if(validator&&validator.toString().includes("entity.")){
          const entity=this.props.form.getFieldsValue();
          parsed[0].entity=entity;//injecting values to rule
        }
        if (validator && typeof validator === "string" && validator.slice(0, 1) == "_") {
          //custom validator
          const validatorName = validator.split("_").pop().split('"')[0];
          const customValidator = this.state.customValidators.find((p) => p.name == validatorName);
          if (customValidator) parsed[0].validator = customValidator.function;
        }
        return [...rules,...parsed];
      } catch (error) {
        console.error("Error parsing configured rule for " + fieldName, error);
        return rules;
      }
    } else return rules;
  };
  isMandatory = (fieldName) => {
    const field = this.state.fieldRules.find((p) => p.field == fieldName);
    if (!field) return false;
    if (!field.required) return false;

    if (field.required.includes("this.")) {
      const birthDate = this.props.form.getFieldValue("birth");
      //injecting custom forms values
       const customFormsValues = {};
       this.state.customForms.forEach((p) => {
         const containterName = "customContactForm_" + p.name;
         customFormsValues[p.name] = window[containterName]?window[containterName].userData:{};         
       });
       //checking if custom form is used and is available
       if(field.required.includes("customFormsValues")){
        console.log("Custom forms values",customFormsValues);
        if(Object.keys(customFormsValues).length==0) return false;
       }
       try {
        const entity=this.props.form.getFieldsValue();
          const result = this.evalInContext(field.required, {
            contact: entity,
            entity: entity,
            currentAge: birthDate ? getAge(birthDate.format("YYYY-MM-DD")) : 0,
            customFormsValues: customFormsValues,
          });
      return result;
    } catch (error) {
      console.error("Error evaluating custom rule for " + fieldName, error);
      return false;
    }
    } else {
      return field.required.toUpperCase() == "TRUE";
    }
  };
  evalInContext(js, context) {
    return function () {
      return eval(js);
    }.call(context);
  }
  reload = () => {
    if (this.props.match.params.contactId) this.loadContact(this.props.match.params.contactId);
  };
  saveContact = () => {
    console.log("Saving... Running custom validators");
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.isMandatory("Addresses") && (!values.Addresses || values.Addresses.length == 0)) {
          message.error(this.props.t("Please specify at least one address"));
          this.setState({ activeKeyTab: "2" });
          return;
        }
        if (this.isMandatory("Addresses") && values.Addresses.some((p) => p.err && p.err.length > 0)) {
          //addresses validation error
          let hasError = false;
          values.Addresses.forEach((p) => {
            (p.err||[]).forEach((err) => {
              if (this.isMandatory(err)) {
                message.error(err + " " + this.props.t("is mandatory"));
                this.setState({ activeKeyTab: "2" });
                hasError = true;
              }
            });
          });
          if (hasError) {
            message.error(this.props.t("Please check Address validation errors"));
            return;
          }
        }
        //validating phones
        if (values.Phones.some((p) => p.err)) {
          message.error(this.props.t("Please check phone validation errors"));
          this.setState({ activeKeyTab: "2" });
          return;
        }
        if (this.isMandatory("Emails") && values.Emails.some((p) => p.err)) {
          //addresses validation error
          message.error(this.props.t("Please check email validation errors"));
          this.setState({ activeKeyTab: "2" });
          return;
        }
        //if legal address is mandatory, at least one must be marked as legal address
        if (this.state.mandatoryLegalAddress && values.Addresses.filter((p) => p.legal).length == 0) {
          message.error(this.props.t("Please specify a legal address"));
          this.setState({ activeKeyTab: "2" });
          return;
        }
        //if there is an address marked as legal, it must be unique
        if (values.Addresses.filter((p) => p.legal).length > 1) {
          message.error(this.props.t("Only one address can be marked for legal correspondence"));
          this.setState({ activeKeyTab: "2" });
          return;
        }
        if (values.Addresses)
          values.Addresses.forEach((element) => {
            delete element.location;
            delete element.err;
          });
        //validating custom form
        if (window.customContactForm && window.customContactForm.instanceContainers[0]) {
          if (window.customContactForm.instanceContainers[0].reportValidity()) {
            values.genericField = JSON.stringify(window.customContactForm.userData);
          } else {
            message.error(this.props.t("Please check custom form validation errors"));
            this.setState({ activeKeyTab: "other" });
            return;
          }
        }
        //validating custom AML form
        if (window.amlForm && window.amlForm.instanceContainers[0]) {
          if (window.amlForm.instanceContainers[0].reportValidity()) {
            values.jAmlForm = JSON.stringify(window.amlForm.userData);
          } else {
            message.error(this.props.t("Please check custom AML form validation errors"));
            this.setState({ activeKeyTab: "aml" });
            return;
          }
        }
        //validating multiforms
        const customFields = {};
        let customFieldsWithError = false;
        this.state.customForms.forEach((p) => {
          const containterName = "customContactForm_" + p.name;
          if (window[containterName] && window[containterName].instanceContainers[0]) {
            if (window[containterName].instanceContainers[0].reportValidity()) {
              customFields[p.name] = JSON.stringify(window[containterName].userData);
            } else {
              //getting the list of required fields not filled
              const requiredFields = window[containterName].options.formData.filter((p) => p.required &&(!p.userData||p.userData&&Array.isArray(p.userData)&&p.userData.length&&!p.userData[0])).map((p) => p.label);
              requiredFields.forEach((f) => message.error(f + " " + this.props.t("is required in custom tab ")+ p.name));
              this.setState({ activeKeyTab: "other"});
              if(!customFieldsWithError) this.setState({ customFormsActiveTab: "customTab_" + p.name },()=>setTimeout(()=>window[containterName].instanceContainers[0].reportValidity(),500)); //focus on first tab with error
              customFieldsWithError = true;
            }
          }
        });
        if (customFieldsWithError) return;
        if (Object.keys(customFields).length > 0) values.jCustomForms = JSON.stringify(customFields);

        //validation OK
        values.id = this.props.match.params.contactId;
        console.log("Received values of form: ", values);
        if (values.location) {
          values.country = values.location.country;
          values.state = values.location.state;
          values.city = values.location.city;
          values.sector = values.location.sector;
        }
        values.inactive = !values.active;
        this.setState({ loading: true });
        exe("AddOrUpdateContact", values).then((r) => {
          console.log(r);
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            if (r.operation === "ADD"&&this.props.modal){
              this.props.onAdd(r.outData)
            }
            if (r.operation == "ADD"&&!this.props.modal) window.location.hash = "#/contact/" + r.outData.id;
            else {
              this.props.form.setFieldsValue({ fatca: r.outData.fatca });
            }
          } else {
            if (r.changeRequest) {
              const before = {};
              const after = {};
              r.unauthorized.forEach((element) => {
                before[element] = r.outData[element];
                after[element] = r.data[element];
              });
              this.props.form.setFieldsValue(before); //reverting unauth changes
              const me = this;
              Modal.confirm({
                title: this.props.t("Restricted change"),
                content: r.msg,
                onOk() {
                  const change = {
                    contactId: r.outData.id,
                    jBefore: JSON.stringify(before),
                    JAfter: JSON.stringify(after),
                    operation: r.changeOperation,
                  };
                  exe("AddContactChange", change).then((r) => {
                    if (r.ok) {
                      message.success(r.msg);
                      me.props.form.setFieldsValue({ Changes: r.outData });
                    } else {
                      message.error(r.msg);
                    }
                  });
                },
              });
            } else {
              message.error(r.msg);
            }
          }
        });
      } else {
        console.log("Validation errors:", err);
        //validation error
        //finding the tab to focus
        let focusOnTab="1";
        for (const { tab, fields } of tabFields) {
          if (fields.some((r) => Object.keys(err).includes(r))) {
              focusOnTab = tab;
              break;
          }
      }

        this.setState({ activeKeyTab: focusOnTab });
        //extracting error messages
        const errorMessages = Object.keys(err).map((p) => err[p].errors.map((e) => e.message));
        //showing error messages, one error in each line
        message.error(<>{this.props.t("Please check required fields")}:<br/><ul>{errorMessages.map((p,index)=><li key={index}>{p}</li>)}</ul></>);
      }
    });
  };
  newContact = () => {
    window.location.hash="#/contact/0";
  };
  resetContact = () => {
    this.loadCustomforms();
    this.setState({ ...this.initialState(),fieldRules:this.state.fieldRules,customValidators:this.state.customValidators, customFormId: this.state.customFormId, customFormValues: undefined, customForms: this.state.customForms });
    this.props.form.resetFields();
    this.props.form.setFieldsValue({ Addresses: [] });
  };
  loadCustomforms=()=>{
    try {
      this.state.customForms.forEach((p) => window["customContactForm_" + p.name] &&window["customContactForm_" + p.name].load&& window["customContactForm_" + p.name].load(p.formId));
      //if (window.customContactForm) window.customContactForm.load&&window.customContactForm.load(this.state.customFormId);
    }catch (e) {
      console.error("Error loading custom forms:",e);
    }
  }
  loadContact = (contactId) => {
    this.loadCustomforms();
    this.setState({ customFormValues: null });
    if (contactId == 0) {
      this.setState({ ...this.initialState(),fieldRules:this.state.fieldRules,customValidators:this.state.customValidators, customFormId: this.state.customFormId, customFormValues: undefined, customForms: this.state.customForms });
      this.props.form.resetFields();
      this.props.form.setFieldsValue({ Addresses: [] });
      return;
    }
    this.setState({ loading: true });
    exe("GetContacts", {
      filter: "id=" + contactId,
      include: [
        "Tags",
        "Relationships",
        "RelationshipsTo",
        "Changes",
        "Changes.Process",
        "Roles",
        "AmlCases",
        "AmlCases.Process",
        "Addresses",
        "Phones",
        "Emails",
        "Process",
      ],
    }).then((r) => {
      console.log(r);
      this.setState({ loading: false });
      if (r.ok) {
        const d = r.outData[0];
        if (!d) {
          message.error(r.msg);
        } else {
          //adaptando data del DTO
          d.location = {
            country: d.country,
            state: d.state,
            city: d.city,
            sector: d.sector,
          };
          d.active = !d.inactive;
          //if (d.birth) d.birth = moment(d.birth);
          this.props.form.setFieldsValue(d);
          //fusionando relaciones
          d.Relationships = d.Relationships.concat(
            d.RelationshipsTo.map((r) => {
              return { ...r, name: r.contactName, relatedId: r.contactId, type: r.nameInverse?r.nameInverse:(r.type + "(TO)") };
            })
          );
          //getting the tab from the hash eg: '#/contact/1/tab2'
          const tab = window.location.hash.split("/")[3];
          //if restricted, checking user permissions for current tab
          if (d.restricted&&tab){
            this.props.checkTabPermissions(tab,true).then(authorized => {
              if (!authorized) {
                message.error(this.props.t("Your user does not have permission to access this tab"));
                //changing to main tab
                window.history.replaceState(null, null, window.location.hash.split("/tab")[0] + "/tab1" );
                this.setState({ activeKeyTab: "1" });
              }
              this.setState({ contact: d, isPerson: d.isPerson, customFormValues: d.genericField, customFormsValues: d.jCustomForms,process:d.Process });
            })
          }else this.setState({ contact: d, isPerson: d.isPerson, customFormValues: d.genericField, customFormsValues: d.jCustomForms,process:d.Process });
        }
      } else {
        message.error(r.msg);
      }
    });
  };

  relationshipDataChanged = (r) => {
    this.setState({ contact: { ...this.state.contact, Relationships: r } });
  };
  customerTypeChanged = (checked) => {
    this.setState({ isPerson: checked });
  };
  changeHash(tab) {
    // Check tab permissions before changing tab
    this.props.checkTabPermissions(`/contact/tab${tab}`,safeGet(["contact","restricted"],this.state,false)).then(authorized => {
      if (!authorized) {
        message.error(this.props.t("Your user does not have permission to access this tab"));
        return;
      }
       // If authorized, proceed with tab change
    let newHash = window.location.hash;
    newHash = newHash.split("/tab")[0] + "/tab" + tab;
    window.history.replaceState(null, null, newHash);
    this.setState({ activeKeyTab: tab });
    })
  }
  cnpValidator = (rule, value, cb) => {
    if (!value && !rule.required) return cb();
    if (isNaN(value) || !value) {
      cb(this.props.t("Invalid CNP"));
    } else {
      const cnp = new CNP(value);
      if (cnp.isValid()) {
        cb();
      } else {
        cb(this.props.t("Invalid CNP"));
      }
    }
  };
  getCnpInfo = () => {
    const cnpValue = this.props.form.getFieldValue("cnp");
    if (cnpValue) {
      const cnp = new CNP(cnpValue);

      const msg = (
        <div>
          <p>
            {this.props.t("Valid")}:{!isNaN(cnpValue) && cnp.isValid() ? this.props.t("Yes") : this.props.t("No")}
          </p>
          <p>
            {this.props.t("Birth")}:{cnp.getBirthDate()}
          </p>
          <p>
            {this.props.t("Birthplace")}:{cnp.getBirthPlace()}
          </p>
          <p>
            {this.props.t("Gender")}:{cnp.getGender()}
          </p>
          <p>
            {this.props.t("Has identity card")}:{cnp.hasIdentityCard() ? this.props.t("Yes") : this.props.t("No")}
          </p>
          <p>
            {this.props.t("Serial Num")}:{cnp.getSerialNumberFromCNP()}
          </p>
        </div>
      );
      return msg;
    } else {
      return <div>{this.props.t("No CNP provided")}</div>;
    }
  };
 
  openAsUser=()=>{
    window.sessionStorage.contactId=this.state.contact.id
    window.open("#/SelfService/home", '_blank').focus()
    
    //window.sessionStorage.contactId=this.state.contact.id;
    //window.location.hash="/SelfService/home";
    //window.location.reload();
  }
  isHidden= (fieldName) => {
    const field = this.state.fieldRules.find((p) => p.field == fieldName);
    if (!field) return false;
    if (field.hidden) return true;
    if(field.hiddenCondition){
      const entity=this.props.form.getFieldsValue();
      return computeFormula(field.hiddenCondition,{con:entity,entity:entity});
    }
    return false;
  };
  FormItem = (props) => {
    let componentStyle=props.style||{};
    let hidden=false;
    //safely getting field name
    if(props.children&&props.children.props&&props.children.props.id){
      const fieldName=props.children.props.id;
      hidden=fieldName?this.isHidden(fieldName):false
      if(hidden){
        console.log("Hiding field",fieldName);
        componentStyle={...componentStyle,display:"none"}
      }
    }
    return <Form.Item {...props} style={componentStyle}>{props.children}</Form.Item>
  }
  addWorkflow=()=>{
    this.setState({loading:true});
    exe("AddContactWorkflow",{contactId:this.props.match.params.contactId}).then(r=>{
      this.setState({loading:false});
      if(r.ok){
        message.success(r.msg);
        this.setState({process:r.outData.process})
      }else{
        message.error(r.msg);
      }
    })
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const contactId = this.props.match.params.contactId; //obtenido de la url
    const { isPerson } = this.state;
    const birthDate = this.props.form.getFieldValue("birth");
    const currentAge = birthDate ? getAge(birthDate.format("YYYY-MM-DD")) : 0;
    const adult = currentAge >= 18;
    const amlCases = this.props.form.getFieldValue("AmlCases") || [];
    const FormItem =this.FormItem;
    const restrictedForUser=safeGetRaw(["contact","restrictedForUser"],this.state,false)
    return (
      <div style={{}}>
        <DefaultPage
          routes={{
            routes: [
              { breadcrumbName: t("Home"), path: "/" },
              { breadcrumbName: t("Contact List"), path: "contactlist" },
              { breadcrumbName: t("Contact"), path: "" },
            ],
          }}
          title={contactId == 0 ? t("New Contact") : `${this.state.contact.FullName}`}
          subTitle={`${t("ID")} ${contactId}`}
          tags={this.state.process?<Status process={this.state.process} color={getColor(this.state.process && this.state.process.entityState)} reload={() => this.reload()} />:null }
          icon={isPerson ? "user" : "bank"}
          loading={this.state.loading}
          extra={
            <div>
               <Dropdown
                onClick={() => null}
                overlay={
                  <Menu onClick={() => null}>
                    <Menu.Item key="addWorkflow" onClick={() => this.addWorkflow()} disabled={this.state.process||contactId==0}>
                      <RetweetOutlined />
                      {t("Add Workflow")}
                    </Menu.Item>
                  </Menu>
                }>
                <Button type="link">
                  {t("Actions")} <DownOutlined />
                </Button>
              </Dropdown>
              <ButtonGroup>
                <Button onClick={() => this.setState({ activityPanel: !this.state.activityPanel })} icon={<MessageOutlined />}>
                  {t("Comments")} <Badge count={this.state.numberOfComments} style={{ marginLeft: 5 }}></Badge>
                </Button>
                <Button onClick={this.reload} icon={<ReloadOutlined />}>
                  {t("Reload")}
                </Button>
                <Button onClick={this.newContact} icon={<FileOutlined />}>
                  {t("New")}
                </Button>
                <Button onClick={this.resetContact} icon={<UndoOutlined />}>
                  {t("Reset")}
                </Button>
                <Button onClick={this.saveContact} icon={<SaveOutlined />} type="primary">
                  {t("Save")}
                </Button>
              </ButtonGroup>
            </div>
          }>
          {this.state.activityPanel && (
            <div style={{ position: "absolute", right: 0, top: -15, zIndex: 9 }}>
              <Button type="link" icon={<LegacyIcon type={"close"} />} onClick={() => this.setState({ activityPanel: false })} className="collapseButton"></Button>
            </div>
          )}
          <Row gutter={32}>
            <Col span={this.state.activityPanel ? 16 : 24}>
              <Form layout="vertical">
              {getFieldDecorator("processId")}
              {getFieldDecorator("organizationId")}
                <Tabs defaultActiveKey="1" tabBarExtraContent={{left:this.state.activeKeyTab=="1"?null:<Button type="link" icon={<LeftOutlined style={{fontSize:14}}  />} onClick={() =>this.changeHash("1")}/>}}
                activeKey={this.state.activeKeyTab} onTabClick={(tab) => this.changeHash(tab)}>
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <HomeOutlined />
                        {t("Main")}
                      </span>
                    }
                    key="1">
                    <Row gutter={16}>
                      {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <div style={{ display: isPerson ? "block" : "none" }}>
                          <FormItem label={t("First Name")}>
                            {getFieldDecorator("name", {
                              rules: !isPerson?[]:[
                                ...this.getRules("name"),
                              ],
                              normalize: (value) => (value || "").toUpperCase(),
                            })(<Input placeholder={t("First Name")} />)}
                          </FormItem>
                        
                       
                          <FormItem label={t("Middle Name")}>
                            {getFieldDecorator("middleName", {
                              rules: !isPerson?[]:[
                                ...this.getRules("middleName"),
                              ],
                              normalize: (value) => (value || "").toUpperCase(),
                            })(<Input placeholder={t("Middle Name")} />)}
                          </FormItem>
                     
                       
                          <FormItem label={t("Last Name")}>
                            {getFieldDecorator("surname1", {
                              normalize: (value) => (value || "").toUpperCase(),
                              rules: !isPerson?[]:[
                                ...this.getRules("surname1")]
                            })(<Input placeholder={t("Last Name")} />)}
                          </FormItem>
                        </div>
                        <FormItem label={isPerson ? t("Second Surname") : t("Company Name")}>
                          {getFieldDecorator("surname2", {
                            normalize: (value) => (value || "").toUpperCase(),
                            rules: [
                              ...this.getRules("surname2"),
                            ],
                          })(<Input />)}
                        </FormItem>

                        <FormItem label={t("Tags")}>{getFieldDecorator("Tags", {})(<ContactTags contactId={contactId} />)}</FormItem>
                        <FormItem label={t("Roles")}>{getFieldDecorator("Roles", {rules:[...this.getRules("Roles")]})(<RoleSelect personType={isPerson?"P":"C"}/>)}</FormItem>
                        <div style={{ display: isPerson ? "block" : "none" }}>
                          <FormItem
                            label={
                              <span>
                                {t("CNP")}
                                {this.state.defaultCountry == "RO" && (
                                  <Tooltip title={<div>{this.getCnpInfo()}</div>}>
                                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                                  </Tooltip>
                                )}
                              </span>
                            }>
                            {getFieldDecorator("cnp", { rules: !isPerson?[]:[...this.getRules("cnp")] })(<Input />)}
                          </FormItem>
                        </div>
                        <div style={{ display: !isPerson ? "block" : "none" }}>
                          <FormItem label={t("Legal Representative")}>
                            {getFieldDecorator("legalRepresentativeId", {
                              rules: [
                                ...this.getRules("legalRepresentativeId"),
                              ],
                            })(<SimpleContactSelect showLink allowNew newInTab filter={
                              this.state.legalRepContactRoles.length === 0 ? undefined
                                  : `id in (SELECT contactId from ContactRole WHERE role IN (${this.state.legalRepContactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                            } />)}
                          </FormItem>
                          <FormItem label={t("Legal Representative Notes")}>
                            {getFieldDecorator("coLegalRepName", {
                              rules: [
                                ...this.getRules("coLegalRepName"),
                              ],
                            })(<Input />)}
                          </FormItem>
                        </div>
                        <div style={{ display: !isPerson ? "block" : "none" }}>
                          <FormItem label={t("Person of Contact")}>
                            {getFieldDecorator("personOfContactId", {
                              rules: [
                                ...this.getRules("personOfContactId"),
                              ],
                            })(<SimpleContactSelect showLink allowNew newInTab />)}
                          </FormItem>
                          <FormItem label={t("Person of Contact Notes")}>
                            {getFieldDecorator("coPersonOfContact1", {
                              rules: [
                                ...this.getRules("coPersonOfContact1"),
                              ],
                            })(<Input />)}
                          </FormItem>
                        </div>
                        <div style={{ display: !isPerson ? "block" : "none" }}>
                          <FormItem label={t("Secondary Person of Contact")}>
                            {getFieldDecorator("coPersonOfContact2", {
                              normalize: (value) => (value || "").toUpperCase(),
                              rules: [
                                ...this.getRules("coPersonOfContact2"),
                              ],
                            })(<Input />)}
                          </FormItem>
                        </div>
                      </Col>
                      {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div style={{ display: isPerson ? "block" : "none" }}>
                              <FormItem label={t("Title")}>
                                {getFieldDecorator("title", { rules:!isPerson?[]: [...this.getRules("title")] })(<Input />)}
                              </FormItem>
                            </div>
                            <FormItem label={t("Id Type")}>
                              {getFieldDecorator("idType", { rules: [ ...this.getRules("idType")] })(<IdTypeSelect personType={isPerson?"P":"C"} />)}
                            </FormItem>
                            <FormItem label={isPerson ? t("ID/Passport") : t("Registration ID")}>
                              {getFieldDecorator("passport", {
                                rules: [
                                  ...this.getRules("passport"),
                                ],
                              })(<Input />)}
                            </FormItem>
                            <div style={{ display: !isPerson ? "block" : "none" }}>
                              <FormItem label={t("Trade Register")}>
                                {getFieldDecorator("coTradeRegister", {
                                  rules: [ ...this.getRules("coTradeRegister")],
                                })(<Input />)}
                              </FormItem>
                            </div>
                            <div style={{ display: !isPerson ? "block" : "none" }}>
                              <FormItem label={t("HQ Country")}>
                                {getFieldDecorator("coHqCountry", { rules: [ ...this.getRules("coHqCountry")] })(
                                  <CountrySelect />
                                )}
                              </FormItem>
                            </div>

                            <div style={{ display: !isPerson ? "block" : "none" }}>
                              <FormItem label={t("Website")}>
                                {getFieldDecorator("coWeb", { rules: [...this.getRules("coWeb")] })(<Input />)}
                              </FormItem>
                            </div>

                            <div style={{ display: isPerson ? "block" : "none" }}>
                              <FormItem label={t("Nationality")}>
                                {getFieldDecorator("nationality", { rules:!isPerson?[]: [ ...this.getRules("nationality")] })(
                                  <CountrySelect />
                                )}
                              </FormItem>
                       
                          
                              <FormItem label={t("Citizenship")}>
                                {getFieldDecorator("citizenship", {
                                  normalize: (v) => (v ? v : undefined),
                                  rules: !isPerson?[]: [ ...this.getRules("citizenship")],
                                })(<CountrySelect />)}
                              </FormItem>
                            

                          
                              <FormItem label={t("Civil Status")}>
                                {getFieldDecorator("maritalStatus", {
                                  rules:!isPerson?[]: [ ...this.getRules("maritalStatus")],
                                })(<MaritalStatusSelect />)}
                              </FormItem>
                            </div>
                            <FormItem label={t("NIF")}>
                              {getFieldDecorator("nif", { rules: [ ...this.getRules("nif")] })(<Input />)}
                            </FormItem>
                          </Col>
                          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                          <Col span={12}>
                            <FormItem label={t("Person Type")} style={{ marginBottom: 4 }}>
                              {getFieldDecorator("isPerson", { valuePropName: "checked", initialValue: true })(
                                <Switch onChange={this.customerTypeChanged} checkedChildren={<UserOutlined />} unCheckedChildren={<BankOutlined />} />
                              )}
                            </FormItem>

                            <FormItem label={t("Customer ID")}>
                              {getFieldDecorator(
                                "id",
                                {}
                              )(<Input placeholder={t("New")} prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} readOnly></Input>)}
                            </FormItem>
                            <div style={{ display: isPerson ? "block" : "none" }}>
                              <FormItem label={t("Gender")}>
                                {getFieldDecorator("gender", { rules:!isPerson?[]: [...this.getRules("gender") ] })(
                                  <RadioGroup name="radiogroup" type="">
                                    <Radio.Button value="M">{t("Male")}</Radio.Button>
                                    <Radio.Button value="F">{t("Female")}</Radio.Button>
                                  </RadioGroup>
                                )}
                              </FormItem>
                            </div>
                            <FormItem label={isPerson ? t("Date of birth") : t("Registration Date")}>
                              {getFieldDecorator("birth", { rules: [ ...this.getRules("birth")] })(<BirthdaySelect />)}
                            </FormItem>
                            <FormItem label={t("National Id CI/BI")}>
                              {getFieldDecorator("nationalId", { rules: [ ...this.getRules("nationalId")] })(
                                <Input />
                              )}
                            </FormItem>
                            <FormItem label={t("National Id CI/BI Expiration")}>
                              {getFieldDecorator("nationalIdExpiration", {
                                rules: [ ...this.getRules("nationalIdExpiration")],
                              })(<DatePickerW />)}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}></Col>
                          <Col span={12}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <PhoneOutlined />
                        {t("Contact Info")}
                      </span>
                    }
                    key="2">
                    {getFieldDecorator("Addresses", { initialValue: [], rules: [...this.getRules("Addresses") ] })(
                      <Addresses
                        contactId={contactId}
                        onRefresh={this.reload}
                        isMandatory={this.state.fieldRules.length > 0 && this.isMandatory}
                        defaultCountry={this.state.defaultCountry}
                        hasSector={this.state.hasSector}
                        personType={isPerson?"P":"C"} 
                      />
                    )}
                    <Row gutter={16}>
                      {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <Section
                          text={
                            <span>
                              <PhoneOutlined style={{ marginRight: 5 }} />
                              {t("Phone Numbers")}
                            </span>
                          }>
                          <FormItem label={t("Phone")}>
                            {getFieldDecorator("phone", {
                              rules: [
                                ...this.getRules("phone"),
                              ],
                            })(<Input />)}
                          </FormItem>
                          <FormItem label={t("Other Phone Numbers")}>
                            {getFieldDecorator("Phones", { initialValue: [] })(<Phones validation={this.getRules("phone")} />)}
                          </FormItem>
                        </Section>
                      </Col>
                      {/* ////////////////////////////////COLUMNA 4 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <Section
                          text={
                            <span>
                              <MailOutlined style={{ marginRight: 5 }} />
                              {t("Email Addresses")}
                            </span>
                          }>
                          <FormItem label={t("Email")}>
                            {getFieldDecorator("email", {
                              rules: [
                                {
                                  type: "email",
                                  message: t("The input is not valid E-mail"),
                                },
                                ...this.getRules("email"),
                              ],
                            })(<Input />)}
                          </FormItem>
                          <FormItem label={t("Other Emails")}>{getFieldDecorator("Emails", { initialValue: [] })(<Emails />)}</FormItem>
                        </Section>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Details  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <InfoOutlined />
                        {t("More Info")}
                      </span>
                    }
                    key="details">
                    <Row gutter={16}>
                      {/* ////////////////////////////////MORE INFO COLUMN 1 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <FormItem label={t("Date of entry")} style={{ marginBottom: 11 }}>
                          {getFieldDecorator("created")(<EditableFormField readOnly />)}
                        </FormItem>

                        <FormItem label={t("Notification Channel")}>
                          {getFieldDecorator("notificationChannel", { rules: [...this.getRules("notificationChannel")] })(
                            <NotificationChannel />
                          )}
                        </FormItem>
                        <FormItem label={t("Preferred Communication Method")}>
                          {getFieldDecorator("preferedCommunicationMethod", { rules: [...this.getRules("preferedCommunicationMethod") ] })(
                            <PreferedCommunicationMethod />
                          )}
                        </FormItem>
                        <div style={{ display: isPerson ? "block" : "none" }}>
                          <FormItem label={t("Occupation Sector")}>
                            {getFieldDecorator("occupationSectorCode", { rules:!isPerson?[]: [...this.getRules("occupationSectorCode") ] })(
                              <OccupationSectorSelect />
                            )}
                          </FormItem>
                 
                     
                          <FormItem label={t("Occupation")}>
                            {getFieldDecorator("occupationId", { rules:!isPerson?[]: [...this.getRules("occupationId")] })(
                              <OccupationSelect sectorCode={this.props.form.getFieldValue("occupationSectorCode")} />
                            )}
                          </FormItem>
                        </div>
                        <FormItem label={t("Link to documents")}>
                          {getFieldDecorator("docsLink", { rules: [ ...this.getRules("docsLink")] })(<Input />)}
                        </FormItem>
                        <FormItem label={t("Workplace")}>
                          {getFieldDecorator("workplace", { rules: [ ...this.getRules("workplace")] })(<Input />)}
                        </FormItem>
                      </Col>
                      {/* ////////////////////////////////MORE INFO COLUMN 2 ////////////////////////////////////////////////////////////////// */}
                      <Col span={6}>
                        <FormItem label={t("Migration code")}>
                          {getFieldDecorator("migrationCode", { rules: [ ...this.getRules("migrationCode")] })(
                            <Input />
                          )}
                        </FormItem>

                        <FormItem label={t("FATCA Tax Code")}>
                          {getFieldDecorator("fatcaTaxCode", { rules: [ ...this.getRules("fatcaTaxCode")] })(
                            <Input />
                          )}
                        </FormItem>
                        <FormItem label={t("FATCA TIN/GIN Code")}>
                          {getFieldDecorator("fatcaTinGinCode", {
                            rules: [ ...this.getRules("fatcaTinGinCode")],
                          })(<Input />)}
                        </FormItem>
                        <FormItem label={t("Bank Account")}>
                          {getFieldDecorator("bankAccount", { rules: [ ...this.getRules("bankAccount")] })(
                            <Input />
                          )}
                        </FormItem>
                        <FormItem label={t("Bank")}>
                          {getFieldDecorator("bank", { rules: [...this.getRules("bank") ] })(<BankSelect />)}
                        </FormItem>
                        <FormItem label={t("CIF")}>
                          {getFieldDecorator("cif", { rules: [ ...this.getRules("cif")] })(<Input />)}
                        </FormItem>
                        <FormItem label={t("Holding")}>
                          {getFieldDecorator("holdingId", { rules: [...this.getRules("holdingId")] })(<SimpleContactSelect showLink allowNew newInTab filter={
                            `id in (SELECT contactId from ContactRole WHERE role='HOL') AND `} />)}
                        </FormItem>
                        <FormItem label={t("Default Receipt Type")}>
                          {getFieldDecorator("receiptTypeCode", { rules: [ ...this.getRules("receiptTypeCode")] })(<ReceiptTypeSelect />)}
                        </FormItem>
                      </Col>
                      {/* ////////////////////////////////MORE INFO COLUMN 3 ////////////////////////////////////////////////////////////////// */}
                      <Col span={6}>
                        <FormItem label={t("Active")} style={{ marginBottom: 4 }}>
                          {getFieldDecorator("active", { valuePropName: "checked", initialValue: true })(<Switch />)}
                        </FormItem>
                        <FormItem label={t("Marketing agreement")} style={{ marginBottom: 4 }}>
                          {getFieldDecorator("marketingAgreement", { valuePropName: "checked", initialValue: false })(<Switch />)}
                        </FormItem>
                        <FormItem label={t("Public Status")} style={{ marginBottom: 4 }}>
                          {getFieldDecorator("publicStatus", { valuePropName: "checked" })(<Switch />)}
                        </FormItem>
                        <FormItem label={t("FATCA")} style={{ marginBottom: 4 }}>
                          {getFieldDecorator("fatca", { valuePropName: "checked" })(<Switch disabled />)}
                        </FormItem>
                        <FormItem label={t("International Residence")} style={{ marginBottom: 4 }}>
                          {getFieldDecorator("internationalResidence", { valuePropName: "checked" })(<Switch disabled />)}
                        </FormItem>

                        <FormItem label={t("Risk Class")}>
                          {getFieldDecorator("risk", { normalize: (v) => (v ? v : undefined), rules: [...this.getRules("risk") ] })(
                            <RiskSelect />
                          )}
                        </FormItem>
                        <FormItem label={t("Customer creation date")} style={{ marginBottom: 11 }}>
                          {getFieldDecorator("customerDate")(<EditableFormField readOnly />)}
                        </FormItem>
                        <FormItem label={t("Last updated")} style={{ marginBottom: 11 }}>
                          {getFieldDecorator("updated")(<EditableFormField readOnly />)}
                        </FormItem>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Other  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <FormOutlined />
                        {t("Custom")}
                      </span>
                    }
                    key="other">
                    {this.state.customFormId && (
                      <CustomForm formId={this.state.customFormId} variable="customContactForm" value={this.state.customFormValues} />
                    )}
                    {this.state.customForms.length > 0 && (
                      <Tabs onChange={v=>this.setState({customFormsActiveTab:v})} activeKey={this.state.customFormsActiveTab}>
                        {this.state.customForms.map((p) => (
                          <Tabs.TabPane
                            forceRender
                            key={"customTab_" + p.name}
                            tab={
                              <span>
                                <LegacyIcon type={p.icon} />
                                {p.name}
                              </span>
                            }>
                            <CustomForm
                              formId={p.formId}
                              variable={"customContactForm_" + p.name}
                              value={this.state.customFormsValues && JSON.parse(this.state.customFormsValues)[p.name]}
                            />
                          </Tabs.TabPane>
                        ))}
                      </Tabs>
                    )}
                  </Tabs.TabPane>

                  {/* ////////////////////////////////AML  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <EuroOutlined />
                        {t("AML")}
                        <Badge count={amlCases.length} style={{ marginLeft: 5 }} />
                      </span>
                    }
                    key="aml">
                      <Tabs>
                        <Tabs.TabPane tab={t("Cases")} key="amlDetails">
                          <Row gutter={16}>
                            <Col span={8}>
                              <FormItem label={t("AML Risk Class")}>
                                {getFieldDecorator("amlRiskId", { normalize: (v) => (v ? v : undefined), rules: [...this.getRules("amlRiskId")] })(
                                  <AmlRiskSelect />
                                )}
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <div style={{ display: "flex" }}>
                                <FormItem label={t("Income")}>
                                  <span style={{ display: restrictedForUser?"none":"block" }}>
                                  {getFieldDecorator("income", { rules: [...this.getRules("income")] })(
                                      <Money currency={this.props.form.getFieldValue("incomeCurrency")} />
                                    )}
                                  </span>
                                  <span style={{ display: restrictedForUser?"block":"none" }}>****</span>
                                </FormItem>
                                <FormItem label={t("Currency")} style={{ marginLeft: 3, width: 90 }}>
                                <span style={{ display: restrictedForUser?"none":"block" }}>
                                  {getFieldDecorator("incomeCurrency", { rules: [...this.getRules("incomeCurrency")] })(<Currency />)}
                                  </span>
                                  <span style={{ display: restrictedForUser?"block":"none" }}>****</span>
                                </FormItem>
                              </div>
                            </Col>
                          </Row>
                          {getFieldDecorator("jAmlForm")}
                          {this.state.amlFormId && <CustomForm formId={this.state.amlFormId} variable="amlForm" value={this.props.form.getFieldValue("jAmlForm")} />}

                          <FormItem label={t("AML Cases")}>{getFieldDecorator("AmlCases")(<AmlCases contactId={contactId} />)}</FormItem>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Income Info"} key="amlIncomeInfo" disabled={restrictedForUser}>
                          <ContactIncome contactId={contactId} />
                        </Tabs.TabPane>
                      </Tabs>
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Changes  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <EditOutlined />
                        {t("Changes")}
                      </span>
                    }
                    key="changes">
                    <Title level={4}>{t("Change Requests")}</Title>
                    {getFieldDecorator("Changes", {})(<ContactChanges onRefresh={this.reload} />)}
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Issues  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <AlertOutlined />
                        {t("Issues")}
                        <Badge count={this.state.issueCount} style={{ marginLeft: 5 }} />
                      </span>
                    }
                    forceRender
                    disabled={!+contactId}
                    key="issues">
                    <Title level={4}>{t("Issue Tracker")}</Title>
                    {getFieldDecorator("Issues", {})(<ContactIssues contactId={contactId} count={(v) => this.setState({ issueCount: v })}  />)}
                  </Tabs.TabPane>

                  {/* ////////////////////////////////Communications  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <MessageOutlined />
                        {t("Communications")}
                      </span>
                    }
                    key="communications">
                    {getFieldDecorator("Messages", {})(<CommunicationsData contactId={this.state.contact.id} />)}
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Documents  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <FilePdfOutlined />
                        {t("Documents")}
                      </span>
                    }
                    key="documents">
                    {getFieldDecorator("Documents", {})(<ContactDocuments contactId={this.state.contact.id} personType={isPerson?"P":"C"} contact={this.state.contact} form={this.props.form} />)}
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Policies  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <SafetyOutlined />
                        {t("Policies")} <Badge count={this.state.contactPolicies} style={{ marginLeft: 5, backgroundColor: "#52c41a" }} />
                      </span>
                    }
                    key="4">
                    <ContactPolicies contactId={this.state.contact.id} onLoad={(q) => this.setState({ contactPolicies: q })} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Billing  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <FileSyncOutlined />
                        {t("Billing")}
                      </span>
                    }
                    key="9">
                    <BillingSummaryData contactId={this.state.contact.id} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Billing  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <UploadOutlined />
                        {t("Payments")}
                      </span>
                    }
                    key="10">
                    <ContactPayments contactId={this.state.contact.id} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Relationships  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    disabled={!this.state.contact.id}
                    tab={
                      <span>
                        <UsergroupAddOutlined />
                        {t("Relationships")}
                        <Badge
                          count={this.state.contact.Relationships && this.state.contact.Relationships.length}
                          style={{ marginLeft: 5, backgroundColor: "#52c41a" }}
                        />
                      </span>
                    }
                    key="5">
                    <Relationships
                      contactId={this.props.match.params.contactId}
                      data={this.state.contact.Relationships}
                      isPerson={this.props.form.getFieldValue("isPerson")}
                      dataChanged={this.relationshipDataChanged}
                    />
                  </Tabs.TabPane>

                  {/* ////////////////////////////////Claims  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    forceRender
                    tab={
                      <span>
                        <WarningOutlined />
                        {t("Claims")} <Badge count={this.state.contactClaims} style={{ marginLeft: 5 }} />
                      </span>
                    }
                    key="7">
                    <ContactClaims contactId={contactId} onLoad={(q) => this.setState({ contactClaims: q })} restrictedForUser={restrictedForUser} />
                    {/* <ClaimSummary contactId={contactId} /> */}
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Branches  ////////////////////////////////////////////////////////////////// */}
                  {!this.state.isPerson&&<Tabs.TabPane
                      tab={
                        <span>
                        <BankOutlined />
                          {t("Branches")}
                      </span>
                      }
                      key="11">
                    <ContactBranches contactId={this.state.contact.id} />
                  </Tabs.TabPane>}
                  {/* ////////////////////////////////Pending  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <ClockCircleOutlined />
                        {t("Pending Requirements")}
                      </span>
                    }
                    key="8">
                    <RequirementsTable reload={this.state.reloading} hideAdd />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Medical Records  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <IdcardOutlined />
                        {t("Medical Records")}
                      </span>
                    }
                    key="6">
                    <Title level={4}>{t("Info")}</Title>
                    <div style={{ display: "flex" }}>
                      <FormItem label={t("Height (m)")}>
                        {getFieldDecorator("height", { rules: [ ...this.getRules("height")] })(<InputNumber />)}
                      </FormItem>
                      <FormItem label={t("Weight (kg)")} style={{ marginLeft: 5 }}>
                        {getFieldDecorator("weight", { rules: [ ...this.getRules("weight")] })(<InputNumber />)}
                      </FormItem>
                      <FormItem label={t("BMI")} style={{ marginLeft: 5 }}>
                        {getFieldDecorator("bmi", {
                          normalize: (v, p, all) => (all.height && all.weight ? round2(all.weight / (all.height * all.height)) : undefined),
                        })(<InputNumber disabled />)}
                      </FormItem>
                    </div>
                    <MedicalRecords contactId={contactId} />
                  </Tabs.TabPane>
                  {/* ////////////////////////////////SelfService  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
                    tab={
                      <span>
                        <LockOutlined />
                        {t("SelfService")}
                      </span>
                    }
                    key="3">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Button type={"link"} icon={<LegacyIcon type={"select"} />} onClick={()=>this.openAsUser()}>{t("Open Self Service as user")}</Button>
                        <FormItem label={t("User")}>
                          {getFieldDecorator("user", { rules: [...this.getRules("user") ] })(
                              <UserSelect />
                          )}
                        </FormItem>
                     <FormItem label={t("Username")}>
                          {getFieldDecorator("username", { rules: [...this.getRules("username")] })(
                            <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={t("Username")} />
                          )}
                        </FormItem>
                        <FormItem label={t("Password")}>
                          {getFieldDecorator("password", { rules: [...this.getRules("password") ] })(
                            <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder={t("Password")} />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  {/* ////////////////////////////////Accounts  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane tab={<span><GlobalOutlined />{t("Accounts")}</span>} key="accounts">
                    <ContactAccounts contactId={contactId} />
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            </Col>
            <Col span={this.state.activityPanel ? 8 : 0}>
              <Tabs>
                <Tabs.TabPane
                  forceRender
                  key="panel3"
                  tab={
                    <span>
                      <MessageOutlined />
                      {t("Comments")}
                    </span>
                  }>
                  <Comments contactId={contactId} numberOfComments={(q) => this.setState({ numberOfComments: q })} />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </DefaultPage>
      </div>
    );
  }
}

const WrappedContactForm = Form.create()(Contact);
export default withTranslation()(WrappedContactForm);
