import React, {useEffect, useState} from "react";
import { FileOutlined,FundOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Tabs,
    Table,
    Typography,
    Row,
    Col,
    Select,
    Input,
    InputNumber,
    message,
    Switch,
} from "antd";
import Section from "../Shared/Section";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import { exe } from "../../Lib/Dal";
import AccountSelect from "../Shared/AccountSelect";
import { useTranslation } from "react-i18next";
import ExternalSourceSelect from "../Financial/ExternalSourceSelect";
import IncomeTypeSelect from "../Transfers/IncomeTypeSelect";
import PolicySelect from "../Health/PolicySelect";
import CustomForm from "../Shared/CustomForm";
import SplitPayment from "./SplitPayment";
import ClaimSelect from "../Shared/ClaimSelect";
import CostCenterManagement from "../Shared/CostCenterManagement";
import {formatMoney, round2} from "../../Lib/Helpers";
import CurrencyOptions from "./CurrencyOptions";

const IncomeTransfer = (props) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [destinationAccountId, setDestinationAccountId] = useState();
    const [formId,setFormId]=useState();
    const [internalIncomeType,setInternalIncomeType]=useState();
    const [customForms,setCustomForms]=useState([]);
    const [incomeTypeFormId,setIncomeTypeFormId]=useState();
    const [costCentersVisible,setCostCentersVisible]=useState(false);
    const [currencyOptionsVisible,setCurrencyOptionsVisible]=useState(false);
    const [exchangeDate,setExchangeDate]=useState();
    
    const values=props.form.getFieldsValue();
    
    useEffect(()=>{
        if(props.selectedPayment){
            props.form.setFieldsValue(props.selectedPayment);
            setCustomForms(props.selectedPayment.SplitPayments);
            window.selectedPayment=props.selectedPayment;
        }
    },[props.selectedPayment])
    //premiums
    useEffect(()=>{
        if(props.selectedPremiums.length===0)
        {
            onNewRecord();
        }else{
            const currency=props.selectedPremiums[0].currency;
            const amount=props.selectedPremiums.reduce((p,c)=>p+c.pendingAmount,0);
            
            props.form.setFieldsValue({currency:currency,SplitPayments:[{amount:round2(amount)}]});
        }
    },[props.selectedPremiums.length])
    //other
    useEffect(()=>{
        if(props.selectedOtherReceivables.length===0)
        {
            onNewRecord();
        }else{
            const currency=props.selectedOtherReceivables[0].currency;
            const amount=props.selectedOtherReceivables.reduce((p,c)=>p+c.total,0);

            props.form.setFieldsValue({currency:currency,SplitPayments:[{amount:round2(amount)}]});
        }
    },[props.selectedOtherReceivables.length])

    const isOtherReceivables=props.selectedOtherReceivables.length;
    const field = props.form.getFieldDecorator;
    const currency = props.form.getFieldValue("currency") || props.defaultCurrency;
    const selectedAmount=isOtherReceivables?props.selectedOtherReceivables.reduce((p,c)=>p+c.total,0):props.selectedPremiums.reduce((p,c)=>p+c.pendingAmount,0);

    const onExecute = () => {
        props.form.validateFields((err, values) => {
            //validating custom form
            if (window.customWorkspaceForm && window.customWorkspaceForm.instanceContainers&&window.customWorkspaceForm.instanceContainers[0]) {
                if (window.customWorkspaceForm.instanceContainers[0].reportValidity()) {
                    values.jForm = JSON.stringify(window.customWorkspaceForm.userData);
                    values.formId=formId;
                } else {
                    message.error(t("Please check custom form validation errors"));
                    return;
                }
            }
            //validating income type form
            if (window.incomeTypeForm &&window.incomeTypeForm.instanceContainers&& window.incomeTypeForm.instanceContainers[0]) {
                if (window.incomeTypeForm.instanceContainers[0].reportValidity()) {
                    values.jIncomeTypeForm = JSON.stringify(window.incomeTypeForm.userData);
                } else {
                    message.error(t("Please check income type form validation errors"));
                    return;
                }
            }
            //validating payment methods forms
            let customPaymentMethodFormError=false;
            values.SplitPayments.filter(p=>p.formId).forEach((p,index)=>{
                const customForm=window[`customPaymentMethodForm-${index}`];
                if (customForm&& customForm.instanceContainers&&customForm.instanceContainers[0]) {
                    if (customForm.instanceContainers[0].reportValidity()) {
                        p.jValues = JSON.stringify(customForm.userData);
                        p.formId=customForms[index].formId;
                    } else {
                        message.error(t("Please check payment method custom form validation errors"));
                        customPaymentMethodFormError=true;
                    }
                }
            })
           if(customPaymentMethodFormError) return;
            
            if (!err) {
                //validating split payment
                if(values.SplitPayments.some(p=>!p.paymentMethod)){
                    message.error(t("Some payment methods have an invalid method field"));
                    return;
                }
                if(values.SplitPayments.some(p=>!p.amount)){
                    message.error(t("Some payment methods have an invalid amount field"));
                    return;
                }
                if(values.SplitPayments.some(p=>p.conversion&&!p.amountEx)){
                    message.error(t("Some payment methods have an invalid exchange amount field"));
                    return;
                }
                if(values.SplitPayments.some(p=>p.conversion&&!p.currency)){
                    message.error(t("Some payment methods have an invalid currency field"));
                    return;
                }
                if(values.SplitPayments.some(p=>p.conversion&&!p.exchangeRate)){
                    message.error(t("Some payment methods have an invalid exchange rate field"));
                    return;
                }
                //validation OK
                values.isExternal=true;
                values.concept="IW";
                if(internalIncomeType!=="TRANSIT") values.destinationAccountId=destinationAccountId;
                let otherReceivables=[];
                if(isOtherReceivables) {
                    values.transactionCode = "OTHER";
                    otherReceivables=props.selectedOtherReceivables.map(p=>p.entityId);
                    if(props.selectedOtherReceivables.some(p=>p.type=="I")){
                        //non-insurance income
                        values.transactionCode = "INCOMENI";
                        //all items must have the same type I
                        if(props.selectedOtherReceivables.some(p=>p.type!="I")){
                            message.error(t("Other receivables must have the same type"));
                            return;
                        }
                        //must not have selected premiums
                        if(props.selectedPremiums.length>0){
                            message.error(t("Cannot mix premiums with other receivables"));
                            return;
                        }
                    }

                }
                
                values.DestinationAccount=null;
                (values.SplitPayments||[]).forEach(p=>{p.id=0;p.transferId=0});
                if (props.workspaceId) values.transferWorkspaceId = props.workspaceId;
                console.log("Received values of form: ", values);
                setLoading(true);
                exe("RepoTransfer", { operation: "ADD", entity: values,otherReceivables:otherReceivables }).then((r) => {
                    setLoading(false);
                    if (r.ok) {
                        message.success(r.msg);
                        props.onNewManualTransfer && props.onNewManualTransfer();
                    } else {
                        message.error(r.msg);
                    }
                });
            }
        });
    };

    const onSelectExternalSource = (externalSource) => {
        setFormId(externalSource.formId);
        if(!externalSource.destinationAccNo){
            message.error(t("Please define an account number for this external source"));
            return
        }
        setLoading(true);
        exe("RepoAccount", { operation: "GET", filter: `accNo='${externalSource.destinationAccNo}'` }).then((r) => {
            setLoading(false);
            if (r.ok) {
                if(r.total===0){
                    message.error(t("External source account not found"));
                }else{
                    setDestinationAccountId(r.outData[0].id)
                }
            }else  message.error(r.msg);
        });
      
    };
    const onNewRecord=()=>{
        setFormId();
        props.form.resetFields();
        props.form.setFieldsValue({SplitPayments:[{}]});
        setExchangeDate();
        props.onNew();
    }
    const onIncomeTypeSelect=(v,record)=>{
        if(record) {
            setInternalIncomeType(record.internalType);
            setIncomeTypeFormId(record.formId);
            setCostCentersVisible(record.showCostCenters);
        }else {
            setInternalIncomeType(undefined);
            setIncomeTypeFormId(undefined)
            setCostCentersVisible(false);
        }
        
    }
    const onChangeSplitPayments=v=>{
        console.log(v)
        setCustomForms(v.filter(p=>p.formId));
    }
    const getExeIcon=()=>{
        const currentTotal=props.form.getFieldValue("amount");
        const dif=currentTotal-selectedAmount;
        let icon=null;
        if(dif===0) icon="arrow-right";
        if(dif>0) icon="arrow-up";
        if(dif<0) icon="arrow-down";
        return icon;
    }
    const onCurrencyOptionsOk=(options)=>{
        console.log(options)
        if(options.exchangeDate){
            setExchangeDate(options.exchangeDate);
            setCurrencyOptionsVisible(false);
            //props.form.setFieldsValue({currencyDate:options.exchangeDate});
        }
    }
    const dif=props.form.getFieldValue("amount")-selectedAmount;
    const paymentMethods=(props.form.getFieldValue("SplitPayments")||[]).map(p=>p.paymentMethod);
    return (
        <div>
            {field("CostCenters")}
            <Button type="link" icon={<FileOutlined />} onClick={onNewRecord}>{t("New")}</Button>
            <Button type="link" icon={<FundOutlined />} onClick={()=>setCurrencyOptionsVisible(true)}>{t("Currency Options")}</Button>
            <CurrencyOptions visible={currencyOptionsVisible} onOk={onCurrencyOptionsOk} onCancel={()=>setCurrencyOptionsVisible(false)} />
            <CostCenterManagement form={props.form} visible={costCentersVisible} style={{float:"left"}} />
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={8}>
                        <Section text={t("Payment Method(s)")}>
                            {field("SplitPayments")(<SplitPayment selectedAmount={selectedAmount} onChange={onChangeSplitPayments} 
                            currency={currency} defaultCurrency={props.defaultCurrency} 
                            currencyDate={exchangeDate?exchangeDate:props.currencyDate} onChangetotal={(total)=>props.form.setFieldsValue({amount:total})} />)}
                        </Section>
                        <Section text={t("Internal Account Information")}>
                            <Form.Item label={t("Income Type")}>{field("incomeType")(<IncomeTypeSelect onSelect={onIncomeTypeSelect} default={props.selectedPremiums.length>0?"PREMIUM":undefined} />)}</Form.Item>
                            <div style={{display:internalIncomeType==="TRANSIT"?"none":"block"}}>
                                <Form.Item label={t("Destination")}>
                                    {field("sourceExternal", { rules: [{ required: internalIncomeType!=="TRANSIT" }] })(<ExternalSourceSelect currency={currency} paymentMethods={paymentMethods} onSelect={(r) =>onSelectExternalSource(r)} />)}
                                </Form.Item>
                            </div>
                            <div style={{display:internalIncomeType==="TRANSIT"?"block":"none"}}>
                                <Form.Item label={t("Destination")}>
                                    {field("destinationAccountId", { rules: [{ required: internalIncomeType==="TRANSIT" }] })(<AccountSelect  />)}
                                </Form.Item>
                            </div>
                            <Form.Item label={t("Currency")}>{field("currency", { initialValue: props.defaultCurrency, rules: [{ required: true }] })(<Currency />)}</Form.Item>
                            <Form.Item label={t("Amount Payable")}>{field("amount", { rules: [{ required: true }] })(<Money currency={currency} disabled />)}</Form.Item>
                            <div style={{display:["CLAIM","DEDUCTIBLE"].includes(internalIncomeType)?"block":"none"}}>
                                <Form.Item label={t("Claim")}>{field("claimId",{rules:[{required:["CLAIM","DEDUCTIBLE"].includes(internalIncomeType)}]})(<ClaimSelect  />)}</Form.Item>
                            </div>
                            <Form.Item label={t("Difference")}>{<span style={{color:dif<0?"red":"black"}}>{formatMoney(dif,currency)}</span>}</Form.Item>
                        <Button type="primary" onClick={() => onExecute()} loading={loading} disabled={props.disabled} icon={<LegacyIcon type={getExeIcon()} />}>
                            {t("Execute")}
                        </Button>
                        </Section>
                    </Col>
                    <Col span={16}>
                        {formId&&<CustomForm formId={formId} variable="customWorkspaceForm" value={props.selectedPayment&&props.selectedPayment.jForm}  />}
                        <Section text={t("Payment Method(s) Form(s)")} hidden={customForms.length===0}>
                            <Tabs>
                                {customForms.map((p,index)=><Tabs.TabPane tab={`${p.paymentMethodName}`} key={index}>
                                    <CustomForm formId={p.formId} variable={"customPaymentMethodForm-"+index} value={p.jValues}  />
                                </Tabs.TabPane>)}
                            </Tabs>
                        </Section>
                        <Section text={t("Income Type Form")} hidden={!incomeTypeFormId}>
                            <CustomForm formId={incomeTypeFormId} variable="incomeTypeForm" value={props.selectedPayment&&props.selectedPayment.jIncomeTypeForm}  />
                        </Section>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Form.create()(IncomeTransfer);
