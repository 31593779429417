import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import Money from '../Shared/Money';
import DatePickerW from '../Shared/DatePickerW';
import ReasonSelect from './ReasonSelect';


const AmendmentTerm = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const currentStart = props.currentStart;
  const currentEnd = props.currentEnd;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
               {/* CHANGE OPTIONS ***********************************************************************/}
               <div style={{ display: "flex" }}>
                <div>
                  <Form.Item label={t("Current Start")}>{field("oldStart", { initialValue: currentStart })(<DatePickerW disabled />)}</Form.Item>
                  <Form.Item label={t("Current End")}>{field("oldEnd", { initialValue: currentEnd })(<DatePickerW disabled />)}</Form.Item>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <Form.Item label={t("New Start")}>
                    {field("newStart", { initialValue: currentStart, rules: [{ required: false }] })(<DatePickerW />)}
                  </Form.Item>
                  <Form.Item label={t("New End")}>{field("newEnd", { initialValue: currentEnd, rules: [{ required: false }] })(<DatePickerW />)}</Form.Item>
                </div>
              </div>
              <Form.Item label={t("Change Reason")}>
                {field("reason", { rules: [{ required: false }] })(<ReasonSelect catalog="TermChange" />)}
              </Form.Item>
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentTerm);
