import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import Money from '../Shared/Money';


const AmendmentPolicyCapital = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const currentCapital = props.currentCapital;
  const currency = props.currency;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              
              <Form.Item label={t("Current Sum Insured")}>
                {field("oldCapital", { initialValue: currentCapital })(<Money currency={currency} disabled />)}
              </Form.Item>
              <Form.Item label={t("New Sum Insured")}>
                {field("newCapital", { rules: [{ required: true }] })(<Money currency={currency} />)}
              </Form.Item>
            
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentPolicyCapital);
