import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import CoverageTechEdition from './CoverageTechEdition';


const AmendmentCoverageTechData = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
                {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={t("Current Coverage Values")}>
                {field("jOldCoverages", { rules: [{ required: true }], initialValue: JSON.stringify(props.policy.Coverages) })(
                  <CoverageTechEdition currency={currency} disabled config={props.config} />
                )}
              </Form.Item>
              <Form.Item label={t("New Coverage Values")}>
                {field("jNewCoverages", { rules: [{ required: true }], initialValue: JSON.stringify(props.policy.Coverages) })(
                  <CoverageTechEdition currency={currency} config={props.config} policy={props.policy} new />
                )}
              </Form.Item>
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentCoverageTechData);
