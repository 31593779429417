import React from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { HashRouter, Route, Link, Redirect, withRouter } from "react-router-dom";
import "./App.css";
import Mousetrap from "mousetrap";
import Login from "./Components/Login";
import config from "./config";
import MisDatos from "./Components/Misdatos";
//import {Icon as Faicon} from 'react-fa';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClone, faThLarge, faUser } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { LeftOutlined,RightOutlined } from '@ant-design/icons';
import { Layout,Menu,Modal,Avatar,Badge,notification,Result,message,Tooltip,Button,Divider,Dropdown,ConfigProvider, Alert, Tree, Space, Input } from "antd";
import {Icon} from "@ant-design/compatible";
import Configuracion from "./Components/Configuracion";
import {DownOutlined} from '@ant-design/icons';
//import MenuLateral from './Components/MenuLateral';
import { exe, safeGet, safeParse } from "./Lib/Dal";
import FormBuilder from "./Components/FormBuilder";
import Procesos from "./Components/Procesos";
import Actividades from "./Components/Bpm/Actividades";
import Equipo from "./Components/Bpm/Equipo";
import Home from "./Components/Home";
import Inspector from "./Components/Inspector";
import Actividad from "./Components/Bpm/Actividad";
import Launcher from "./Components/Launcher";
import Contact from "./Components/Contact";
import Sisos from "./Components/Sisos";
import Contract from "./Components/Contract";
import ContractList from "./Components/ContractList";
import HealthClaim from "./Components/Health/Claim";
import ContactList from "./Components/ContactList";
import User from "./Components/User";
import Rules from "./Components/Rules";
import RuleTable from "./Components/Rules/RuleTable";
import ClaimList from "./Components/Health/ClaimList";
import Diagnosis from "./Components/Health/Diagnosis";
import Procedure from "./Components/Health/Procedure";
import Provider from "./Components/Health/Provider";
import ProviderSearch from "./Components/Health/ProviderSearch";
import LifePolicy from "./Components/Life/LifePolicy";
import Products from "./Components/Products";
import Product from "./Components/Products/Product";
import InvestmentPlans from "./Components/InvestmentPlans";
import InvestmentPlan from "./Components/InvestmentPlans/InvestmentPlan";
import Transfers from "./Components/Transfers/Transfers";
import LifePolicyList from "./Components/Life/LifePolicyList";
import Supervisor from "./Components/Settings/Supervisor";
import Accounts from "./Components/Financial/Accounts";
import Annuity from "./Components/Annuities/Annuity";
import AnnuityList from "./Components/Annuities/AnnuityList";
import Account from "./Components/Financial/Account";
import Cessions from "./Components/Contract/Cessions";
import Collection from "./Components/Collection/Collection";
import ClaimReserves from "./Components/Reserves/ClaimReserves";
import ComContract from "./Components/Commissions/ComContract";
import Commissions from "./Components/Commissions/Commissions";
import SearchSmall from "./Components/SearchSmall";
import Production from "./Components/Control/Production";
import ComContractList from "./Components/Commissions/ComContractList";
import Notifications from "./Components/Notifications";
import StatusPage from "./Components/StatusPage";
import TransactionLog from "./Components/Accounting/TransactionLog";
import Allocation from "./Components/Transfers/Allocation";
import AccountSimulator from "./Components/Financial/AccountSimulator";
import LogExplorer from "./Components/Settings/LogExplorer";
import Templates from "./Components/Settings/Templates";
import Portal from "./Components/SelfServiceBuy";
import Movements from "./Components/Accounting/Movements";
import Triggers from "./Components/Settings/Triggers";
import Communications from "./Components/Contact/Communications";
import HtmlTemplates from "./Components/Settings/HtmlTemplates";
import Payments from "./Components/Payments/Payments";
import ChartOfAccounts from "./Components/Accounting/ChartOfAccounts";
import Transactions from "./Components/Accounting/Transactions";
import RecordImport from "./Components/Life/RecordImport";
import ProductConfigurator from "./Components/Products/ProductConfigurator";
import CommandChains from "./Components/Bpm/CommandChains";
import AuditLogExplorer from "./Components/Settings/AuditLogExplorer";
import Dashboard from "./Components/EventBus/Dashboard";
import BillingSummary from "./Components/Collection/BillingSummary";
import ReportList from "./Components/Reports/ReportList";
import ReportView from "./Components/Reports/ReportView";
import CalcSheet from "./Components/Shared/CalcSheet";
import Language from "./Components/Configuracion/Language";
import FileManager from "./Components/Shared/FileManager";
import TaxSchemeList from "./Components/Tax/TaxSchemeList";
import TaxScheme from "./Components/Tax/TaxScheme";
import ObjectDefinition from "./Components/Property/ObjectDefinition";
import TimeLapse from "./Components/TimeLapse/TimeLapse";
import TransactionTemplates from "./Components/Accounting/TransactionTemplates";
import License from "./Components/License";
import Cumulus from "./Components/Contract/Cumulus";
import getCache from "./Lib/Cache";
import ComAllLiquidations from "./Components/Commissions/ComAllLiquidations";
import ReEvents from "./Components/Contract/ReEvents";
import CoCessions from "./Components/Coinsurance/CoCessions";
import InstallmentSchemeList from "./Components/Installments/InstallmentSchemeList";
import InstallmentScheme from "./Components/Installments/InstallmentScheme";
import TaxGenerated from "./Components/Tax/TaxGenerated";
import Extra from "./Components/Extra";
import GoalDefinition from "./Components/Goals/GoalDefinition";
import GoalDefinitionList from "./Components/Goals/GoalDefinitionList";
import GoalStatus from "./Components/Goals/GoalStatus";
import Portfolios from "./Components/Ifrs17/Portfolios";
import Portfolio from "./Components/Ifrs17/Portfolio";
import Measurements from "./Components/Ifrs17/Measurements";
import Measurement from "./Components/Ifrs17/Measurement";
import Presentations from "./Components/Ifrs17/Presentations";
import Presentation from "./Components/Ifrs17/Presentation";
import Workspaces from "./Components/Financial/Workspaces";
import Closure from "./Components/Accounting/Closure";
import AccountingMovements from "./Components/Accounting/AccountingMovements";
import Text from "antd/lib/typography/Text";
import Credit from "./Components/Credit/Credit";
import CreditList from "./Components/Credit/CreditList";
import DebtStatus from "./Components/Credit/DebtStatus";
import CoLiquidations from "./Components/Coinsurance/CoLiquidations";
import ReLiquidations from "./Components/Re/ReLiquidations";
import View from "./Components/Bpm/View";
import LiveViews from "./Components/Bpm/LiveViews";
import FiscalDocs from "./Components/Tax/FiscalDocs";
import FiscalDocsGenerated from "./Components/Tax/FiscalDocsGenerated";
import LocationCumulus from "./Components/Re/LocationCumulus";
import CustomCumulus from "./Components/Re/CustomCumulus";
import OpenFile from "./Components/Shared/OpenFile";
import ComTreeList from "./Components/Commissions/ComTreeList";
import ComTree from "./Components/Commissions/ComTree";
import CalculationSchemes from "./Components/Bpm/CalculationSchemes";
import ProfitCommissionList from "./Components/Re/ProfitCommissionList";
import ProfitCommission from "./Components/Re/ProfitCommission";
import CoContract from "./Components/Coinsurance/CoContract";
import CoContractList from "./Components/Coinsurance/CoContractList";
import IssueTracker from "./Components/Contact/IssueTracker";
import PaymentMonitor from "./Components/Financial/PaymentMonitor";
import PensionScheme from "./Components/Pensions/PensionScheme";
import PensionList from "./Components/Pensions/PensionList";
import Contributions from "./Components/Pensions/Contributions";
import PensionProducts from "./Components/Pensions/PensionProducts";
import ProgressWindow from "./Components/Shared/ProgressWindow";
import FundList from "./Components/Pensions/FundList";
import Fund from "./Components/Pensions/Fund";
import PortfolioTransfers from "./Components/Contract/PortfolioTransfers";
import Ai from "./Components/Ai/Ai";
import AnnuityProducts from "./Components/Annuities/AnnuityProducts";
import PasswordChange from "./Components/Shared/PasswordChange";
import BulkTransactions from "./Components/Accounting/BulkTransactions";
import RecentMenu from "./Components/RecentMenu";
import Gantt from "./Components/Projects/Gantt";
import OtherMovements from "./Components/Financial/OtherMovements";

const { Header, Sider, Content } = Layout;

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class App extends React.Component {
  state = {
    collapsed: localStorage.collapsed==="true",
    loginView: false,
    mobileView: false,
    isAdmin: false,
    user: "",
    usuarios: [],
    selectedKeysMenu1:[localStorage.selectedKeyMenu1], //selected modules in first menu
    selectedKeys: [localStorage.selectedKey], //selected keys in second menu
    openKeys:localStorage.openKeys?JSON.parse(localStorage.openKeys):[], //open keys in expanded menu
    history: [],
    historyVisited: [],
    menuModule: { name: "Life", icon: "heart", color: "#2ecc71",id:"Life" },
    notificationsVisible: false,
    statusPageVisible: false,
    configProfile: undefined,
    searchBoxVisible: false,
    navigatorVisible: false,
    languages: [],
    localeLoaded:false,
    notifications:0,
    disconnection:false,
    doReloadNotifications:0,
    organization:null,
    direction:"ltr",
    currentUser:{},
    warningMsg:null
  };
  handleBeforeUnload = (e) => {
    if(localStorage.leader===this.tabId){
      this.notifications.close();
      localStorage.removeItem("leader");
      window.channel.postMessage("disconnection");
    }
    //saving state on local storage
    localStorage.setItem(
      "appPreferences",
      JSON.stringify({
        selectedKeys: this.state.selectedKeys,
        selectedKeysMenu1: this.state.selectedKeysMenu1,
        menuModule: this.state.menuModule,
        collapsed: this.state.collapsed,
        openKeys: this.state.openKeys,
        history: this.state.history.slice(-20),
        historyVisited: this.state.historyVisited.slice(-20),
      })
    );
}
  componentDidMount() {
    console.log(`%c ${config.version} SISos Core Version -*****-`, "color: white;background-color:green;font-size:12px;");
    console.log("%c \u00A9 Axxis Systems", "color:white;background-color:red");
    this.loadLanguages();
    this.tabId=Math.random().toString().slice(2); //setting unique tab id for intertab coms
    //loading channel
    window.channel = new BroadcastChannel('app-data');
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.props.history.listen((location, action) => {
      console.log("HISTORY--", action, location.pathname, location.state);
      //adding to history and removing if entry already exists
      let history = this.state.history;
      history = history.filter((p) => p !== location.pathname);
      history.push(location.pathname);
      //the same for historyVisited
      let historyVisited = this.state.historyVisited;
      historyVisited = historyVisited.filter((p) => p.location !== location.pathname);
      historyVisited.push({ location: location.pathname, visited: new Date().toISOString() });

      this.setState({ history: history.slice(-20),historyVisited: historyVisited.slice(-20) });
    });

    if (window.location.hash === "#/login") {
      this.setLoginView(true);
    }
    //ping status de auth
    getCache("GetCurrentUser")
      .then((r) => {
        if (!r.ok) {
          throw new Error("Token not valid");
        } else {
          return r;
        }
      })
      .then((r) => {
        localStorage.esAdmin = r.outData.esAdmin; //actualizando x si ha sifo revocado
        localStorage.nombre = r.outData.nombre;
        localStorage.email = r.outData.email;
        window.GLOBAL.currentUser = r.outData;
        this.checkPermissions(this.props.location.pathname);
        this.onLogin(r.outData, true);
      })
      .catch((err) => {
        this.onSalir();
      });

  }
  componentWillUnmount() {
    //hotkeys unbind
    Mousetrap.reset();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    // if(localStorage.leader===this.tabId){
    //   //closing, new leader needed. Also done on windows.onunload event
    //   this.notifications.close();
    //   localStorage.removeItem("leader");
    //   window.channel.postMessage("disconnection");
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.checkPermissions(this.props.location.pathname);
    }
  }
  checkPermissions(path) {
    if (path === '/') {
      // Exclude permission check for root path
      this.setState({ unauthorized: false });
      return;
    }
    getCache("GetCurrentUser").then((r) => {
      console.log("Checking permissions for path: ", path, r.outData.jPermissions);
      
      this.setState({ unauthorized: r.outData.jPermissions.some((p) => p.includes(path)||path.startsWith(p)) }); //warning if paths have common beginning will be unauthorized
    });
  }
  checkTabPermissions=(path,isRestricted)=>new Promise((resolve,reject)=> {
    console.log("Checking permissions for tab: ", path);
    if(!path) {
      resolve(true);
      return;
    }
    getCache("GetCurrentUser").then((r) => {
      const authorized=!r.outData.jPermissions.some((p) => path.includes(p));
      if(!authorized) resolve(false);
      if(!isRestricted) resolve(true);
      else{
        //checking if restricted tab is authorized
        console.log("Checking restricted permissions");
        const restrictedAuthorized=!r.outData.jPermissions.some((p) => p.includes(path+"restricted"));
        resolve(restrictedAuthorized);
      }
    })
  });
  
  loadConfigProfile() {
    exe("RepoConfigProfile", { operation: "GET" }).then((r) => {
      if (!r.ok) {
        console.error("Error loading config Profile");
        return;
      }
      if (r.outData.length == 0) {
        console.error("No config profiles found");
        return;
      }
      const configProfileRecord=r.outData.find((p) => p.active);
      if (!configProfileRecord) {
        console.error("No active config profile not found");
        return;
      }
      const configProfile = JSON.parse(configProfileRecord.configJson);
      window.global.configProfile = configProfile;
      this.setState({ configProfile: configProfile, defaultCurrency: safeGet(["Main", "defaultCurrency"], configProfile, "USD"),warningMsg:configProfileRecord.warningMsg });
      const companyName = " | " + safeGet(["Main", "company"], configProfile, "");
      if (companyName !== localStorage.companyName) {
        localStorage.companyName = companyName;
        window.location.reload();
      }
      //setting default language if none is selected
      if(!localStorage.language){
        const defaultLanguage=safeGet(["Main","defaultLanguage"],configProfile,"");
        if(defaultLanguage!==""){
          localStorage.language=defaultLanguage;
          window.location.reload();
        }
      }
      //if there is a warningMsg, show it every 5 minutes
      if(configProfileRecord.warningMsg&&configProfileRecord.warningMsg.toLowerCase().includes("payment")){
        setInterval(()=>{
          Modal.warning({
            title:"License Warning",
            content:configProfileRecord.warningMsg,
          });
        },60000);
      }

    });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      mobileView: this.mobile(),
    });
    localStorage.collapsed= !this.state.collapsed;
  };
  setLoginView = (value) => {
    this.setState({ loginView: value, collapsed: true });
  };
  onLogin = (user, local) => {
    if(user.contactId){
      //self service user
      this.setState({unauthorized:true});
      return;
    }
    const appPreferences = safeParse(localStorage.appPreferences) || {};
    this.setState({
      isAdmin: JSON.parse(localStorage.esAdmin),
      user: localStorage.email,
      userName: user.nombre,
      currentUser:user,
      ...appPreferences,
    });
    this.loadConfigProfile();
    this.loadNotifications();
    if (!local) {
      //if called from login page
      i18next.reloadResources(); //were not loaded bcs user not authorized.
      this.loadLanguages();
    }
    //checking if the user should change his password
    console.log(user)
    if(user.forcePasswordChange) this.setState({passwordChangeVisible:true});

    //password expiration warning
    if(user.expireWarning>0){
      notification.warning({
        message: this.props.t('Password Expiration'),
        key:"expirationWarningNotification",
        duration:0,
        description:<div>
          {this.props.t("Your password will expire in ")+user.expireWarning+this.props.t(" days")+". "+this.props.t("Please change your password to avoid user lockout.")}
          <Button style={{marginLeft:0,paddingLeft:0}} type="link" onClick={()=>{notification.close("expirationWarningNotification");window.location="/#/profile"}}>{this.props.t("Go to my user profile")}</Button>
        </div>
      });
    }
    //organization selection
    this.setOrganization(user.Organization);
    //hotkeys bind
    Mousetrap.bind("g f", () => {
      this.setState({ searchBoxVisible: !this.state.searchBoxVisible });
    });
    Mousetrap.bind("g n", () => {
      this.setState({ navigatorVisible: !this.state.navigatorVisible });
    });
    Mousetrap.bind("g h", () => {
      window.location = "/#/home";
    });
    Mousetrap.bind("g r", () => {
      this.setState({ startVisible: !this.state.startVisible });
    });
    Mousetrap.bind("g l", () => {
      this.setState({ searchVisible: !this.state.searchVisible });
    });
    //menuitems
    //settings
    Mousetrap.bind("c s", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Supervisor"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c t", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Triggers"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c y", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("System Log"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c a", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Audit Log"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c e", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Event Bus"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c l", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Language"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c f", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("File Manager"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c g", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Settings")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("General"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    //Product Workshop
    Mousetrap.bind("c p", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Products"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c i", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Financial Products"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c c", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Actuarial Sheet"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c m", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("File Templates"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c h", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("HTML Templates"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c o", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("Product Workshop")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Object Definition"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    //bpm
    Mousetrap.bind("c 1", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Process"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 2", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Business Rules"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 3", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Tables"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 4", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Forms"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 5", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Tasks"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 6", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Launcher"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 7", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Chains"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 8", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Views"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });
    Mousetrap.bind("c 9", () => {
      const item=this.allMenuItems&&this.allMenuItems.filter(p=>p.section=="Config"&&p.texto==this.props.t("BPM")).map(p=>p.children).flat().find(p=>p.texto==this.props.t("Calculation Schemes"))
      if(item.disabled) message.error(this.props.t("Shortcut disabled"));else this.props.history.push(item.link);
    });

    
    

  };
  setOrganization = (organization) => {
    if(organization)
    {
      localStorage.organizationName=organization.name;
      localStorage.organizationCode=organization.code;
    }else {
      localStorage.removeItem("organizationName");
      localStorage.removeItem("organizationCode");
    }
    this.setState({ organization: organization });
  }
  setDefaultLanguage=()=> {
    localStorage.isoCode="en";
    moment.locale("en");
    this.setState({localeLoaded:true});
  }
  loadLanguages = () => {
    //setting user direction preference
    if(localStorage.direction) this.setState({direction:localStorage.direction});
    exe("GetLanguages").then((r) => {
          if (r.ok) {
            this.setState({languages: r.outData});
            if(localStorage.language){ 
              const currentLanguage=(r.outData||[]).find(p=>p.code===localStorage.language);
              if(currentLanguage&&currentLanguage.isoCode){
                try {
                  //automatic direction set by language if no user preference set
                  if(!localStorage.direction) this.setLanguageDirection(currentLanguage.isoCode);
                  //patches for locales not included by default in moment
                  let isoCode=currentLanguage.isoCode;
                  if(isoCode==="es-cl") isoCode="es-mx"; //using mx locale for date but es-cl for currency
                  import('moment/locale/'+isoCode).then(l=>{
                    localStorage.isoCode=currentLanguage.isoCode;
                    moment.updateLocale(isoCode, l);
                    this.setState({localeLoaded:true});
                  })
                }catch (e) {
                  //iso code not found in moment locale, using default
                  console.warn("No language iso code found. Using default",e)
                 this.setDefaultLanguage();
                }
                this.changeComponentsLanguage(currentLanguage.isoCode);
               
              }else{
                //iso code not found, using default
                console.warn("No language iso code found. Using default")
                this.setDefaultLanguage();
              }
            }else this.setDefaultLanguage();
          }
        }
    );
  };
  changeComponentsLanguage=localeCode=>{
    try{
      //loading antd components locale for non-en langs
      if(localeCode.includes("en-")){
        this.setState({antdLocale:null});
      }else{
        //getting the first 2 letters of the iso code
        const letterIsoCode=localeCode.split('-')[0];
        //composing code for antd locale, removing - and converting to lower case and adding same code uppercase ie: es -> es_ES
        let antdLocaleCode=letterIsoCode+"_"+letterIsoCode.toUpperCase();
        if(antdLocaleCode==="ar_AR") antdLocaleCode="ar_EG"; //arabic egyptian ony available
        import(`antd/es/locale/${antdLocaleCode}.js`).then(r=>{
          this.setState({antdLocale:r.default})
        } )
      }
    }catch(e){
      console.warn("Error loading components locale",e);
    }
  }
  tabId;
  notifications;
  loadNotifications=()=>{
    //setting leader if not present
    if(!localStorage.leader) localStorage.leader=this.tabId;
    
    //loading leader logic
    if(localStorage.leader===this.tabId){
      //notifications
      console.log("loading leader logic");
      this.notifications = new EventSource(config.ssApiUrl + "/stream?session="+encodeURIComponent(localStorage.token));
      
      this.notifications.onopen = function(event) {
        console.log('onopen----');
      };
      this.notifications.onerror = (event)=> {
        console.log('onerror',event);
        this.setState({disconnection:true});
        //schedule recovery in 5s
        setTimeout(()=>this.setState({disconnection:false}),5000);
      }
      //reloading notifications component
      this.setState({doReloadNotifications:this.state.doReloadNotifications+1});
    }else console.log("Follower mode",localStorage.leader,this.tabId);
  }
  setLanguage = (code,isoCode) => {
    console.log("Setting language",code,isoCode);
    this.props.i18n.changeLanguage(code);
    localStorage.setItem("language", code);
    if(isoCode){
      this.setLanguageDirection(isoCode);
      this.changeComponentsLanguage(isoCode);
      import('moment/locale/'+isoCode).then(r=>{
        moment.updateLocale(isoCode, r);
      })
    }
    
  };
  handleMenuClick = (e) => {
    console.log("click", e);
  };
  mobile() {
    return !window.matchMedia("(min-width: 480px)").matches;
  }
  notifyItems(p) {
    console.log(p);
  }
  onSalir = () => {
    //hotkeys unbind
    Mousetrap.reset();
    localStorage.removeItem("token"); 
    localStorage.removeItem("nombre");
    localStorage.removeItem("email");
    localStorage.removeItem("esAdmin");
    window.GLOBAL.currentUser = {};
    //if already in login view, do nothing
    if(window.location.hash.includes("login")) return;

    //checking if azure ad login
    if(window.sessionStorage.adLogin){
      window.sessionStorage.removeItem("adLogin");
      //redirecting to azure ad logout
      const tenant=window.sessionStorage.adTenant;
      const loginPage=`${window.location.origin}/#/login?adAuth=1`;
      window.location=`https://login.microsoftonline.com/${tenant}/oauth2/logout?post_logout_redirect_uri=${loginPage}`;
    }else this.props.history.push("/login");
  };
  addBookmark() {
    let bookmarks = localStorage.bookmarks;
    if (bookmarks) bookmarks = JSON.parse(bookmarks);
    else bookmarks = [];
    if (bookmarks.map((p) => p.url).includes(window.location.hash)) {
      message.error(this.props.t("Bookmark already exists"));
    } else {
      bookmarks.push({ url: window.location.hash, index: bookmarks.length });
      console.log(bookmarks);
      localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
      message.success(this.props.t("Bookmark added"));
    }
  }
  toggleDirection(){
    const newDirection=this.state.direction==="rtl"?"ltr":"rtl";
    this.setState({direction:newDirection});
    localStorage.direction=newDirection;
  }
  setLanguageDirection=isoCode=>{
    //getting language direction
    const intLocale=new Intl.Locale(isoCode);
    this.setState({direction:intLocale.textInfo.direction});
    console.log("Setting language direction: ",intLocale.textInfo.direction);
  }
  computeFormula(f){
    try {
      const context = {currentUser:this.state.currentUser};
      f = f.replaceAll("_", "context.");
      console.log(f, context, "CONTEXT");
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side", error);
      return undefined;
    }
  };
  render() {
    const { t, i18n } = this.props;
    const userSplit=this.state.userName?this.state.userName.split(' '):['-',''];
    const initials=userSplit[0].charAt(0)+(userSplit.length>1?userSplit[1].charAt(0):'');
    const menuUser = (
      <Menu>
        <div style={{display:"flex",alignItems:"center",padding:"5px 12px"}}>
          <Avatar  size="small" >{initials}</Avatar>
          <Text ellipsis style={{fontSize:11,fontWeight:"bold",marginLeft:3,marginRight:3}} >{this.state.userName}</Text>
        </div>
        <Menu.Divider />
        <Menu.Item key="1">
          <Link to="/profile">{t("Profile")}</Link>
        </Menu.Item>
        <Menu.SubMenu key="3" title={<span style={{ marginRight: this.state.direction=="ltr"?5:0,marginLeft:this.state.direction=="rtl"?5:0 }}>{t("Language")}</span>} onClick={(item) => this.setLanguage(item.key,item.item.props.isoCode)}>
          {this.state.languages.map((p) => (
            <Menu.Item key={p.code} isoCode={p.isoCode}>{p.code == localStorage.language ? <b>{p.name}</b> : p.name}</Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.Item key="direction" onClick={()=>this.toggleDirection()}>
          {this.state.direction === "rtl" ? "LTR": "RTL"}
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ licenseVisible: true })} key="license">
          {t("License")}
        </Menu.Item>
        <Menu.Item onClick={()=>onSelfService()} key="selfservice">
          {t("Self Service")}
        </Menu.Item>
        <Menu.Item onClick={()=>onUinsurer()} key="uInsurer">
          {t("uInsurer")}
        </Menu.Item>
        <Menu.Item onClick={this.onSalir} key="2">
          {t("Logout")}
        </Menu.Item>
      </Menu>
    );
    const onSelfService=()=>{
      if(window.sessionStorage.contactId) window.sessionStorage.removeItem("contactId");
      window.open("#/SelfService/home", '_blank').focus()
      //window.location.hash="/SelfService/home";
      //window.location.reload();
    }
    const onUinsurer=()=>{
      window.location.hash="/login?org=1";
    }
    let menuHeaders = [
      { id: "Contacts", name: t("Contacts"), icon: "team", color: "#4a69bd",treeId:4 },
      { id: "Life", name: t("Life"), icon: "heart", color: "#2ecc71",treeId:1 },
      { id: "Health", name: t("Health"), icon: "medicine-box", color: "#87d068",treeId:1 },
      { id: "Property", name: t("Property"), icon: "home", color: "#e74c3c",treeId:1 },
      { id: "Pensions", name: t("Pensions"), icon: "fund", color: "#4A90E2",treeId:1 },
      { id: "Technical", name: t("Technical"), icon: "calculator", color: "#ff7979",treeId:2 },
      { id: "Administration", name: t("Administration"), icon: "pie-chart", color: "#45aaf2",treeId:3 },
      { id: "Commercial", name: t("Commercial"), icon: "shopping", color: "#a55eea",treeId:2 },
      { id: "Control", name: t("Control"), icon: "crown", color: "#f56a00",treeId:4 },
      { id: "Config", name: t("Config"), icon: "setting", color: "#fbc214",treeId:5 },
    ];
    let allMenuItems = [
      {
        section: "Pensions",
        texto: t("Schemes"),
        icono: "fund",
        children: [
          { texto: t("New Scheme"), link: "/pensionScheme", icono: "fund" },
          { texto: t("Scheme List"), link: "/pensionList", icono: "search" },
          { texto: t("Contributions"), link: "/contributions", icono: "download" },
          { texto: t("Products"), link: "/pensionProducts", icono: "control" },
          { texto: t("Funds"), link: "/funds", icono: "bars" },
        ],
      },
      {
        section: "Pensions",
        texto: t("Tools"),
        icono: "tool",
        children: [
          { texto: t("Import"), link: "/import", icono: "upload" },
          { texto: t("TimeLapse"), link: "/timelapse", icono: "clock-circle" },
        ],
      },
      {
        section: "Property",
        texto: t("Policies"),
        icono: "home",
        children: [
          { texto: t("New Policy"), link: "/lifePolicy/0?&section=property", icono: "safety" },
          { texto: t("Policies"), link: "/policies?&section=property", icono: "search" },
        ],
      },
      {
        section: "Property",
        texto: t("Claims"),
        icono: "medicine-box",
        children: [
          { texto: t("New Claim"), link: "/healthclaim/0", icono: "warning" },
          { texto: t("Claim List"), link: "/claimList", icono: "folder-open" },
          { texto: t("Diagnosis"), link: "/diagnosis", icono: "monitor" },
          { texto: t("Procedures"), link: "/procedure", icono: "global" },
         
        ],
      },
      {
        section: "Property",
        texto: t("Tools"),
        icono: "tool",
        children: [
          { texto: t("Import"), link: "/import", icono: "upload" },
          { texto: t("TimeLapse"), link: "/timelapse", icono: "clock-circle" },
        ],
      },
      {
        section: "Commercial",
        texto: t("Intermediaries"),
        icono: "shopping",
        children: [
          {
            texto: t("Contract"),
            link: "/comContract/0",
            icono: "shopping-cart",
          },
          {
            texto: t("Contract List"),
            link: "/comContracts",
            icono: "ordered-list",
          },
          {
            texto: t("Commissions"),
            link: "/commissions",
            icono: "shopping",
          },
          {
            texto: t("Liquidation Orders"),
            link: "/liquidations",
            icono: "wallet",
          },
          {
            texto: t("Multi Level Schemes"),
            link: "/comTreeList",
            icono: "gold",
          },
        ],
      },
      {
        section: "Commercial",
        texto: t("Goals"),
        icono: "trophy",
        children: [
          {
            texto: t("Goal Definitions"),
            link: "/goalDefinitionList",
            icono: "unordered-list",
          },
          {
            texto: t("Goal Status"),
            link: "/goalStatus",
            icono: "gift",
          },
        ],
      },
      {
        section: "Config",
        texto: t("BPM"),
        icono: "branches",
        children: [
          {
            texto: t("Process"),
            link: "/proc",
            icono: "retweet",
            disabled: !this.state.isAdmin,
          },
          { texto: t("Business Rules"), link: "/rules", icono: "layout" },
          {
            texto: t("Tables"),
            link: "/ruletable",
            icono: "table",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Forms"),
            link: "/fb",
            icono: "file-text",
            disabled: !this.state.isAdmin,
          },
          { section: "Administration", texto: t("Tasks"), link: "/tasks", icono: "pushpin" },
          { texto: t("Launcher"), link: "/launcher", icono: "rocket" },
          {
            texto: t("Chains"),
            link: "/chains",
            icono: "link",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Views"),
            link: "/views",
            icono: "codepen",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Calculation Schemes"),
            link: "/calculationSchemes",
            icono: "code",
          },
        ],
      },
      {
        section: "Config",
        texto: t("Product Workshop"),
        icono: "dropbox",
        children: [
          {
            texto: t("Products"),
            link: "/products",
            icono: "shopping-cart",
            disabled: !this.state.isAdmin,
          },
          { texto: t("Financial Products"), link: "/FinancialProducts", icono: "dollar" },
          {
            texto: t("Actuarial Sheet"),
            link: "/calcsheet",
            icono: "solution",
          },
          {
            texto: t("File Templates"),
            link: "/templates",
            icono: "file-word",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("HTML Templates"),
            link: "/htmltemplates",
            icono: "html5",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Object Definition"),
            link: "/objectdefinition",
            icono: "build",
            disabled: !this.state.isAdmin,
          },
        ],
      },
      {
        section: "Config",
        texto: t("Settings"),
        icono: "setting",
        children: [
          {
            texto: t("Supervisor"),
            link: "/supervisor",
            icono: "clock-circle",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Triggers"),
            link: "/triggers",
            icono: "robot",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("System Log"),
            link: "/log",
            icono: "audit",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Audit Log"),
            link: "/auditlog",
            icono: "history",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Event Bus"),
            link: "/eventbus",
            icono: "deployment-unit",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Language"),
            link: "/language",
            icono: "flag",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("File Manager"),
            link: "/filemanager",
            icono: "folder",
            disabled: !this.state.isAdmin,
          },
          {
            texto: t("Projects"),
            link: "/gantt",
            icono: "project",
          },
          {
            texto: t("General"),
            link: "/config",
            icono: "setting",
            disabled: !this.state.isAdmin,
          },
        ],
      },
      {
        section: "Control",
        texto: t("Operating"),
        icono: "container",
        children: [
          { texto: t("Claims"), link: "/claimReserves", icono: "warning" },
          { texto: t("Cessions"), link: "/cessions", icono: "interaction" },
          { texto: t("Production"), link: "/production", icono: "rocket" },
          { texto: t("Billing"), link: "/collection", icono: "dollar" },
          { texto: t("Billing Summary"), link: "/billingSummary", icono: "file-sync" },
        ],
      },
      {
        section: "Control",
        texto: t("User Reports"),
        icono: "audit",
        children: [
          { texto: t("Viewer"), link: "/reportlist", icono: "eye" },
          { texto: t("Designer"), link: "/reportDesigner", icono: "tool" },
        ],
      },
      {
        section: "Control",
        texto: t("IFRS 17"),
        icono: "block",
        children: [
          { texto: t("Portfolios"), link: "/portfolios", icono: "build" },
          { texto: t("Measurement"), link: "/measurements", icono: "control" },
          { texto: t("Presentation"), link: "/presentations", icono: "camera" },
        ],
      },

      {
        section: "Administration",
        texto: t("Financial"),
        icono: "bank",
        children: [
          { texto: t("Transfers"), link: "/transfers", icono: "retweet" },
       
          { texto: t("Accounts"), link: "/accounts", icono: "global" },
          { texto: t("New Account"), link: "/account/0", icono: "plus-circle" },

          { texto: t("Investment Plans"), link: "/InvestmentPlans", icono: "euro" },
          { section: "Administration", texto: t("Account Simulator"), link: "/accountsim", icono: "experiment" },
          { section: "Administration", texto: t("Other Movements"), link: "/othermovs", icono: "bulb" },
        ],
      },
      {
        section: "Administration",
        texto: t("Money In"),
        icono: "download",
        children: [
          { section: "Administration",texto: t("Cashier"), link: "/cashier", icono: "folder" },
          { section: "Administration", texto: t("Billing Summary"), link: "/billingSummary", icono: "file-sync" },
          { section: "Administration", texto: t("Collection"), link: "/collection", icono: "schedule" },
          {
            section: "Administration",
            texto: t("Allocation"),
            icono: "api",
            link: "/allocation",
          },
          {
            section: "Administration",
            texto: t("Installment Schemes"),
            icono: "gold",
            link: "/InstallmentSchemeList",
          },
          {
            section: "Administration",
            texto: t("Payment Monitor"),
            icono: "monitor",
            link: "/paymentMonitor",
          },
        ],
      },
      {
        section: "Administration",
        texto: t("Money Out"),
        icono: "upload",
        children: [{ section: "Administration", texto: t("Payments"), link: "/payments", icono: "upload" }],
      },
      {
        section: "Administration",
        texto: t("Taxes"),
        icono: "transaction",
        children: [
          { section: "Taxes", texto: t("Tax Schemes"), link: "/taxschemelist", icono: "transaction" },
          { section: "Taxes", texto: t("Tax Generated"), link: "/taxgenerated", icono: "pound" },
        ],
      },
      {
        section: "Administration",
        texto: t("Fiscal Documents"),
        icono: "account-book",
        children: [
          { section: "Fiscal", texto: t("Series"), link: "/fiscaldocs", icono: "ordered-list" },
          { section: "Fiscal", texto: t("Generated"), link: "/fiscaldocsgenerated", icono: "tags" },
        ],
      },
      {
        section: "Administration",
        texto: t("Accounting"),
        icono: "book",
        children: [
          { section: "Accounting", texto: t("Chart of Accounts"), link: "/accounting/coa", icono: "menu-unfold" },
          { section: "Accounting", texto: t("Transactions"), link: "/accounting/tx", icono: "reconciliation" },
          { section: "Accounting", texto: t("Templates"), link: "/accounting/templates", icono: "transaction" },
          { section: "Accounting", texto: t("Closure"), link: "/accounting/closure", icono: "safety" },
          { section: "Accounting", texto: t("Movements"), link: "/accounting/movements", icono: "diff" },
          { section: "Accounting", texto: t("Differentials"), link: "/movements", icono: "calendar" },
          { section: "Accounting", texto: t("Bulk Transactions"), link: "/bulkTransactions", icono: "import" },
        ],
      },
      {
        section: "Administration",
        texto: t("Credit"),
        icono: "dollar",
        children: [
          { section: "Administration", texto: t("New Credit"), link: "/credit", icono: "credit-card" },
          { section: "Administration", texto: t("Credits Issued"), link: "/creditlist", icono: "folder-open" },
          { section: "Administration", texto: t("Debt status"), link: "/debtstatus", icono: "fullscreen-exit" },
        ],
      },
      {
        section: "Life",
        texto: t("Policies"),
        icono: "heart",
        children: [
          { texto: t("New Policy"), link: "/lifePolicy/0?&section=life", icono: "safety" },
          { texto: t("Policies"), link: "/policies?&section=life", icono: "search" },
        ],
      },
      {
        section: "Life",
        texto: t("Claims"),
        icono: "medicine-box",
        children: [
          { texto: t("New Claim"), link: "/healthclaim/0", icono: "warning" },
          { texto: t("Claim List"), link: "/claimList", icono: "folder-open" },
          { texto: t("Diagnosis"), link: "/diagnosis", icono: "monitor" },
          { texto: t("Procedures"), link: "/procedure", icono: "global" },
          
        ],
      },
      {
        section: "Life",
        texto: t("Annuities"),
        icono: "history",
        children: [
          { texto: t("New Annuity"), link: "/Annuity/0", icono: "pull-request" },
          { texto: t("Annuities"), link: "/annuities", icono: "search" },
          { texto: t("Annuity Products"), link: "/annuityProducts", icono: "control" },
        ],
      },
      {
        section: "Life",
        texto: t("Tools"),
        icono: "tool",
        children: [
          { texto: t("Import"), link: "/import", icono: "upload" },
          { texto: t("TimeLapse"), link: "/timelapse", icono: "clock-circle" },
        ],
      },
      {
        section: "Technical",
        texto: t("Reinsurance"),
        icono: "audit",
        children: [
          { texto: t("Contract"), link: "/contract/0", icono: "file-protect" },
          { texto: t("Contract List"), link: "/contractlist", icono: "bars" },
          { texto: t("Cessions"), link: "/cessions", icono: "interaction" },
          { texto: t("Cumulus"), link: "/cumulus", icono: "menu-unfold" },
          { texto: t("Location Cumulus"), link: "/locationCumulus", icono: "compass" },
          { texto: t("Custom Cumulus"), link: "/customCumulus", icono: "dot-chart" },
          { texto: t("Events"), link: "/reEvents", icono: "thunderbolt" },
          { texto: t("Liquidations"), link: "/reLiquidations", icono: "dollar" },
          { texto: t("Profit Commission"), link: "/profitCommission", icono: "carry-out" },
          { texto: t("Portfolio Transfers"), link: "/portfolioTransfers", icono: "pullRequest" },
        ],
      },
      {
        section: "Technical",
        texto: t("Coinsurance"),
        icono: "pie-chart",
        children:[
            { texto: t("Contract"), link: "/coContract/0", icono: "file-protect" },
            { texto: t("Contract List"), link: "/coContractList", icono: "bars" },
            { texto: t("Cessions"), link: "/coCessions", icono: "pie-chart" },
            { texto: t("Liquidations"), link: "/coLiquidations", icono: "dollar" }
        ],
      },
      {
        section: "Technical",
        texto: t("Reserves"),
        icono: "container",
        children: [{ texto: t("Claims"), link: "/claimReserves", icono: "warning" }],
      },

      {
        section: "Health",
        texto: t("Tasks"),
        icono: "pushpin",
        children: [
          { texto: t("Launcher"), link: "/launcher", icono: "rocket" },
          { texto: t("Activities"), link: "/tasks", icono: "pushpin" },
        ],
      },
      {
        section: "Health",
        texto: t("Policies"),
        icono: "heart",
        children: [
          { texto: t("New Policy"), link: "/lifePolicy/0?&section=health", icono: "safety" },
          { texto: t("Policies"), link: "/policies?&section=health", icono: "search" },
        ],
      },
      {
        section: "Health",
        texto: t("Claims"),
        icono: "medicine-box",
        children: [
          { texto: t("Health Claim"), link: "/healthclaim/0", icono: "warning" },
          { texto: t("Claim List"), link: "/claimList", icono: "folder-open" },
          { texto: t("Diagnosis"), link: "/diagnosis", icono: "monitor" },
          { texto: t("Procedures"), link: "/procedure", icono: "global" },
          
        ],
      },
      {
        section: "Health",
        texto: t("Tools"),
        icono: "tool",
        children: [
          { texto: t("Import"), link: "/import", icono: "upload" },
          { texto: t("TimeLapse"), link: "/timelapse", icono: "clock-circle" },
        ],
      },

      {
        section: "Contacts",
        texto: t("Contacts"),
        icono: "team",
        children: [
          { texto: t("Contact"), link: "/contact/0", icono: "user" },
          { texto: t("Contact List"), link: "/contactlist", icono: "team" },
          { texto: t("Communications"), link: "/communications", icono: "message" },
          { texto: t("Issue Tracker"), link: "/issueTracker", icono: "alert" },
        ],
      },
      {
        section: "Contacts",
        texto: t("Providers"),
        icono: "shop",
        children: [
          { texto: t("Procedures"), link: "/procedure", icono: "global" },
          { texto: t("Provider"), link: "/provider/0", icono: "medicine-box" },
          {
            texto: t("Provider Search"),
            link: "/providersearch",
            icono: "search",
          },
        ],
      },
    ];
    //extra menus defiend in config profile
    const extraMenus = safeGet(["configProfile", "ExtraMenus"], this.state, []);
    if (extraMenus.length > 0) {
      const extra = extraMenus.map((p) => ({ texto: p.name, icono: p.icon, section: p.section, children: p.children }));
      extra.forEach((p) => {
        if (p.children && p.children.length > 0)
          p.children = p.children.map((q) => ({ texto: q.name, icono: q.icon, link: q.url&&q.url.startsWith('/')?q.url:`/extra/${encodeURIComponent(q.url)}` }));
        allMenuItems.push(p);
      });
    }
    //hidden items
    const hiddenItems = safeGet(["configProfile", "MenuItems"], this.state, []).filter((p) => p.hidden);
    if (hiddenItems.length > 0) {
      allMenuItems= allMenuItems.filter(p=>!hiddenItems.map(q=>q.name).includes(p.texto));
      allMenuItems.forEach((p) => {
        if (p.children) p.children = p.children.filter((q) => !hiddenItems.map(r=>r.name).includes(q.texto));
      } );
    }
    

    //hidden sections
    const hiddenSections = safeGet(["configProfile", "Sections"], this.state, []);
    if (hiddenSections.length > 0) {
      //computing hidden sections that have a formula string instead of a boolean in hidden property
      const computedHiddenSections = hiddenSections.filter((p) => typeof p.hidden == "string");
      computedHiddenSections.forEach((p) => (p.hidden = this.computeFormula(p.hidden)));
      //replacing hidden sections with computed ones
      hiddenSections.splice(
        0,
        hiddenSections.length,
        ...hiddenSections.filter((p) => typeof p.hidden == "boolean"),
        ...computedHiddenSections.filter((p) => p.hidden)
      );
      //removing hidden sections
      menuHeaders = menuHeaders.filter(
        (p) =>
          !hiddenSections
            .filter((q) => q.hidden)
            .map((q) => q.name)
            .includes(p.id)
      );
      //adding extra sections having hidden=false and have a name and icon
      menuHeaders = menuHeaders.concat(hiddenSections.filter((q) => !q.hidden&&q.name&&q.icon).map((q) => ({ id: q.name, name: q.name, icon: q.icon, color: q.color })));
    }
    const menuNavegacion = allMenuItems.filter((p) => p.section == this.state.menuModule.id&&menuHeaders.map(q=>q.id).includes(p.section));

    if (this.state.cfg) {
      this.state.cfg.MENUS_OCULTOS instanceof Array && this.state.cfg.MENUS_OCULTOS.sort((a, b) => b - a).forEach((e) => menuNavegacion.splice(e, 1));
    }
    //used for mousetrap
    this.allMenuItems=allMenuItems;
    //preserving selected menu after refresh
    if(this.state.selectedKeysMenu1[0]!==this.state.menuModule.id) {
      const selectedMenuHeader=menuHeaders.find(p=>p.id===this.state.selectedKeysMenu1[0]);
      if(selectedMenuHeader) this.setState({menuModule:selectedMenuHeader}); 
    }
    const HisotoryMenu = (
      <Menu>
        {this.state.history &&
          this.state.history.map((p) => (
            <Menu.Item>
              <a href={"#" + p}>{p}</a>
            </Menu.Item>
          ))}
      </Menu>
    );
    const mobileMenu = (
      <Menu>
        {allMenuItems
          .filter((p) => p.children)
          .filter((item, index, array) => array.findIndex((t) => t.texto == item.texto) == index)
          .sort((a, b) => a.texto.localeCompare(b.texto))
          .map((p) => (
            <Menu.SubMenu
              title={
                <span>
                  <Icon type={p.icono} style={{ marginRight: this.state.direction=="ltr"?5:0,marginLeft: this.state.direction=="rtl"?5:0 }} />
                  {p.texto}
                </span>
              }>
              {p.children.map((c) => (
                <Menu.Item>
                  <a href={"#" + c.link.replace("?&section=property","")}>
                    <span>
                      <Icon type={c.icono} style={{ marginRight: this.state.direction=="ltr"?5:0,marginLeft: this.state.direction=="rtl"?5:0 }} />
                      {c.texto}
                    </span>
                  </a>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
      </Menu>
    );
    //menu lateral selccionado debe seguir a la url
    // for (let index = 0; index < menuNavegacion.length; index++) {
    //   if(this.props.location.pathname.indexOf(menuNavegacion[index].link)>-1)
    //   {
    //     if(this.state.selectedKeys[0]!=index.toString()) this.setState({selectedKeys:index.toString()});
    //   }
    // }
    const companyName =localStorage.organizationName? " | "+localStorage.organizationName:(localStorage.companyName ? localStorage.companyName : "");
    const systemName=localStorage.organizationName?"uInsurer":"SISos";
    if (this.state.unauthorized)
      return (
        <Result
          status="403"
          title="403"
          subTitle={t("Sorry, you are not authorized to access this page.")}
          extra={
            <Button type="primary" onClick={() => (window.location.hash = "#/home")}>
              {t("Back Home")}
            </Button>
          }
        />
      );
      const getOrgConfig=orgCode=>{
        const orgs=safeGet(["UInsurer","Organizations"],this.state.configProfile,[]);
        return orgs.find(p=>p.code===orgCode);
      }
      const getMenuColor=()=>{
        if(localStorage.organizationName){
          //uInsurer
          const org=getOrgConfig(localStorage.organizationCode); //checking if there is a custom menuColor for the organization
          return org?org.menuColor:"rgb(58, 182, 96)";
        }else{
          //SISos
          return "#0078d4";
        }
      }
      
      const doTreeData=(treeId)=>{
        //rendering tree with menuHeaders and allMenuItems
        let treeData = [];
        menuHeaders.forEach((p) => {
          if(!p.treeId) p.treeId=4; //custom sections go to tree 4
          if (p.treeId !== treeId) return;
          const children = allMenuItems.filter((q) => q.section == p.id);
          if (children.length > 0) {
            const childrenData = children.map((q) => {
              const subChildren = q.children
              //.filter(h=>this.state.treeSearch?h.texto.toLowerCase().includes(this.state.treeSearch):true)
                ? q.children.map((r) => {
                    return { title: r.texto, key: "q"+p.id+q.texto+r.texto, icon: <Icon type={r.icono} />, level:3,link:r.link };
                  })
                : [];
              return { title: q.texto, key: "m"+p.id+q.texto, icon:  <Icon type={q.icono} style={{color:"rgba(0,0,0,0.65)"}} />, children: subChildren, level:2 };
            });
            treeData.push({ title: p.name, key: "s"+p.id+p.name, icon: <Avatar shape="square" style={{backgroundColor:p.color}} icon={<Icon type={p.icon} />} size="small" />, children: childrenData,level:1,color:p.color });
          }
        });
        return treeData;

      }
      const treeTitleRender=(node)=>{
        switch(node.level){
          case 1: return <span style={{fontWeight:"bold",fontSize:12, color:this.state.treeSearch?node.title.toLowerCase().includes(this.state.treeSearch)?"red":"rgba(0,0,0,0.65)":"rgba(0,0,0,0.65)",marginLeft:5}}>{node.title}</span>;
          case 2: return <span style={{fontWeight:"bold",fontSize:12, color:this.state.treeSearch?node.title.toLowerCase().includes(this.state.treeSearch)?"red":"rgba(0,0,0,0.55)":"rgba(0,0,0,0.55)",marginLeft:5}}>{node.title}</span>;
          case 3: return <Link to={node.link} onClick={()=>this.setState({navigatorVisible:false})}><span style={{fontSize:12,color:this.state.treeSearch?node.title.toLowerCase().includes(this.state.treeSearch)?"red":"rgba(0,0,0,0.65)":"rgba(0,0,0,0.65)",marginLeft:5}}>{node.title}</span></Link>;
          default: return
        }
      }
      const onTreeSearch=(e,value)=>{
        this.setState({treeSearch:e.target.value?e.target.value.toLowerCase():""});
      }
      const onTreePressEnter=()=>{
        //selecting the first node that matches the search. Flattening allMenuItems
        const allMenuItemsFlat=allMenuItems.flatMap(p=>p.children?p.children:[]);
        const found=allMenuItemsFlat.filter(p=>p.texto.toLowerCase().includes(this.state.treeSearch));
        console.log(found,"onTreePressEnter");
        if(found.length>0){
          window.location.hash=found[0].link;
          this.setState({navigatorVisible:false});
        }

      }
    return (<ConfigProvider direction={this.state.direction} locale={this.state.antdLocale}>
      <div style={{overflow:"hidden", height:"100vh"}}>
        {this.state.warningMsg&&<Alert message={this.state.warningMsg} type="warning" showIcon closable />}
        <Layout style={{ height: "100vh" }}>
          {/*****************************#f0f2f5***************** HEADER */}
          <Header
            style={
              this.state.loginView
                ? { display: "none" }
                : {
                    background: getMenuColor(),
                    padding: 0,
                    height: 50,
                    lineHeight: "50px",
                    zIndex: 10,
                    boxShadow: "0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)",
                    display:"flex",
                    alignItems:"center"
                  }
            }>

            <Link to="/home" style={{whiteSpace:"nowrap",overflow:"hidden"}}>
              <Button shape="circle" size="large" style={{ width:this.state.collapsed?80:53, backgroundColor: "transparent", border: "none"}}>
                {!this.state.collapsed && <Icon type="appstore" theme="filled" style={{ color: "white", marginLeft: "auto", marginRight: "auto", fontSize: 18 }} />}
                {this.state.collapsed && <Icon type="appstore" theme="filled" style={{ color: "white", marginLeft: "auto", marginRight: "auto", fontSize: 20 }} />}
              </Button>
              {!this.state.collapsed && <span style={{ color: "white", fontSize: 20, marginRight: this.state.direction=="ltr"?20:0,marginLeft:this.state.direction=="rtl"?20:0 }}>{systemName + companyName}</span>}
              {this.state.collapsed && <span style={{ color: "white", fontSize: 20, marginRight: this.state.direction=="ltr"?20:0,marginLeft:this.state.direction=="rtl"?20:0 }}>{systemName + companyName}</span>}
              {/* <img src={require("./logo_blanco.png")} style={{ marginLeft: 10, width: 80, marginRight: 120 }} /> */}
            </Link>
            <Tooltip title={t("Notifications")}>
              <Button
                shape="circle"
                size="large"
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => this.setState({ notificationsVisible: !this.state.notificationsVisible })}>
                  <Badge count={this.state.notifications} offset={[2, 0]} style={{ borderStyle: "hidden",boxShadow:"none", minWidth:15,lineHeight:"15px",fontSize:9,height:15 }}>
                    <Icon type="bell" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
                </Badge>
              </Button>
            </Tooltip>
            <Tooltip title={t("My Tasks")}>
              <Button shape="circle" size="large" style={{ backgroundColor: "transparent", border: "none" }} onClick={() => (window.location = "/#/tasks")}>
                <Icon type="pushpin" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
              </Button>
            </Tooltip>
            <Dropdown overlay={HisotoryMenu}>
              <Tooltip title={t("Recent")} placement="right">
                <Button shape="circle" size="large" style={{ backgroundColor: "transparent", border: "none" }}>
                  <Icon type="folder" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
                </Button>
              </Tooltip>
            </Dropdown>
            <Tooltip title={t("Add bookmark")}>
              <Button shape="circle" size="large" style={{ backgroundColor: "transparent", border: "none" }} onClick={() => this.addBookmark()}>
                <Icon type="star" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
              </Button>
            </Tooltip>
            <Tooltip title={t("Server Status")}>
              <Button
                shape="circle"
                size="large"
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => this.setState({ statusPageVisible: !this.state.statusPageVisible })}>
                <Badge dot={this.state.disconnection} style={{borderStyle: "hidden",boxShadow:"none"}}>
                  <Icon type="database" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
                </Badge>
              </Button>
            </Tooltip>
            <Tooltip title={t("Command Launcher")}>
              <Button
                shape="circle"
                size="large"
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => this.setState({ searchVisible: true })}>
                <Icon type="code" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
              </Button>
            </Tooltip>
            <Tooltip title={t("Navigator")}>
              <Button
                shape="circle"
                size="large"
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => this.setState({ navigatorVisible: !this.state.navigatorVisible })}>
                <Icon type="compass" theme="filled" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
              </Button>
            </Tooltip>
            <Dropdown overlay={mobileMenu}>
              <Tooltip title={t("Menu")}>
                <Button shape="circle" size="large" style={{ backgroundColor: "transparent", border: "none" }}>
                  <Icon type="menu" style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
                </Button>
              </Tooltip>
            </Dropdown>
            <Ai />
            <div style={{display:"flex",flexGrow:1,flexDirection:"row-reverse"}}>
              <div style={{marginLeft:5,marginRight: 5,display:"inline-block" }}>
                <User userName={this.state.userName} menu={menuUser} />
              </div>
              <Tooltip title={t("Search")}>
                <div style={{marginLeft:5, marginRight: 5,display:"inline-block" }}>
                  <SearchSmall navigation={allMenuItems} open={this.state.searchBoxVisible} />
                </div>
              </Tooltip>
            </div>
          </Header>
          <Layout>
            {/********************************************** MENU LATERAL */}
            <Sider
              breakpoint="sm"
              style={this.state.loginView ? { display: "none" } : { overflow: "hidden", backgroundColor: "white" }}
              width={263}
              collapsedWidth={this.state.loginView || this.state.mobileView ? 0 : undefined}
              trigger={null}
              collapsible
              className={this.state.collapsed?"lateralMenuCollapsed":"lateralMenu"}
              collapsed={this.state.collapsed}>
              {/* MENU 1 */}
              <div
                style={
                  (!this.state.collapsed && {
                    height: "100vh",
                    backgroundColor: "rgb(243, 242, 241)",
                  }) ||
                  (this.state.collapsed && { })
                }>
                <Menu
                  theme="light"
                  style={{ width:53, textAlign: "center", backgroundColor: "rgb(243, 242, 241)" }}
                  defaultSelectedKeys={[localStorage.selectedKeyMenu1]}
                  selectedKeys={this.state.selectedKeysMenu1}
                  onSelect={(o) => {
                    this.setState({ selectedKeysMenu1: [o.key] });
                    localStorage.selectedKeyMenu1=o.key;
                  }}>
                  <Menu.Item key="start" onClick={() => this.setState({ startVisible: true })}>
                    <Icon style={{ fontSize: 20 }} type="star" />
                    {this.state.collapsed && <span>{t("Bookmarks")}</span>}
                  </Menu.Item>

                  {Object.keys(menuHeaders).map((p) => (
                    <Menu.Item key={menuHeaders[p].id} onClick={() => this.setState({ menuModule: { id: p, ...menuHeaders[p] }, collapsed: false })} title={menuHeaders[p].name}>
                      <Tooltip placement="right" title={menuHeaders[p].name} overlayStyle={{ visibility: this.state.collapsed ? "hidden" : "visible" }}>
                        <Icon style={{ fontSize: 20 }} type={menuHeaders[p].icon} />
                      </Tooltip>
                    </Menu.Item>
                  ))}

                  <Divider />
                </Menu>
              </div>
              {/* MENU 2 COLAPSADO------------------------------------------------------------------- */}
              {this.state.collapsed && (
                <Menu
                  theme="light"
                  style={{flexGrow:1, backgroundColor: "rgb(243, 242, 241)", height: "100vh" }}
                  mode="inline"
                  selectedKeys={this.state.selectedKeys}
                  onSelect={(o) => {
                    if (o.key !== "back") {
                      localStorage.selectedKey=o.key;
                      this.setState({selectedKeys: [o.key]});
                    }
                  }}>
                  <Menu.Item key="back" onClick={this.toggle} hidden={!menuNavegacion.length}>
                    <Icon style={{ fontSize: 16 }} type={this.state.direction=="ltr"?"right":"left"} />
                    <span className="nav-text">{t("Expand")}</span>
                  </Menu.Item>

                  {menuNavegacion.map((x, i) =>
                    x.children ? (
                      <SubMenu
                        key={i}
                        disabled={x.disabled}
                        title={
                          <span>
                            <Icon type={x.icono} />
                            <span>{x.texto}</span>
                          </span>
                        }>
                        {x.children.map((subitem, subindex) => (
                          <Menu.Item key={i * 100 + subindex}>
                            <Link to={subitem.link}>
                              <Icon type={subitem.icono} style={{ fontSize: 16 }} />
                              {subitem.texto}
                            </Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={i} disabled={x.disabled}>
                        <Link to={x.link}>
                          <Icon type={x.icono} style={{ fontSize: 16 }} />
                          <span className="nav-text">{x.texto}</span>
                        </Link>
                      </Menu.Item>
                    )
                  )}
                </Menu>
              )}
              {/* MENU 2 EXPANDIDO------------------------------------------------------------------ */}
              {!this.state.collapsed && (
                <Menu
                  theme="light"
                  mode="inline"
                  className="expandedLateralMenu"
                  style={{ width: 210, height: "100vh", overflowY: "auto", background: "#fafafa" }}
                  
                  openKeys={this.state.openKeys}
                  onOpenChange={(v) => {
                    this.setState({ openKeys: v });
                    localStorage.openKeys=JSON.stringify(v);
                  }}
                  selectedKeys={this.state.selectedKeys}
                  onSelect={(o) => {
                    if (o.key !== "back") {
                      localStorage.selectedKey=o.key;
                      this.setState({selectedKeys: [o.key]});
                    }
                  }}>
                  <div style={{ display: "flex",justifyContent:"space-between", marginTop: 10, marginBottom: 20 }}>
                    {/* <img src={require("./logo_gris.png")} style={{ marginLeft: 15, marginTop: 22, width: 80 }} /> */}

                    {/* <div style={{ marginLeft: 15, marginTop: 15, marginBottom: 20, fontWeight: "bold" }}>Insurance Core</div> */}
                    <div style={{display:"flex"}}>
                      <Avatar
                        shape="square"
                        size="large"
                        icon={<Icon type={this.state.menuModule.icon} />}
                        style={{ marginLeft:this.state.direction=="ltr"?15:0,marginRight:this.state.direction=="rtl"?15:0, marginTop: 5, backgroundColor: this.state.menuModule.color }}
                      />
                      <div style={{ marginLeft: this.state.direction=="ltr"?8:0,marginRight:this.state.direction=="rtl"?8:0, marginTop: 25, fontWeight: "bold", fontSize: 15 }}>{this.state.menuModule.name}</div>
                    </div>
                    <Button
                      type="link"
                      key="back"
                      onClick={this.toggle}
                      icon={this.state.direction=="ltr"?<LeftOutlined />:<RightOutlined />}
                      className="collapseButton"
                    />
                  </div>
                  {menuNavegacion.map((x, i) =>
                    x.children ? (
                      <SubMenu
                        key={i}
                        disabled={x.disabled}
                        title={
                          <span>
                            <Icon type={x.icono} />
                            <span style={{ fontWeight: 600 }}>{x.texto}</span>
                          </span>
                        }>
                        {x.children.map((subitem, subindex) => (
                          <Menu.Item key={(i + 1) * 100 + subindex}>
                            <Link to={subitem.link}>
                              <Icon type={subitem.icono} style={{ fontSize: 16 }} />
                              {subitem.texto}
                            </Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={i} disabled={x.disabled}>
                        <Link to={x.link}>
                          <Icon type={x.icono} style={{ fontSize: 16 }} />
                          <span className="nav-text">{x.texto}</span>
                        </Link>
                      </Menu.Item>
                    )
                  )}
                  <span style={{ position: "absolute", bottom: 15, marginLeft: this.state.direction=="ltr"?5:0,marginRight: this.state.direction=="rtl"?5:0, fontSize: 12 }}>© {new Date().getFullYear()} Axxis Systems</span>
                </Menu>
              )}
            </Sider>
            <Layout>
              <Content
                style={{
                  padding: "12px 24px 0px 24px",
                  background: "#fff",
                  minHeight: 280,
                  overflow: "auto",
                }}>
                {/********************************************** CONTENT */}

                <Route exact path="/profile"  render={(props)=><MisDatos {...props} configProfile={this.state.configProfile}  />} />

                <Route exact path="/fb/:formId?" component={FormBuilder} />
                <Route exact path="/contact/:contactId/:tab?" render={(props) => <Contact {...props} configProfile={this.state.configProfile} checkTabPermissions={this.checkTabPermissions}  />} />
                <Route exact path="/contract/:contractId"  render={(props) => <Contract {...props} configProfile={this.state.configProfile} />} />
                <Route exact path="/cessions" component={Cessions} />
                <Route exact path="/healthclaim/:claimId/:tab?" render={(props) => <HealthClaim {...props} configProfile={this.state.configProfile} checkTabPermissions={this.checkTabPermissions}   />} />
                <Route exact path="/contractList" component={ContractList} />
                <Route exact path="/contactList" component={ContactList} />
                <Route exact path="/claimList" component={ClaimList} />
                <Route exact path="/diagnosis" component={Diagnosis} />
                <Route exact path="/procedure" component={Procedure} />
                <Route exact path="/procedure/:procedureCode" component={Procedure} />
                <Route exact path="/provider/:providerId" component={Provider} />
                <Route exact path="/providersearch" component={ProviderSearch} />
                <Route exact path="/proc" component={Procesos} />
                <Route exact path="/tasks" component={Actividades} />
                <Route exact path="/team" component={Equipo} />
                <Route exact path="/rules" component={Rules} />
                <Route exact path="/ruletable" component={RuleTable} />
                <Route exact path="/objectdefinition" component={ObjectDefinition} />

                <Route exact path={["/home","/"]} render={(props) => <Home {...props} recent={this.state.history} configProfile={this.state.configProfile} localeLoaded={this.state.localeLoaded} direction={this.state.direction} />} />
                <Route exact path="/launcher" component={Launcher} />
                <Route exact path="/activity/:actividadId" component={Actividad} />
                <Route exact path="/inspector" component={Inspector} />

                <Route exact path="/lifePolicy/:policyId/:tab?" render={(props) => <LifePolicy {...props} configProfile={this.state.configProfile} direction={this.state.direction} checkTabPermissions={this.checkTabPermissions} />} />
                <Route exact path="/policies/" component={LifePolicyList} />
                <Route exact path="/Products" component={Products} />
                <Route exact path="/Products/:productCode" component={Product} />
                <Route exact path="/InvestmentPlans" component={InvestmentPlans} />
                <Route exact path="/InvestmentPlans/:investmentPlanCode" component={InvestmentPlan} />
                <Route exact path="/FinancialProducts" component={InvestmentPlans} />
                <Route exact path="/Transfers" component={Transfers} />
                <Route exact path="/collection" component={Collection} />
                <Route exact path="/Supervisor" component={Supervisor} />
                <Route exact path="/Accounts" component={Accounts} />
                <Route exact path="/Account/:accountId" component={Account} />

                <Route exact path="/Annuity/:annuityId" component={Annuity} />
                <Route exact path="/Annuities" component={AnnuityList} />
                <Route exact path="/annuityProducts" component={AnnuityProducts} />

                <Route exact path="/claimReserves" component={ClaimReserves} />

                <Route exact path="/comContract/:comContractId" component={ComContract} />
                <Route exact path="/commissions" component={Commissions} />
                <Route exact path="/comContracts" component={ComContractList} />
                <Route exact path="/cumulus" component={Cumulus} />
                <Route exact path="/locationCumulus" component={LocationCumulus} />
                <Route exact path="/customCumulus" component={CustomCumulus} />

                <Route exact path="/production" component={Production} />

                <Route exact path="/transactionLog" component={TransactionLog} />
                <Route exact path="/allocation" component={Allocation} />
                <Route exact path="/accountsim" component={AccountSimulator} />
                <Route exact path="/othermovs/:movId?" component={OtherMovements} />
                <Route exact path="/log" component={LogExplorer} />
                <Route exact path="/auditlog" component={AuditLogExplorer} />
                <Route exact path="/templates" component={Templates} />
                <Route exact path="/portal" component={Portal} />
                <Route exact path="/language" component={Language} />
                <Route exact path="/filemanager" component={FileManager} />
                <Route exact path="/taxschemelist" component={TaxSchemeList} />
                <Route exact path="/taxscheme/:taxSchemeId" component={TaxScheme} />
                <Route exact path="/taxgenerated" component={TaxGenerated} />
                <Route exact path="/installmentschemelist" component={InstallmentSchemeList} />
                <Route exact path="/installmentscheme/:installmentSchemeId" component={InstallmentScheme} />
                <Route exact path="/movements" component={Movements} />
                <Route exact path="/triggers" component={Triggers} />
                <Route exact path="/communications" component={Communications} />
                <Route exact path="/issueTracker" component={IssueTracker} />
                <Route exact path="/paymentMonitor" component={PaymentMonitor} />
                <Route exact path="/htmltemplates" component={HtmlTemplates} />
                <Route exact path="/payments/:paymentId?" component={Payments} />
                <Route exact path="/accounting/coa" component={ChartOfAccounts} />
                <Route exact path="/accounting/tx" render={(props) => <Transactions {...props} defaultCurrency={this.state.defaultCurrency} />} />
                <Route exact path="/accounting/movements" render={props=><AccountingMovements {...props} defaultCurrency={this.state.defaultCurrency} />} />
                <Route exact path="/import/:importId?" component={RecordImport} />
                <Route exact path="/configurator/:productCode" component={ProductConfigurator} />
                <Route exact path="/chains/:chainId?" component={CommandChains} />
                <Route exact path="/eventbus" component={Dashboard} />
                <Route exact path="/billingSummary" component={BillingSummary} />
                <Route exact path="/reportlist" component={ReportList} />
                <Route exact path="/reportview/:report/:queryString?" component={ReportView} />
                <Route
                  exact
                  path="/reportDesigner"
                  render={(props) => (
                    <iframe
                      frameBorder="0"
                      style={{ width: "100%", height: "100%", marginLeft: "-24px", marginTop: "-12px" }}
                      src={config.reportsUrl + "/Home/Designer29ZQJsnwb8BsVR9a?token=" + localStorage.token}
                    />
                  )}
                />
                <Route exact path="/calcsheet/:policyId?" component={CalcSheet} />
                <Route
                  exact
                  path="/login"
                  render={(props) => {
                    return <Login {...props} login={this.setLoginView} onLogin={this.onLogin} />;
                  }}
                />
                <Route path="/config" render={(props) => <Configuracion {...props} allMenuItems={allMenuItems} configProfile={this.state.configProfile} />} />
                <Route exact path="/timelapse" component={TimeLapse} />
                <Route exact path="/accounting/templates" component={TransactionTemplates} />
                <Route exact path="/liquidations" component={ComAllLiquidations} />
                <Route exact path="/reEvents" component={ReEvents} />
                <Route exact path="/coCessions" component={CoCessions} />
                <Route exact path="/coLiquidations" component={CoLiquidations} />
                <Route exact path="/reLiquidations" component={ReLiquidations} />
                <Route exact path="/extra/:link" component={Extra} />
                <Route exact path="/goalDefinitionList" component={GoalDefinitionList} />
                <Route exact path="/goalDefinition/:goalDefinitionId" component={GoalDefinition} />
                <Route exact path="/goalStatus" component={GoalStatus} />
                <Route exact path="/portfolios" component={Portfolios} />
                <Route exact path="/portfolio/:portfolioId" component={Portfolio} />
                <Route exact path="/measurements" component={Measurements} />
                <Route exact path="/measurement/:measurementId" component={Measurement} />
                <Route exact path="/presentations" component={Presentations} />
                <Route exact path="/presentation/:presentationId" component={Presentation} />
                <Route exact path="/cashier/:workspaceId?/:tab?" render={props=><Workspaces {...props} checkTabPermissions={this.checkTabPermissions} />} />
                <Route exact path="/accounting/closure" component={Closure} />
                <Route exact path="/credit" component={Credit} />
                <Route exact path="/credit/:creditId" component={Credit} />
                <Route exact path="/creditList" component={CreditList} />
                <Route exact path="/debtstatus" component={DebtStatus} />
                <Route exact path="/view/:id?" component={View} />
                <Route exact path="/views/:viewId?" component={LiveViews} />
                <Route exact path="/fiscaldocs" component={FiscalDocs} />
                <Route exact path="/fiscaldocsgenerated" component={FiscalDocsGenerated } />
                <Route exact path="/openfile" component={OpenFile } />
                <Route exact path="/comTreeList" component={ComTreeList } />
                <Route exact path="/comTree/:comTreeId" component={ComTree} />
                <Route exact path="/calculationSchemes/:schemeId?" component={CalculationSchemes} />
                <Route exact path="/profitCommission" component={ProfitCommissionList} />
                <Route exact path="/profitCommission/:profitCommissionId" component={ProfitCommission} />
                <Route exact path="/coContract/:contractId"  render={(props) => <CoContract {...props} configProfile={this.state.configProfile} />} />
                <Route exact path="/coContractList" component={CoContractList} />
                <Route exact path="/pensionScheme/:pensionId?" component={PensionScheme} />
                <Route exact path="/pensionList" component={PensionList} />
                <Route exact path="/contributions" component={Contributions} />
                <Route exact path="/pensionProducts" component={PensionProducts} />
                <Route exact path="/funds" component={FundList} />
                <Route exact path="/fund/:fundId" component={Fund} />
                <Route exact path="/portfolioTransfers" component={PortfolioTransfers} />
                <Route exact path="/bulkTransactions" component={BulkTransactions} />
                <Route exact path="/gantt/:projectId?" component={Gantt} />
              </Content>
            </Layout>
          </Layout>
        </Layout>
        <Sisos visible={this.state.searchVisible} onClose={() => this.setState({ searchVisible: false })} />
        <RecentMenu history={this.state.historyVisited} visible={this.state.startVisible} onClose={() => this.setState({ startVisible: false })} menuItems={allMenuItems||[]} />
        <Notifications visible={this.state.notificationsVisible} onClose={() => this.setState({ notificationsVisible: false,notifications:0 })} notifications={this.notifications} onNewMessage={(q)=>this.setState({notifications:q})} onNewLeader={()=>this.loadNotifications()} leaderLoaded={this.state.doReloadNotifications} />
        <StatusPage visible={this.state.statusPageVisible} onClose={() => this.setState({ statusPageVisible: false })} disconnection={this.state.disconnection} />
        <PasswordChange visible={this.state.passwordChangeVisible} onOk={() => this.setState({ passwordChangeVisible: false })} configProfile={this.state.configProfile} />
        <Modal
          width="90%"
          title={null}
          footer={null}
          closable={false}
          centered
          visible={this.state.navigatorVisible}
          onCancel={() => this.setState({ navigatorVisible: false })}>
          <div style={{ display: "flex",alignItems:"center", marginBottom:10}}>
            {/* <Icon type="compass" theme="twoTone" style={{ fontSize: "30px",marginLeft:5,marginRight:5 }} /> */}
            {/* <SearchSmall navigation={allMenuItems} open={this.state.navigatorVisible} onSelect={() => this.setState({ navigatorVisible: false })} /> */}
            <Input.Search placeholder={t("Search")} onChange={onTreeSearch} onPressEnter={onTreePressEnter} onSearch={onTreePressEnter}  />
          </div>
          <Space align="start">
            <Tree
              defaultExpandAll
              showIcon
              selectable={false}
              titleRender={treeTitleRender}
              switcherIcon={<DownOutlined />}
              treeData={doTreeData(1)}
              />
              <Tree
              defaultExpandAll
              showIcon
              selectable={false}
              titleRender={treeTitleRender}
              switcherIcon={<DownOutlined />}
              treeData={doTreeData(2)}
              />
               <Tree
              defaultExpandAll
              showIcon
              selectable={false}
              titleRender={treeTitleRender}
              switcherIcon={<DownOutlined />}
              treeData={doTreeData(3)}
              />
               <Tree
              defaultExpandAll
              showIcon
              selectable={false}
              titleRender={treeTitleRender}
              switcherIcon={<DownOutlined />}
              treeData={doTreeData(4)}
              />
                <Tree
              defaultExpandAll
              showIcon
              selectable={false}
              titleRender={treeTitleRender}
              switcherIcon={<DownOutlined />}
              treeData={doTreeData(5)}
              />

          </Space>
        </Modal>
        <License visible={this.state.licenseVisible} onClose={() => this.setState({ licenseVisible: false })} configProfile={this.state.configProfile} />
        <ProgressWindow />
      </div>
      </ConfigProvider>);
  }
}

export default withRouter(withTranslation()(App));
