import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CheckOutlined, DollarOutlined, DownloadOutlined, EditOutlined, ReloadOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, DatePicker, message, Tag } from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import { formatMoney, formatDateShort, formatDate, getColor } from "../../Lib/Helpers";
import Text from "antd/lib/typography/Text";
import Status from "../Shared/Status";
import { useTranslation } from "react-i18next";
import ManualPaymentRequest from "./ManualPaymentRequest";
import getCache from "../../Lib/Cache";

const PaymentData = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editRecord, setEditRecord] = useState();
  const [pagination, setPagination] = useState({ pageSize: 15, total: -1, showTotal: (total) => `Total ${total} items` });
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(()=>{
    exe("RepoPaymentMethodCatalog",{operation:"GET"}).then(r=>{
      if(r.ok){
        setPaymentMethods(r.outData);
      }
    })
  },[])
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setLoading(false)
    }
  }, [props.data]);
  
  useEffect(() => {
    props.onPageChange(pagination.current);
  }, [pagination.current]);

  useEffect(() => {
    if (props.total) setPagination({ ...pagination, total: props.total });
  }, [props.total]);

  const onSelectChange = (keys,rows) => {
    setSelectedRowKeys(keys);
    if (props.onSelectChange) props.onSelectChange(rows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true
  };
  const reload = () => {
    setLoading(true)
    props.reload();
    setSelectedRowKeys([]);
  };
  const execute = (id) => {
    setLoading(true);
    exe("ExeClaimPayment", { claimPaymentId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        reload();
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const undoMerge=id=>{
    setLoading(true);
    exe("UndoMerge", { paymentId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        reload();
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  }
  const getStatus = (r) => {
    switch (r.entityState) {
      case "CANCELLED":
        return <Tag color="red">{t("Cancelled")}</Tag>;
      case "REVERTED":
        return <Tag color="red">{t("Reverted")}</Tag>;
      case "MERGED":
        return <Tag color="orange">{t("Merged to ")+r.parentId}</Tag>;
      case "EXECUTED":
        return <Tag color="green">{t("Executed")}</Tag>;

      default:
        return <Tag>{r.entityState}</Tag>;
    }
  };
  const getAction = (r) => {
    if(r.entityState==="MERGED")  return (
      <Button icon={<UndoOutlined />} onClick={() => undoMerge(r.id)}>
        {t("Undo Merge")}
      </Button>
    );
    if(r.entityState==="CANCELLED") return null;
    if(!r.Process&&["APROVED","APPROVED","OK"].includes(r.entityState)){
      // no workflow defined
      return (
        <Button icon={<CheckOutlined />} onClick={() => execute(r.id)}>
          {t("Execute")}
        </Button>
      );
    }
    switch (r.Process&&r.Process.entityState) {
      case "APROVED":
      case "APPROVED":
      case "OK":
        return (
          <Button icon={<CheckOutlined />} onClick={() => execute(r.id)}>
            {t("Execute")}
          </Button>
        );
      case "REJECTED":
        return <Tag color="red">{t("Rejected")}</Tag>;
      case "EXECUTED":
        return <Tag color="green">{t("Executed")}</Tag>;
      default:
        return null;
    }
  };
  const getOrigin = (r) => {
    let url = "";
    let origin = "";
    let originId = 0;
    let icon = "";
    if (r.claimId) {
      origin = t("Claim");
      originId = r.claimId;
      icon = "warning";
      url = "#/healthclaim/" + originId;
    } else if (r.lifePolicyId) {
      origin = t("Policy");
      originId = r.lifePolicyId;
      icon = "safety";
      url = "#/lifepolicy/" + originId;
    } else if (r.liquidationId && r.producer == "COM") {
      origin = t("Commission liquidation");
      originId = r.liquidationId;
      icon = "shopping";
      url = "#/liquidations?id=" + originId;
    } else if (r.liquidationId && r.producer == "TAX") {
      origin = t("Tax liquidation");
      originId = r.liquidationId;
      icon = "transaction";
      url = "#/taxscheme/1?id=" + originId;
    } else if (r.liquidationId && r.producer == "RE") {
      origin = t("Reinsurance liquidation");
      originId = r.liquidationId;
      icon = "calculator";
      const refInfo = r.reference.split("-"); //RELIQ-{liquidation.contractId}-{liquidation.id}-PART-{participant.contactId}"
      url = refInfo.length > 0 ? `#/contract/${refInfo[1]}` : "#/contractlist";
    } else if (r.liquidationId && r.producer == "REPROFIT") {
      origin = t("Reinsurance profit commission liquidation");
      originId = r.liquidationId;
      icon = "carry-out";
      const refInfo = r.reference.split("-"); //PROFITLIQ-{liquidation.profitCommissionId}-{liquidation.id}-PART-{participant.contactId}"
      url = refInfo.length > 0 ? `#/profitCommission/${refInfo[1]}` : "#/profitCommission";
    }
    else if (r.liquidationId && r.producer == "COI") {
      origin = t("Coinsurance liquidation");
      originId = r.liquidationId;
      icon = "pie-chart";
      url = "#/coLiquidations";
    }
    else if (r.producer === "MERGED") {
      origin = t("Merged Payments");
      icon = "block";
      originId = "";
      url = "#";
    }else if(r.producer==="ANNUITIES"){
        origin = t("Annuity");
        icon = "pull-request";
        originId = r.reference.split("-")[1];
        url = "#/annuity/"+originId;
    }else if(r.producer==="REINST"){
        origin = t("Reinstatement");
        icon = "retweet";
        originId = r.reference.split("-")[2];
        url = "#/contract/"+originId;
    }else if(r.producer==="EXNI"){
        origin = t("Not Insurance Expense");
        icon = "bulb";
        originId = r.reference.split("-")[2];
        url = "#/othermovs/"+originId;
    }

    else {
      //manual
      origin = t("Manual Request");
      icon = "user";
      originId = "";
      url = "#";
    }

    return (
      <Tooltip title={origin}>
        <LegacyIcon type={icon} style={{ marginRight: 3 }} />
        <a href={url}>{originId}</a>
      </Tooltip>
    );
  };
  const onEditRecord = (record) => {
    setEditRecord(record);
  };
  const saveRecordChanges = (record) => {
    setLoading(true);
    exe("RepoClaimPayment", { operation: "UPDATE", entity: record }).then((r) => {
      setLoading(false);
      setEditRecord(undefined);
      reload();
    });
  };
  const renderPaymentMethod=code=>{
    const pm=paymentMethods.find(p=>p.code===code);
    if(pm) return <Tag>{t(pm.name)}</Tag>;else return null;
  }
  const endStates=["MERGED","CANCELLED","REVERTED","EXECUTED"];
  return (
    <div>
      <Button type="link" icon={<ReloadOutlined />} onClick={reload}>
        {t("Reload")}
      </Button>
      <CSVLink data={data} filename={"payments.csv"} target="_blank" disabled={data.length == 0}>
        <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
          {t("Download")}
        </Button>
      </CSVLink>
      <Table
        dataSource={data}
        loading={loading||pagination.total===-1}
        style={{ marginTop: 5 }}
        scroll={{ x: true }}
        size="middle"
        rowSelection={props.selectable ? rowSelection : {}}
        pagination={(props.selectable&&!props.pagination) ? false : pagination}
        onChange={(pag) => {setPagination(pag);setLoading(true)}}
        rowKey="id">
        <Table.Column title={t("Actions")} key="actions" render={(v, r) => <Button icon={<EditOutlined />} type="link" onClick={() => onEditRecord(r)} />} />
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Beneficiary")} dataIndex="contactId" render={(v, r) => <a href={"#/contact/" + v}>{r.beneficiaryName}</a>} />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Origin")} key="origin" render={(v, r) => getOrigin(r)} />
        <Table.Column title={t("Ref")} dataIndex="reference" />
        <Table.Column title={t("Method")} dataIndex="paymentMethodCode" render={v=>renderPaymentMethod(v)} />
        <Table.Column title={t("Concept")} dataIndex="concept" />
        <Table.Column title={t("Branch")} dataIndex="branch" />
        <Table.Column title={t("Check Num")} dataIndex="checkNum" />
        <Table.Column title={t("Source Acc")} dataIndex="sourceAccountId" render={(v, r) => <a href={"#/account/" + v}>{v}</a>} />
        <Table.Column title={t("Destination Acc")} dataIndex="accountId" render={(v, r) => <a href={"#/account/" + v}>{v}</a>} />
        <Table.Column
          title={t("Due Date")}
          key="paymentDue"
          render={(v, r) =>
            r.Process && r.Process.dueDate ? <span style={{color: r.late ? "red" : "rgba(0, 0, 0, 0.65)" }}>{formatDate(r.Process.dueDate)}</span> : null
          }
        />
        <Table.Column
          title={t("Release Date")}
          dataIndex="releaseDate"
          render={(v, r) =>v?formatDate(v): null}
          
        />
         <Table.Column title={t("Batch")} dataIndex="batchId" />
        <Table.Column
          title={t("Exchange")}
          key="exchange"
          render={(v, r) =>
            r.entityState == "EXECUTED" ? (
              <a href={"#/account/" + r.accountId + "?&tab=operations"}>
                <DollarOutlined />
              </a>
            ) : null
          }
        />
        <Table.Column title={t("Action")} key="action" render={(v, r) => getAction(r)} />

        <Table.Column
          dataIndex="Process"
          fixed="right"
          title={t("Workflow")}
          render={(v,r) => endStates.includes(r.entityState)?null:<Status process={v} color={getColor(v && v.entityState)} reload={() => reload()} />}
        />
        <Table.Column title={t("Status")} dataIndex="entityState" fixed="right" render={(v, r) => getStatus(r)} />
        <Table.Column title={t("Amount")} dataIndex="total" fixed="right" render={(v, r) => formatMoney(v, r.currency)} />
      </Table>
      <ManualPaymentRequest
        visible={editRecord}
        onCancel={() => setEditRecord(undefined)}
        onOk={(r) => saveRecordChanges(r)}
        record={editRecord}
        readOnly={editRecord && editRecord.entityState == "EXECUTED"}
      />
    </div>
  );
};

export default PaymentData;
