import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import Log from "./Log";
import { DownloadOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Space } from "antd";
import AuditLog from "./AuditLog";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const AuditLogExplorer = () => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const [refresh, doRefresh] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);

  const download = () => {
    setLoading(true);
    exe("DownloadAuditLog", { timeout:1200 }).then(r => {
      setLoading(false);
      if (r.ok) {
        downloadFile(r.outData.auditLog, "auditlog.csv");
      }
    });
  };
  //convets string to cvs file to be downloaded by the user
  const downloadFile = (data, filename) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);

  }


  return (
    <div>
      <DefaultPage
        title={t("Audit Log")}
        icon="history"
        extra={
          <Space>
            <Button icon={<DownloadOutlined />} onClick={download} loading={loading}>{t("Download")}</Button>
            <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
          </Space>
        }>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => doRefresh(refresh + 1)}>
          {t("Refresh")}
        </Button>
        <AuditLog refresh={refresh} filterVisible={filterVisible} onCloseFilter={() => setFilterVisible(false)} />
      </DefaultPage>
    </div>
  );
};

export default AuditLogExplorer;
