import React, {useEffect, useState} from 'react';
import {SettingOutlined} from "@ant-design/icons";
import {Table, Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {exe, safeGet} from "../../Lib/Dal";
import CustomForm from '../Shared/CustomForm';

const CommonChangeAdditional = (props) => {
    const [t]=useTranslation();
    const [additionalVisibile,setAdditionalVisible]=useState(false);
    const [formId,setFormId]=useState(0);

    useEffect(()=>{
        if(props.config){
            const changeAdditionalSettingsFormId=safeGet(["Main","changeAdditionalSettingsFormId"],props.config,0);
            setFormId(changeAdditionalSettingsFormId);
        }
    },[props.config]);
    
    const onOk=()=>{
        //validating form
        if (window.changeAdditionalSettingsForm &&window.changeAdditionalSettingsForm.instanceContainers&& window.changeAdditionalSettingsForm.instanceContainers[0]) {
            if (window.changeAdditionalSettingsForm.instanceContainers[0].reportValidity()) {
                const jAdditional = JSON.stringify(window.changeAdditionalSettingsForm.userData);
                props.onChange(jAdditional);
                setAdditionalVisible(false);
            } else {
                message.error(t("Please check custom form validation errors"));
                return;
            }
        }
    }
    
    return (
        <div style={{display:formId>0?"block":"none"}}>
            <Button style={{paddingLeft:0}} type={"link"} icon={<SettingOutlined />} 
            onClick={()=>setAdditionalVisible(true)}>{t("Additional Settings")}</Button>  
                <Modal title={t("Additional Change Settings")} visible={additionalVisibile} onOk={onOk} onCancel={()=>setAdditionalVisible(false)} destroyOnClose >
                   <div>
                        <CustomForm formId={formId} variable="changeAdditionalSettingsForm" value={props.value} />
                    </div>
                </Modal>
        </div>
    );
};

export default CommonChangeAdditional;