import {
    CalendarOutlined,
    CameraOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlaySquareOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Badge,
    Button,
    Divider,
    Dropdown,
    message,
    Modal,
    Popconfirm,
    Table,
    Tag,
    Typography,
} from "antd";
import React, {useEffect, useState} from "react";
import {exe} from "../../Lib/Dal";
import {formatDate, formatDateShort, formatDateShortUtc, formatMoney, getColor} from "../../Lib/Helpers";
import Status from "../Shared/Status";
import moment from "moment";
import PolicySnapshot from "./PolicySnapshot";
import {useTranslation} from "react-i18next";

const Anniversaries = (props) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [snapshot, setSnapshot] = useState(null);

    useEffect(() => {
        if (props.policyId) load(props.policyId);
    }, [props.policyId, props.value]);


    const currency = props.currency || "USD";

    const load = (policyId) => {
        setLoading(true);
        exe("RepoAnniversary", {
            operation: "GET",
            filter: `lifePolicyId=${policyId}`,
            include: ["Process"]
        }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    const start = (anniversaryId) => {
        setLoading(true);
        exe("StartAnniversary", {anniversaryId}).then((r) => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                setData([...data.filter((p) => p.id !== anniversaryId), r.outData]);
            } else {
                message.error(r.msg);
            }
        });
    };
    const remove = (record) => {
        setLoading(true);
        exe("RepoAnniversary", {operation: "DELETE", entity: record}).then((r) => {
            setLoading(false);
            if (r.ok) {
                props.reload();
            } else {
                message.error(r.msg);
            }
        });
    };
    const parseStatusCode = (statusCode) => {
        switch (statusCode) {
            case 0:
                return <Tag color="blue">{t("Proposal")}</Tag>;
            case 1:
                return <Tag color="green">{t("Executed")}</Tag>;
            case 2:
                return (
                    <Tag color="orange">
                        <ClockCircleOutlined style={{marginRight: 5}} />
                        {t("Scheduled")}
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="green">
                        <CalendarOutlined style={{marginRight: 5}} />
                        {t("Executed")}
                    </Tag>
                );
            case 4:
                return <Tag color="red">{t("Execution failed")}</Tag>;
            case 5:
                return (
                    <Tag>
                        <DeleteOutlined style={{marginRight: 5}} />
                        {t("Aborted")}
                    </Tag>
                );
            case 6:
                return (
                    <Tag color="geekblue">
                        <EditOutlined style={{marginRight: 5}} />
                        {t("Amended")}
                    </Tag>
                );
            default:
                return statusCode;
        }
    };

    const expandedRowRender = (r, index) => {
        // const lowerLimit = moment(r.anniversary).add(-1 * (index + 1), "years");
        // const anniversary = moment(r.anniversary);
        // const changes = (props.changes || []).filter(
        //   (p) => moment(p.effectiveDate).isSameOrBefore(anniversary, "day") && moment(p.effectiveDate).isSameOrAfter(lowerLimit, "day")
        // );
        const changes = (props.changes || []).filter((p) => p.anniversary == r.contractYear);

        return (
            <Table dataSource={changes} pagination={false}>
                <Table.Column
                    title={t("Change Id")}
                    dataIndex="id"
                    render={(v) => (window.location.hash.indexOf("/tab12?" + v) > -1 ?
                        <Typography.Text mark>{v}</Typography.Text> : v)}
                />
                <Table.Column title={t("Status")} dataIndex="status" render={(v) => parseStatusCode(v)}/>

                <Table.Column title={t("Effect Date")} dataIndex="effectiveDate" render={(v) => formatDateShort(v)}/>
                <Table.Column title={t("Code")} dataIndex="code"/>
                <Table.Column title={t("Type")} dataIndex="Discriminator"/>
            </Table>
        );
    };
    const reload = () => load(props.policyId);
    const executeAnniversary = (id) => {
        setLoading(true);
        exe("ExeAnniversary", {anniversaryId: id}).then((r) => {
            setLoading(false);
            if (r.ok) {
                props.reload();
                message.success(r.msg);
            } else {
                message.error(r.msg);
            }
        });
    };
    const doPreview = (r) => {
        setLoading(true);
        exe("GetAnniversaryPreview", {policyId: r.lifePolicyId, contractYear: r.contractYear}).then((r) => {
            if (r.ok) {
                setLoading(false);
                console.log(r.outData);
                setSnapshot(JSON.stringify(r.outData.PolicyImage));
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Table dataSource={data} rowKey="id" loading={loading} expandedRowRender={expandedRowRender}>
                <Table.Column title={props.shortTerm?t("Period"):t("Year")} dataIndex="contractYear"/>
                <Table.Column title={t("From")} dataIndex="start"
                              render={(v) => <span style={{whiteSpace: "nowrap"}}>{formatDateShortUtc(v)}</span>}/>
                <Table.Column title={t("Anniversary")} dataIndex="anniversary"
                              render={(v) => <span style={{whiteSpace: "nowrap"}}>{formatDateShortUtc(v)}</span>}/>
                <Table.Column title={t("Insured Sum")} dataIndex="insuredSum" render={v => formatMoney(v, currency)}/>
                <Table.Column title={t("Premium")} dataIndex="premium" render={v => formatMoney(v, currency)}/>
                <Table.Column title={t("Periodicity")} dataIndex="periodicity"/>
                <Table.Column title={t("Duration")} dataIndex="duration"/>

                <Table.Column
                    dataIndex="Process"
                    title={t("Workflow")}
                    render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => reload()}/>}
                />
                <Table.Column
                    title={t("Actions")}
                    render={(v, r, index) => (
                        <div className={props.snapshot?"disabled":undefined}>
                            <Button type="link" icon={<PlaySquareOutlined />} onClick={() => start(r.id)}
                                    disabled={r.processId !== null}>
                                {t("Start Now")}
                            </Button>
                            <Divider type="vertical"/>
                            <Popconfirm
                                title={t("Are you sure you want to execute this anniversary procedure?")}
                                onConfirm={() => executeAnniversary(r.id)}
                                disabled={!r.processId || r.executionDate}>
                                <Button type="link" icon={<CheckOutlined />} disabled={!r.processId || r.executionDate}>
                                    {t("Execute Now")}
                                </Button>
                            </Popconfirm>
                            <Divider type="vertical"/>
                            <Button type="link" icon={<CameraOutlined />} onClick={() => setSnapshot(r.jSnapshot)}>
                                {t("Snapshot")}
                            </Button>
                            <Divider type="vertical"/>
                            <Button type="link" icon={<VideoCameraOutlined />} onClick={() => doPreview(r)}>
                                {t("Preview")}
                            </Button>
                            <div style={{display: index > 0 ? "block" : "none"}}>
                                <Popconfirm title="Are you sure you want to remove this anniversary procedure?"
                                            onConfirm={() => remove(r)} disabled={r.executionDate}>
                                    <Button type="link" icon={<DeleteOutlined />} disabled={r.executionDate}>
                                        {t("Remove")}
                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>
                    )}
                />
            </Table>
            <Modal title={t("Anniversary Snapshot")} visible={snapshot !== null} onOk={() => setSnapshot(null)}
                   onCancel={() => setSnapshot(null)} width="900px">
                <PolicySnapshot value={snapshot} parsedValues config={props.config} product={props.product} checkTabPermissions={props.checkTabPermissions}  />
            </Modal>
        </div>
    );
};

export default Anniversaries;
