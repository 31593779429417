import React, { Component } from "react";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import { SaveOutlined } from '@ant-design/icons';
import { Menu, Modal, Select, message, Input, Button } from "antd";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

class CatalogEditor extends Component {
  catalogs = [
    { name: this.props.t("Account Groups"), cmd: "RepoAccountGroup" },
    { name: this.props.t("Actions"), cmd: "RepoActionCatalog" },
    { name: this.props.t("Address Types"), cmd: "RepoAddressTypeCatalog" },//t
    { name: this.props.t("Aml Risk Class"), cmd: "RepoAmlRiskCatalog" },//t
    { name: this.props.t("Areas Served"), cmd: "RepoAreaServedCatalog" },//t
    { name: this.props.t("Bank Account Types"), cmd: "RepoBankAccountTypeCatalog" },//t
    { name: this.props.t("Banks"), cmd: "RepoBank" },
    { name: this.props.t("Benefits"), cmd: "RepoBenefit" },
    { name: this.props.t("Branches"), cmd: "RepoBranch" },
    { name: this.props.t("Business Types"), cmd: "RepoBusinessTypeCatalog" },
    { name: this.props.t("Channels"), cmd: "RepoChannelCatalog" },//t
    { name: this.props.t("Checkbooks"), cmd: "RepoCheckBook" },
    { name: this.props.t("CoA Account Types"), cmd: "RepoCatalogAccountType" },//t
    { name: this.props.t("CoA Auxiliary Types"), cmd: "RepoCatalogAuxType" },//t
    { name: this.props.t("Cities"), cmd: "RepoCityCatalog" },
    { name: this.props.t("Claim Expense Types"), cmd: "RepoClaimExpenseCatalog" },
    { name: this.props.t("Claim Stages"), cmd: "RepoClaimStage" },
    { name: this.props.t("Closure Types"), cmd: "RepoClosureTypeCatalog" },//t
    { name: this.props.t("Closure Undo Motives"), cmd: "RepoClosureUndoMotiveCatalog" },
    { name: this.props.t("Communications"), cmd: "RepoCommunicationsCatalog" },//t
    { name: this.props.t("Commission Roles"), cmd: "RepoComRoleCatalog" },//t
    { name: this.props.t("Contact Income Types"), cmd: "RepoContactIncomeType" },
    { name: this.props.t("Contact Roles"), cmd: "RepoRoleCatalog" },//t
    { name: this.props.t("Contract Installment Types"), cmd: "RepoContractInstallmentType" },//t
    { name: this.props.t("Cost Centers"), cmd: "RepoCostCenterCatalog" },
    { name: this.props.t("Coverages"), cmd: "RepoCoverage" },
    { name: this.props.t("Counters"), cmd: "RepoCounter" },
    { name: this.props.t("Countries"), cmd: "RepoCountryCatalog" },
    { name: this.props.t("Currencies"), cmd: "RepoCurrency" },
    { name: this.props.t("Document Status"), cmd: "RepoDocStatusCatalog" }, //t
    { name: this.props.t("EntityAction"), cmd: "RepoEntityAction" },
    { name: this.props.t("Entity State Reason"), cmd: "RepoEntityStateReason" },//t
    { name: this.props.t("Event Reason"), cmd: "RepoEventReasonCatalog" },//t
    { name: this.props.t("External Sources"), cmd: "RepoExternalSourceCatalog" },//t
    { name: this.props.t("Fields"), cmd: "RepoFieldRule" },
    { name: this.props.t("Fund Roles"), cmd: "RepoFundRoleCatalog" },
    { name: this.props.t("Generic"), cmd: "RepoGenericCatalog" },//t
    { name: this.props.t("Id Types"), cmd: "RepoIdTypeCatalog" },//t
    { name: this.props.t("Income-Expense Category"), cmd: "RepoIncomeExpenseCategory" },
    { name: this.props.t("Income Types"), cmd: "RepoIncomeTypeCatalog" },//t
    { name: this.props.t("Insured Events"), cmd: "RepoInsuredEventCatalog" },//t
    { name: this.props.t("Issue Types"), cmd: "RepoIssueTypeCatalog" },//t
    { name: this.props.t("Issue Reasons"), cmd: "RepoIssueReasonCatalog" },//t
    { name: this.props.t("Labels"), cmd: "RepoLabel" },
    { name: this.props.t("Lobs"), cmd: "RepoLob" },//t
    { name: this.props.t("Marital Status"), cmd: "RepoMaritalStatusCatalog" },//t
    { name: this.props.t("Networks"), cmd: "RepoNetworkCatalog" },//t
    { name: this.props.t("Occupations"), cmd: "RepoOccupation" },//t
    { name: this.props.t("Occupation Sectors"), cmd: "RepoOccupationSector" },//t
    { name: this.props.t("Organizations"), cmd: "RepoOrganization" },
    { name: this.props.t("Payment Methods"), cmd: "RepoPaymentMethodCatalog" },//t
    { name: this.props.t("Payment Concepts"), cmd: "RepoPaymentConceptCatalog" },
    { name: this.props.t("Payment Types"), cmd: "RepoPaymentTypeCatalog" },//t
    { name: this.props.t("Pension Account Type"), cmd: "RepoPensionAccountType" },
    { name: this.props.t("Pension Member Status"), cmd: "RepoPensionMemberStatusCatalog" },
    { name: this.props.t("Priorities"), cmd: "RepoPriorityCatalog" },//t
    { name: this.props.t("Provider Types"), cmd: "RepoProviderTypeCatalog" },//t
    { name: this.props.t("Reasons"), cmd: "RepoReasonsCatalog" },//t
    { name: this.props.t("Receipt Types"), cmd: "RepoReceiptTypeCatalog" },//t
    { name: this.props.t("Requirements"), cmd: "RepoReqCatalog" },//t
    { name: this.props.t("Reversal Causes"), cmd: "RepoTransferReversalCause" },//t
    { name: this.props.t("Reversal Subcauses"), cmd: "RepoTransferReversalSubcause" },//t
    { name: this.props.t("Relationships"), cmd: "RepoRelationshipCatalog" },//t
    { name: this.props.t("Risk Types"), cmd: "RepoRiskTypeCatalog" },//t
    { name: this.props.t("Risk Class"), cmd: "RepoRiskClassCatalog" },//t
    { name: this.props.t("Sectors"), cmd: "RepoSectorCatalog" },
    { name: this.props.t("Segments"), cmd: "RepoSegmentCatalog" },//t
    { name: this.props.t("Specialties"), cmd: "RepoSpecialtyCatalog" },//t
    { name: this.props.t("States"), cmd: "RepoStateCatalog" },
    { name: this.props.t("SubLobs"), cmd: "RepoSubLob" },//t
    { name: this.props.t("Surcharge Types"), cmd: "RepoSurchargeCatalog" },
    { name: this.props.t("Umbrella Groups"), cmd: "RepoUmbrellaGroupCatalog" },
    { name: this.props.t("Value Added Taxes"), cmd: "RepoValueAddedCatalog" },//t
  ];
  state = {
    settings: {},
  };
  componentDidMount() {
    const initialSettings = {
      colHeaders: true,
      rowHeaders: true,
      contextMenu: true,
      comments: true,
      formulas: true,
      manualColumnResize: true,
    };

    this.table = React.createRef();
    this.setState({ settings: initialSettings });
  }
  onMap(cmd) {
    //parsing array to entity
    let dataTable = this.table.current.hotInstance.getData();
    const headers = this.table.current.hotInstance.getColHeader();
    let model = {};
    //headers defines entity fields
    for (let index = 0; index < headers.length; index++) {
      model[headers[index]] = null;
    }
    //inserting data to model array
    let modelData = [];
    const keys = Object.keys(model);
    for (let index = 0; index < dataTable.length; index++) {
      let nuevo = { ...model };
      for (let j = 0; j < keys.length; j++) {
        if (typeof dataTable[index][j] == "boolean") {
          //boolean
          nuevo[keys[j]] = dataTable[index][j];
        } else if (typeof dataTable[index][j] == "string" && (dataTable[index][j].toLowerCase() == "true" || dataTable[index][j].toLowerCase() == "false")) {
          //boolean string
          nuevo[keys[j]] = dataTable[index][j].toLowerCase() == "true";
        } else if (isNaN(dataTable[index][j])) {
          //string
          nuevo[keys[j]] = dataTable[index][j];
        } else {
          //number
          nuevo[keys[j]] = Number.parseFloat(dataTable[index][j]);
        }
      }
      modelData.push(nuevo);
    }
    console.log(modelData, JSON.stringify(modelData), "BEFORE SEND");
    exe(cmd, { operation: "MERGE", bulkJson: JSON.stringify(modelData) }, null, true).then((r) => {
      if (r.ok) {
        this.onRead(cmd);
      }
    });
  }

  onRead(cmd) {
    exe(cmd, { operation: "GET", showColumnsIfEmpty: true }).then((r) => {
      if (r.ok) {
        const tableData = [];
        const colHeaders = [];
        //title row
        for (let property in r.outData[0]) {
          colHeaders.push(property);
        }
        //data rows
        for (let i = 0; i < r.outData.length; i++) {
          const dataRow = [];
          for (let property in r.outData[i]) {
            dataRow.push(r.outData[i][property]);
          }
          tableData.push(dataRow);
        }
        this.setState({
          cmd: cmd,
          settings: { ...this.state.settings, data: tableData, colHeaders },
        });
      } else message.error(r.msg);
    });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <Select
          style={{ width: 200, marginRight: 5 }}
          placeholder={t("Please select catalog")}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          onSelect={(cmd) => {
            this.onRead(cmd);
          }}>
          {this.catalogs.map((p) => (
            <Select.Option key={p.cmd} value={p.cmd}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
        <Button type="primary" icon={<SaveOutlined />} onClick={() => this.onMap(this.state.cmd)}>
          {t("Save Changes")}
        </Button>
        <HotTable
          style={{ marginTop: 10 }}
          ref={this.table}
          settings={this.state.settings}
          licenseKey="non-commercial-and-evaluation"
          width="100%"
          height="600"
        />
      </div>
    );
  }
}

export default withTranslation()(CatalogEditor);
