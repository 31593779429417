import React from "react";
import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  SaveOutlined,
  StopOutlined,
  ToolOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Select,
  Divider,
  Button,
  Modal,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Badge,
  message,
  Drawer,
  Typography,
  Tooltip,
  Dropdown,
  Menu,
  Radio,
  Space,
} from "antd";
import equal from "fast-deep-equal";
import CptSelect from "./CptSelect";
import moment from "moment";
import ProviderSelect from "./ProviderSelect";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";
import Money from "../Shared/Money";
import Percentage from "../Shared/Percentage";
import { round2 } from "../../Lib/Helpers";
import CustomForm from "../Shared/CustomForm";
const { Column, ColumnGroup } = Table;
const { Text, Title } = Typography;

const ProcedureStatus = [
  { code: 0, name: "Reported", badge: "default" },
  { code: 1, name: "Reserved", badge: "warning" },
  { code: 2, name: "Adjusted", badge: "success" },
  { code: 3, name: "Authorized", badge: "success" },
  { code: 4, name: "Paid", badge: "default" },
  { code: 5, name: "Rejected", badge: "error" },
];

const MatrixField = (props) => {
  const covered = props.record.company;
  const available = props.value;
  const policy = props.policyValue;
  if (!policy) return null;
  return (
    <div>
      {covered == 0 && <StopOutlined style={{ color: "red" }} />}
      {available > covered && covered != 0 && <CheckOutlined style={{ color: "green" }} />}
      {covered == available && covered != 0 && <WarningOutlined style={{ color: "orange" }} />}
      <Tooltip title="Covered">
        <span style={{ marginLeft: 5, fontSize: 9 }}>{covered}</span>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Available Limit">
        <span style={{ fontSize: 9, color: "blue" }}>{available}</span>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Policy">
        <span style={{ fontSize: 9 }}>{policy}</span>
      </Tooltip>
    </div>
  );
};
const MatrixFieldDeductible = (props) => {
  const effective = props.value;
  const policy = props.policyValue;
  if (!policy) return null;
  return (
    <div>
      <Tooltip title="Effective">
        <span style={{ fontSize: 9, color: "blue" }}>{effective}</span>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip title="Policy">
        <span style={{ fontSize: 9 }}>{policy}</span>
      </Tooltip>
    </div>
  );
};

class ProcedureTable extends React.Component {
  state = {
    visible: false,
    data: [],
    benefits: [],
    deductiblePercentage: 0,
    procedureSelected: null,
    customFormKey: 0,
  };
  componentDidMount() {
    this.load(this.props.claimId);
    this.loadBenefits();
  }
  load = (claimId) => {
    if (claimId == 0) {
      return;
    }
    this.setState({ loading: true });
    exe("RepoProcedure", {
      operation: "GET",
      filter: "claimId=" + claimId,
      include: ["ProcedureAdjudication", "ProcedureAdjudication.PolicyBenefit", "ProcedureDescription", "Provider", "Provider.ProviderPrices"],
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  };
  loadBenefits = () => {
    this.setState({ loading: true });
    exe("RepoBenefit", {
      operation: "GET",
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ benefits: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  };
  componentDidUpdate(prevProps) {
    if (!equal(this.props.claimId, prevProps.claimId)) {
      this.load(this.props.claimId);
    }
  }
  handleAdjudicate = () => {
    this.setState({ loading: true });
    exe("Adjudicate", { claimId: this.props.claimId }).then((r) => {
      if (r.ok) {
        this.setState({ loading: false, data: r.outData });
      } else {
        this.setState({ loading: false });
        message.error(r.msg);
      }
    });
  };
  showModal = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
      operation: "ADD",
      procedureSelected: null,
      customFormKey: this.state.customFormKey + 1,
    });
  };
  handleOk = (e) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        //data transform - ini
        values.claimId = this.props.claimId;
        values.from = values.validity[0].format("YYYY-MM-DD");
        values.to = values.validity[1].format("YYYY-MM-DD");
        //custom form
        if (this.state.procedureSelected?.formId && window.customProcedureForm && window.customProcedureForm.instanceContainers[0]) {
          if (window.customProcedureForm.instanceContainers[0].reportValidity()) {
            values.jFormValues = JSON.stringify(window.customProcedureForm.userData);
          } else {
            message.error(this.props.t("Please check custom form validation errors"));
            return;
          }
        }
        //data transform - end

        exe("RepoProcedure", {
          entity: values,
          operation: this.state.operation,
        }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);

            // let data = [];
            // if (this.state.operation == "ADD") {
            //   data = [...this.state.data, r.outData[0]];
            // } else {
            //   data = [...this.state.data];
            //   const index = data.findIndex(p => p.id == values.id);
            //   data[index] = r.outData[0];
            // }
            this.setState({
              visible: false,
              liquidationVisible: false,
              //data
            });
            this.load(this.props.claimId); //reload
          } else {
            message.error(r.msg);
          }
        });

        this.props.onChange([...this.state.data, values]);
      } else {
        message.error(this.props.t("Please fill all required fields"));
      }
    });
  };
  handleRemoveRecord = (record) => {
    this.setState({ loading: true });
    exe("RepoProcedure", { entity: record, operation: "DELETE" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load(this.props.claimId); //reloading table
      } else {
        message.error(r.msg);
      }
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  onPercentageChange = (e) => {
    const values = this.props.form.getFieldsValue();
    const company = (values.total * e.target.value) / 100;
    const insured = values.total - company;

    this.props.form.setFieldsValue({ company: company, insured: insured });
  };
  openRecord = (r) => {
    this.setState({ visible: true, operation: "UPDATE" });
    //data transform - ini
    r.validity = [];
    r.validity[0] = moment(r.start);
    r.validity[1] = moment(r.end);
    //data transform - end
    this.props.form.setFieldsValue({
      ...r,
    });
  };
  handleProcedureSelect = (r) => {
    console.log(r);
    if(r){
      this.props.form.setFieldsValue({ service: r.description });
    }
    this.setState({ procedureSelected: r, customFormKey: this.state.customFormKey + 1 });
  };
  handleRowSelectionChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys: selectedRowKeys });
    console.log(selectedRowKeys);
  };
  handleOpenLiquidation = (r) => {
    //data transform - ini
    r.validity = [];
    r.validity[0] = moment(r.start);
    r.validity[1] = moment(r.end);
    //data transform - end
    console.log(r, "SETTING R VAUES");
    this.setState(
      {
        operation: "UPDATE",
        liquidationVisible: true,
        selectedProcedure: r.cpt,
      },
      () =>
        this.props.form.setFieldsValue({
          ...r,
        })
    );
  };
  handleCloseLiquidation = (record) => {
    this.setState({ liquidationVisible: false });
  };
  handleActionsMenuClick = (e) => {
    console.log(e);
    exe("PayClaim", { claimId: this.props.claimId }).then((r) => {
      this.load(this.props.claimId); //reload
    });
  };
  actionsMenu = (
    <Menu onClick={this.handleActionsMenuClick}>
      <Menu.Item key="1">{this.props.t("Authorize")}</Menu.Item>
      <Menu.Item key="2">{this.props.t("Approve Payment")}</Menu.Item>
    </Menu>
  );

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const BenefitMatrix = this.state.data;
    const values=this.props.form.getFieldsValue();
    const computeTotals=(field,v)=>{
      switch(field){
        case "company":
          this.props.form.setFieldsValue({insured:values.total-v,payable:v-values.copayment-values.deductible});
          break;
        case "insured": //not covered
          this.props.form.setFieldsValue({company:values.total-v,payable:values.total-v-values.copayment-values.deductible});
          break;
        case "deductible":
          this.props.form.setFieldsValue({payable:values.total-values.insured-values.copayment-v,deductiblePercentage:round2(v/values.company*100)});
          break;
          case "deductiblePercentage":
          this.props.form.setFieldsValue({payable:values.total-values.insured-values.copayment-v*values.company/100,deductible:v*values.company/100})
        case "copayment":
          this.props.form.setFieldsValue({payable:values.total-values.insured-values.deductible-v});
          break;
        default:
          break;
      }
    }
    // const BenefitMatrix = this.state.data.map(p => ({
    //   ...p,
    //   ...this.state.benefits.find(b => b.code == p.benefitCode)
    // }));
    return (
      <div>
        <Button type="primary" icon={<PlusOutlined />} onClick={this.showModal} style={{ marginBottom: 5 }}>
          {t("Add")}
        </Button>
        <Button icon={<SafetyCertificateOutlined />} onClick={this.handleAdjudicate} style={{ marginLeft: 5, marginBottom: 5 }} loading={this.state.loading}>
          {t("Auto Adjudicate")}
        </Button>
        <Dropdown overlay={this.actionsMenu}>
          <Button style={{ marginLeft: 5, marginBottom: 5 }}>
            {t("Actions")} <DownOutlined />
          </Button>
        </Dropdown>
        <Modal title={t("Procedure Detail")} width={640} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} >
          <Form layout="vertical">
            {getFieldDecorator("jFormValues")(<Input style={{ display: "none" }} />)}
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("id", {
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label={t("Service duration")}>
                  {getFieldDecorator("validity", {
                    rules: [{ required: true }],
                  })(<DatePicker.RangePicker />)}
                </Form.Item>
                <Form.Item label={t("CPT Code")}>{getFieldDecorator("cpt",{rules:[{required:true}]})(<CptSelect onSelect={this.handleProcedureSelect} autoSelect />)}</Form.Item>
                <Form.Item label={t("Additional Procedure Information")}>
                  {getFieldDecorator("description")(<Input.TextArea placeholder={t("Additional info")} rows={4} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("Medical Provider")}>{getFieldDecorator("providerCode")(<ProviderSelect />)}</Form.Item>
                <Form.Item label={t("Service Name")}>{getFieldDecorator("service")(<Input />)}</Form.Item>
                <Form.Item label={t("Total")}>
                  {getFieldDecorator("total")(<Money currency={this.props.policy.currency} />)}
                </Form.Item>
              </Col>
            </Row>
            {this.state.procedureSelected?.formId&&this.state.visible&&<CustomForm key={this.state.customFormKey} formId={this.state.procedureSelected?.formId} variable="customProcedureForm" value={values.jFormValues} />}
          </Form>
        </Modal>
        <Drawer
          title={t("Liquidation of procedure ") + this.state.selectedProcedure}
          placement="right"
          width={640}
          closable={false}
          onClose={this.handleCloseLiquidation}
          visible={this.state.liquidationVisible}>
          <Form>
            <Form.Item label={t("Affected Coverage")}>
              {getFieldDecorator("coverageCode")(
                <Select allowClear onSelect={(v) => this.setState({ selectedCover: v })}>
                  {this.props.policy.Coverages.map((p) => (
                    <Select.Option value={p.code}>{p.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={t("Affected Benefit")}>
              {getFieldDecorator("benefitCode")(
                <Select allowClear>
                  {this.state.selectedCover &&
                    this.props.policy.Coverages.find((p) => p.code == this.state.selectedCover).Benefits.map((p) => (
                      <Select.Option value={p.code}>{p.name}</Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Liquidation Status")}>
              {getFieldDecorator("status", { initialValue: 0 })(
                <Select allowClear>
                  {ProcedureStatus.map((p) => (
                    <Select.Option value={p.code}>{p.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Rejection Text / Justification")}>{getFieldDecorator("rejectionText")(<Input.TextArea />)}</Form.Item>

            <Row>
              <Col span={8}>
              <Form.Item label={t("Total Cost")}>
                  {getFieldDecorator("total")(
                      <Money currency={this.props.policy.currency} disabled />
                  )}
                </Form.Item>
                <Form.Item label={t("Covered Amount")}>
                  {getFieldDecorator("company")(
                      <Money currency={this.props.policy.currency} onChange={v=>computeTotals("company",v)}   />
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
              </Col>
            </Row>
            <Form.Item label={t("Not Covered Amount")}>
              {getFieldDecorator("insured")(
                  <Money currency={this.props.policy.currency} onChange={v=>computeTotals("insured",v)}/>
              )}
            </Form.Item>
            <Form.Item label={t("Copayment")}>
              {getFieldDecorator("copayment")(
                  <Money currency={this.props.policy.currency} onChange={v=>computeTotals("copayment",v)} />
              )}
            </Form.Item>
            <Space>
              <Form.Item label={t("Deductible")}>
                {getFieldDecorator("deductible")(
                    <Money currency={this.props.policy.currency} onChange={v=>computeTotals("deductible",v)} />
                )}
              </Form.Item>
              <Form.Item label={t("Deductible %")}>
                {getFieldDecorator("deductiblePercentage")(
                    <Percentage onChange={v=>computeTotals("deductiblePercentage",v)} />
                )}
              </Form.Item>
            </Space>
            <Form.Item label={t("Payable Amount")}>
              {getFieldDecorator("payable")(
                 <Money currency={this.props.policy.currency} />
              )}
            </Form.Item>
               
            <Button type="primary" icon={<SaveOutlined />} onClick={this.handleOk}>
              {t("Save")}
            </Button>
          </Form>
        </Drawer>
        <Table
          size="small"
          style={{ marginTop: 10 }}
          dataSource={this.state.data}
          scroll={{ x: true }}
          rowKey="id"

          // rowSelection={{
          //   selectedRowKeys: this.state.selectedRowKeys,
          //   onChange: this.handleRowSelectionChange
          // }}
        >
          <Column
            title={t("Provider")}
            dataIndex="providerCode"
            render={(v) => (
              <Button type="link" onClick={() => (window.location.hash = "#/provider/" + v)}>
                {v}
              </Button>
            )}
          />
          <Column title={t("Date")} dataIndex="from" render={(v) => (v ? v.split("T")[0] : t("N/A"))} />
          <Column
            title={t("CPT Code")}
            dataIndex="cpt"
            render={(v) => (
              <Button type="link" onClick={() => (window.location.hash = "#/procedure/" + v)}>
                {v}
              </Button>
            )}
          />
          <Column title={t("Service Name")} dataIndex="service" />
          <Column
            title={t("Total")}
            dataIndex="total"
            render={(v, r) => {
              const expected = r.ProcedureDescription.expectedCost;
              const average = r.ProcedureDescription.averageCost;
              if ((expected && expected < v) || (average && average < v)) {
                return (
                  <Tooltip title={t("Expected") + ":" + expected + ", " + t("Average") + ":" + average}>
                    <span style={{ color: "red" }}>{v}</span>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={t("Expected") + ":" + expected + ", " + t("Average") + ":" + average}>
                    <span>{v}</span>
                  </Tooltip>
                );
              }
            }}
          />
          <Column title={t("Covered")} dataIndex="company" />
          <Column title={t("Not Covered")} dataIndex="insured" />
          <Column title={t("Deductible")} dataIndex="deductible" />
          <Column title={t("Copayment")} dataIndex="copayment" />
          <Column title={t("Payable")} dataIndex="payable" />
          <Column
            title={t("Status")}
            dataIndex="status"
            //render={v => ProcedureStatus[v].name}
            render={(v) => (
              <span>
                <Badge status={ProcedureStatus[v].badge} />
                {ProcedureStatus[v].name}
              </span>
            )}
          />

          <Column
            title={t("Price")}
            dataIndex="Provider"
            render={(v, r) => {
              if (!v) return null;
              const agreement = v.ProviderPrices.find((p) => p.procedureCode == r.cpt&&new Date(p.start)<=new Date()&&(!p.end||new Date(p.end)>=new Date()));
              if (agreement) {
                if (r.total > agreement.agreedPrice) {
                  return (
                    <Tooltip title={t("Overpriced. Agreed price") + ":" + agreement.agreedPrice}>
                      <Badge status="error" />
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip title={t("On price. Agreed price") + ":" + agreement.agreedPrice}>
                      <Badge status="success" />
                    </Tooltip>
                  );
                }
              } else {
                return (
                  <Tooltip title={t("No provider agreement found for this procedure")}>
                    <Badge status="default" />
                  </Tooltip>
                );
              }
            }}
          />

          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" icon={<FolderOpenOutlined />} onClick={() => this.openRecord(record)}></Button>
                <Divider type="vertical" />
                <Button icon={<DeleteOutlined />} type="link" onClick={() => this.handleRemoveRecord(record)}></Button>
                <Divider type="vertical" />
                <Button icon={<ToolOutlined />} type="link" onClick={() => this.handleOpenLiquidation(record)}></Button>
              </span>
            )}
          />
        </Table>
        <Title level={4}>{t("Benefit Adjudication Matrix")}</Title>
        <Table dataSource={BenefitMatrix} bordered size="small">
          <Column title={t("Code")} dataIndex="cpt" />
          <Column title={t("Cover")} dataIndex="coverageCode" />
          <Column title={t("Benefit")} dataIndex="benefitCode" />
        <Column title={t("Copay")} dataIndex={["ProcedureAdjudication","copay"]} />
          <Column
            title={t("Covered")}
            dataIndex={["ProcedureAdjudication","covered"]}
            render={(v, r) => {
              if (r.ProcedureAdjudication) {
                return <span style={{ fontSize: 10 }}>{v * 100 + "%/" + r.ProcedureAdjudication.PolicyBenefit.covered * 100 + "%"}</span>;
              } else {
                return t("N/A");
              }
            }}
          />

          <ColumnGroup title={t("Limits")}>
            <Column
              title={t("Event")}
              dataIndex={["ProcedureAdjudication","limit"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.limit} />}
            />
            <Column
              title={t("Family")}
              dataIndex={["ProcedureAdjudication","familyLimit"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.familyLimit} />}
            />
            <Column
              title={t("Annual")}
              dataIndex={["ProcedureAdjudication","aLimit"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.aLimit} />}
            />
            <Column
              title={t("Quarter")}
              dataIndex={["ProcedureAdjudication","qLimit"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.qLimit} />}
            />
            <Column
              title={t("Lifetime")}
              dataIndex={["ProcedureAdjudication","lifetimeLimit"]}
              render={(v, r) => (
                <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.lifetimeLimit} />
              )}
            />
          </ColumnGroup>
          <ColumnGroup title={t("Per Occurrence Limit")}>
            <Column
              title={t("Annual")}
              dataIndex={["ProcedureAdjudication","eventaTimes"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.eventaTimes} />}
            />
            <Column
              title={t("Quarter")}
              dataIndex={["ProcedureAdjudication","eventqTimes"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.eventqTimes} />}
            />
            <Column
              title={t("Daily")}
              dataIndex={["ProcedureAdjudication","eventdTimes"]}
              render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.eventdTimes} />}
            />
          </ColumnGroup>
        </Table>
        <Table dataSource={BenefitMatrix} bordered size="small">
          <Column title={t("Code")} dataIndex="cpt" />
          <Column
            title={t("Event Deductible")}
            dataIndex={["ProcedureAdjudication","deductible"]}
            render={(v, r) => <MatrixFieldDeductible value={v} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.deductible} />}
          />
          <Column
            title={t("Annual Deductible")}
            dataIndex={["ProcedureAdjudication","aDeductible"]}
            render={(v, r) => <MatrixFieldDeductible value={v} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.aDeductible} />}
          />
          <Column
            title={t("Quarter Deductible")}
            dataIndex={["ProcedureAdjudication","qDeductible"]}
            render={(v, r) => <MatrixFieldDeductible value={v} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.qDeductible} />}
          />
          <Column
            title={t("Out Of Pocket Annual Limit")}
            dataIndex={["ProcedureAdjudication","outOfPocketAnualLimit"]}
            render={(v, r) => (
              <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.outOfPocketAnualLimit} />
            )}
          />
          <Column
            title={t("Waiting Period")}
            dataIndex={["ProcedureAdjudication","waitingPeriod"]}
            render={(v, r) => <MatrixField value={v} record={r} policyValue={r.ProcedureAdjudication && r.ProcedureAdjudication.PolicyBenefit.waitingPeriod} />}
          />

          <Column title={t("Special Rule")} dataIndex={["ProcedureAdjudication","rule"]} />
        </Table>
      </div>
    );
  }
}

const Wrapped = Form.create()(ProcedureTable);
export default withTranslation()(Wrapped);
