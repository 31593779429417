import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Table, Select, DatePicker, message, Divider, Popconfirm, Tooltip } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import Text from "antd/lib/typography/Text";
import Paragraph from "antd/lib/typography/Paragraph";
import CessionEdit from "./CessionEdit";
import CessionParticipants from "./CessionParticipants";

const ReDataLossPart = (props) => {
  const [t, i18n] = useTranslation();
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRecord, setEditRecord] = useState();

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <div>
      {!editRecord && (
        <div>
          <CSVLink data={data} filename={"cessions.csv"} target="_blank" disabled={data.length == 0}>
            <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
              {t("Download")}
            </Button>
          </CSVLink>
          <Table dataSource={data} loading={loading || props.loading} style={{ marginTop: 5 }} scroll={{ x: true }}>
            <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
            <Table.Column title={t("Occurence")} dataIndex="occurrence" render={(v) => formatDateShort(v)} />
            <Table.Column title={t("Policy")} dataIndex="policyId" />
            <Table.Column title={t("Coverage")} dataIndex="coverageId" render={(v, r) => <Tooltip title={r.cover}>{v}</Tooltip>} />
            <Table.Column title={t("Line")} dataIndex={["Cession","lineId"]} />
            <Table.Column title={t("Claim")} dataIndex="claimId" />

            <Table.Column title={t("Reserved")} dataIndex="reserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>} />
            <Table.Column title={t("Loss")} dataIndex="loss" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />

            <Table.Column title={t("Retained Reserve")} dataIndex="retainedReserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>} />
            <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>} />

            <Table.Column title={t("Ceded Reserve")} dataIndex="cededReserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>} />
            <Table.Column title={t("Ceded Loss")} dataIndex="cededLoss" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>} />
            <Table.Column title={t("Reinstatement")} dataIndex="reinstatementPremium" render={(v, r) => formatMoney(v, r.currency)} />

            <Table.Column title={t("Participant")} fixed="right" dataIndex="participantName" />
            <Table.Column title={t("Split %")} fixed="right" dataIndex="split" />
            <Table.Column title={t("Part Loss")} fixed="right" dataIndex="lossPart" render={(v, r) => formatMoney(v, r.currency)} />
          </Table>
        </div>
      )}
      {editRecord && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setEditRecord(null)} style={{ float: "left" }}>
            {t("Back")}
          </Button>
          <CessionEdit value={editRecord} onEdit={() => props.doReload && props.doReload()} />
        </div>
      )}
    </div>
  );
};

export default ReDataLossPart;
