import React from "react";
import { Button, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ProviderClaimsFundForm from "./ProviderClaimsFundForm";
import { formatMoney } from "../../Lib/Helpers";


const ProviderClaimsFunds = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(props.providerCode){
            load();
        }
    },[props.providerCode])

    const load=()=>{
        setLoading(true);
        exe("RepoClaimsFund",{operation:"GET",filter:`providerCode='${props.providerCode}'`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }


    return <div>
        {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Constitution Amount")} dataIndex="constitutionAmount" render={(v,r)=>formatMoney(v,r.currency)} />

                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Space>
                        <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />

                    </Space>
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <ProviderClaimsFundForm providerCode={props.providerCode} value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
 
    </div>
}

    export default ProviderClaimsFunds;