import React, {useEffect, useState} from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import Money from "../Shared/Money";
import Section from "../Shared/Section";
import {getConfigProfile, round2} from "../../Lib/Helpers";
import {safeGet} from "../../Lib/Dal";
import Percentage from '../Shared/Percentage';

const CessionParticipantForm = (props) => {
    const [contactRoles, setContactRoles] = useState([]);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    const currency=props.cession.currency;
    
    useEffect(()=>{
        if(props.value) props.form.setFieldsValue(props.value);
    },[props.value])

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], profile, []));
        });
    }, []);
    
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onOk&&props.onOk(values);
        })
    }
    const values=props.form.getFieldsValue();

    const onChangeCommission= v=> {
        //calculating percentage
        const commission=v===0?0:v/values.premium*100;
        props.form.setFieldsValue({
            commissionP:round2(commission),
        });
    };
    const onChangeTax= v=> {
        //calculating percentage
        const tax=v===0?0:v/values.premium*100;
        props.form.setFieldsValue({
            taxP:round2(tax),
        });
    };
    const onChangeCommissionP=v=> {
        const commission=values.premium*v/100;
        props.form.setFieldsValue({
            commission:round2(commission),
        });
    };
    const onChangeTaxP=v=> {
            const tax=values.premium*v/100;
            props.form.setFieldsValue({
                tax:round2(tax),
            });
        }
    const onChangePremium=v=> {
        //updating commission and tax according to percentage, if set
        const commission=values.commissionP>0?v*values.commissionP/100:values.commission;
        const tax=values.taxP>0?v*values.taxP/100:values.tax;
        props.form.setFieldsValue({
            commission:round2(commission),
            tax:round2(tax),
        });
    }

    return (
        <div style={{width:400}}>
            {field("id")}
            {field("cessionId")}
            {field("name")}
            {field("lineId")}
            {field("split")}
            {field("currency")}
            {field("liquidationId")}
            <Section text={t("Main info")}>
                <Form.Item label={t("Participant")}>{field("contactId",{rules:[{required:true}]})(<SimpleContactSelect  filter={
                    contactRoles.length === 0 ? undefined 
                        : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `} />)}
                </Form.Item>
                <Form.Item label={t("Sum Insured")}>{field("sumInsured",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Premium")}>{field("premium",{rules:[{required:true}]})(<Money currency={currency} onChange={onChangePremium} />)}</Form.Item>

                <Space>
                            <Form.Item label={t("Commission")}>{field("commission",{rules:[{required:true}]})(<Money currency={currency} onChange={onChangeCommission} max={values.premium} />)}</Form.Item>
                        <Form.Item label={t("Rate")}>
                            {field("commissionP",{rules:[{required:false}]})(<Percentage onChange={onChangeCommissionP} />)}
                        </Form.Item>
                </Space>
                <Space>
                        <Form.Item label={t("Tax")}>{field("tax",{rules:[{required:true}]})(<Money currency={currency} onChange={onChangeTax} max={values.premium}  />)}</Form.Item>
                        <Form.Item label={t("Rate")}>
                        {field("taxP",{rules:[{required:false}]})(<Percentage onChange={onChangeTaxP} />)}
                        </Form.Item>
                </Space>

                <Form.Item label={t("Reserve")}>{field("reserve",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Broker")}>
                    {field("brokerId", { rules: [{ required: false }] })(
                        <SimpleContactSelect filter={
                            contactRoles.length === 0
                                ? undefined
                                : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                        }
                        />
                    )}
                </Form.Item>
                <Button type="primary" icon={<SaveOutlined />} onClick={onSave} >{t("Save")}</Button>
            </Section>
        </div>
    );
};

export default Form.create()(CessionParticipantForm);