import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const OriginSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const data = [
      { code: "CLA", name: t("Claim") },//no producer, claimId
      { code: "POL", name: t("Policy") },//no producer, lifePolicyId
      { code: "COM", name: t("Commission liquidation") },
      { code: "TAX", name:  t("Tax liquidation")},
      { code: "RE", name:  t("Reinsurance liquidation")},
      { code: "REPROFIT", name:  t("Reinsurance profit commission liquidation")},
      { code: "COI", name:  t("Coinsurance liquidation")},
      { code: "ANNUITIES", name:  t("Annuity")},
      { code: "MERGED", name:  t("Merged Payments")},
      { code: "MANUAL", name:  t("Manual Request")},//no producer
      { code: "REINST", name:  t("Reinsurance Reinstatements")},//no producer
      { code: "EXNI", name:  t("Non Insurance Expense")},
      ]

  return (
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
  );
};

export default OriginSelect;
