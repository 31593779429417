import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import ComData from "./ComData";
import ComFilter from "./ComFilter";
import { FilterOutlined } from '@ant-design/icons';
import { Button } from "antd";
import ComLiquidation from "./ComLiquidation";
import { useTranslation } from "react-i18next";

const Commissions = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [t, i18n] = useTranslation();
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items`, showSizeChanger: true });
  const [data, setData] = useState([]);

  const onResults = (records,total) => {
    setData(records);
    setPagination({ ...pagination, total: total, current: pagination.total !== total ? 1 : pagination.current });
  }

  return (
    <div>
      <DefaultPage
        title={t("Commissions")}
        icon="shopping"
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <ComData data={data} pagination={pagination} onChange={pag=>setPagination(pag)} />
        <ComFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={onResults} pagination={pagination} />
      </DefaultPage>
    </div>
  );
};

export default Commissions;
