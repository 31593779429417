import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainInsuredSelect from "../Contact/MainInsuredSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Money from "../Shared/Money";
import {safeValue} from "../../Lib/Dal";

const AddCertificate = (props) => {
  const [t] = useTranslation();
  const [contact, setContact] = useState(null);
  const [multiRiskMode, setMultiRiskMode] = useState(false);
  const field = props.form.getFieldDecorator;

  useEffect(() => {
    //checking if multi risk mode is enabled
    const multiRiskMode = safeValue("Main.multiRiskMode", props.config, false);
    setMultiRiskMode(multiRiskMode);

    //checking if default values are set
    const certificateDefaults = safeValue("Main.certificateDefaults", props.config, {});
    //iterating over each propoerty and setting it as default value
    Object.keys(certificateDefaults).forEach((key) => {
      props.form.setFieldsValue({ [key]: certificateDefaults[key].toString().includes('_')?computeFormula(certificateDefaults[key]):certificateDefaults[key] });
    });


   
  }, []);
  useEffect(() => {
    if(multiRiskMode){
      //if enabled main insured and payer are the same as the group policy
      props.form.setFieldsValue({contactId: props.mainInsuredId, payerId: props.payerId});
      
    }
  }, [multiRiskMode]);

  useEffect(() => {
     //if there is only one certificate group, set it as default
     //in new effect because fields reset after submit
     if(props.groups.length === 1){
      props.form.setFieldsValue({group: props.groups[0]});
    }
  }, [props.visible]);

  const computeFormula=(f)=> {
    try {
      const context = {};
      context.pol = props.policy;
      
      console.log(f, "COMPUTING FORMULA");
      f = f.replaceAll("_", "context.");
      console.log(f, context, "CONTEXT");
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side", error);
      return undefined;
    }
  }

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.Insureds = [{ contactId: values.contactId, role: 0, name: contact.FullName, Contact: contact }];
      props.onSubmit(values);
      props.form.resetFields();
      if(multiRiskMode){
        props.form.setFieldsValue({contactId: props.mainInsuredId, payerId: props.payerId});
      }
    });
  };
  const onContactSelected = (contact) => {
    setContact(contact);
  };
  return (
    <div>
      <Form.Item label={t("Main Insured")}>
        {field("contactId", { rules: [{ required: true }] })(<SimpleContactSelect disabled={multiRiskMode} forceSelect allowNew onSelectContact={(contact) => onContactSelected(contact)} />)}
      </Form.Item>

        <div style={{ display: safeValue("Main.payerVisible", props.config, false) ? "block" : "none" }}>
            <Form.Item label={t("Payer")}>
                {field("payerId", { rules: [{ required: safeValue("Main.payerMandatory", props.config, false) }] })(
                    <SimpleContactSelect allowNew disabled={multiRiskMode} />
                )}
            </Form.Item>
        </div>

      <Form.Item label={t("Certificate group")}>
        {field("group", { rules: [{ required: true }] })(
          <Select style={{ width: "100%" }}>
            {props.groups.map((group) => (
              <Select.Option key={group} value={group}>
                {group}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t("Base Insured Sum")}>{field("insuredSum", { rules: [{ required: true }] })(<Money currency={props.currency} />)}</Form.Item>
      <Form.Item label={t("Description")}>{field("description", { rules: [{ required: false }] })(<Input.TextArea />)}</Form.Item>

      <Button type="primary" onClick={onSubmit} style={{ marginTop: 5 }}>
        {t("Submit")}
      </Button>
    </div>
  );
};

export default Form.create()(AddCertificate);
