import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import {
  DollarOutlined,
  EditOutlined,
  FileOutlined,
  FilterOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Tabs,
  Row,
  Col,
  Switch,
  Input,
  message,
  Button,
  notification,
  Statistic,
  Modal,
} from "antd";
import yaml from "js-yaml";
import ContactSelect from "../Contact/ContactSelect";
import Periodicty from "../Shared/Periodicty";
import Title from "antd/lib/typography/Title";
import { exe } from "../../Lib/Dal";
import ComData from "./ComData";
import ComFilter from "./ComFilter";
import ComLiquidation from "./ComLiquidation";
import ComLiquidationList from "./ComLiquidationList";
import { useTranslation } from "react-i18next";
import ComParticipants from "./ComParticipants";
import AccountSelect from "../Shared/AccountSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Status from "../Shared/Status";
import ComContractChanges from "./ComContractChanges";
import ComTreeSelect from "./ComTreeSelect";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from "../Shared/CodeEditorW";
import { computeFormula } from "../../Lib/Helpers";
const FormItem = (props) => {
  let componentStyle=props.style||{};
  let hidden=false;
  //safely getting field name
if(props.children&&props.children.props&&props.children.props.id){
    const fieldName=props.children.props.id;
    hidden=fieldName?props.isHidden(fieldName):false
    if(hidden){
      console.log("Hiding field",fieldName);
      componentStyle={...componentStyle,display:"none"}
    }
  }
  return <Form.Item {...props} style={componentStyle}>{props.children}</Form.Item>
}
const ComContract = (props) => {
  const [t, i18n] = useTranslation();
  const id = props.form.getFieldValue("id");
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [liquidationVisible, setLiquidationVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState();
  const [reloadComData, doReloadComData] = useState(0);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [fieldRules, setFieldRules] = useState([]);
  const [customValidators, setCustomValidators] = useState([]);

  useEffect(() => {
    if (props.match.params.comContractId !== "0") {
      load(props.match.params.comContractId);
    } else {
      props.form.resetFields();
      setProcess();
    }
  }, [props.match.params.comContractId]);

  useEffect(() => loadFieldRules(), []);

  const save = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //extra fields
      values.config = window.global.myCodeMirror.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.configJson = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      exe("RepoComContract", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        if (r.ok) {
          props.form.setFieldsValue(r.outData[0]);
          if (r.outData[0].Process) setProcess(r.outData[0].Process);
          message.success(r.msg);
          window.location = "#/comContract/" + r.outData[0].id;
        } else {
          if (r.changeRequest) {
            const before = {};
            const after = {};
            r.unauthorized.forEach((element) => {
              before[element] = r.outData[0][element];
              after[element] = r.data.entity[element];
            });
            props.form.setFieldsValue(before); //reverting unauth changes
            window.global.myCodeMirror.setValue(before.config);
            if(after.config) after.configJson =  r.data.entity.configJson; //YAML-JSON sync
            Modal.confirm({
              title: t("Restricted change"),
              content: r.msg,
              onOk() {
                const change = {
                  comContractId: r.outData[0].id,
                  jBefore: JSON.stringify(before),
                  JAfter: JSON.stringify(after),
                  operation: r.changeOperation,
                };
                exe("AddComContractChange", change).then((r) => {
                  if (r.ok) {
                    message.success(r.msg);
                    props.form.setFieldsValue({ Changes: r.outData });
                  } else {
                    message.error(r.msg);
                  }
                });
              },
            });
          } else {
            message.error(r.msg);
          }
        }
      });
    });
  };
  const load = (id) => {
    setLoading(true);
    exe("RepoComContract", { operation: "GET", filter: "id=" + id, include: ["Process", "Changes", "Changes.Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.outData.length == 0) return message.error(`${t("Record")} ${id} ${t("not found")}`);
        props.form.setFieldsValue(r.outData[0]);
        //extra fields
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
        if (r.outData[0].Process) setProcess(r.outData[0].Process);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onResults = (records,total) => {
    setData(records);
    setPagination({ ...pagination, total: total, current: pagination.total !== total ? 1 : pagination.current });
  }

  const values = props.form.getFieldsValue();
  const loadFieldRules = () => {
    exe("RepoFieldRule", { operation: "GET", filter: "entity='ComContract'" }).then((r) => {
      if (r.ok) {
        setFieldRules(r.outData);
      }
    });
  };
  const evalInContext=(js, context)=> {
    return function () {
      return eval(js);
    }.call(context);
  }
  const isHidden= (fieldName) => {
    const field = fieldRules.find((p) => p.field == fieldName);
    if (!field) return false;
    if (field.hidden) return true;
    if(field.hiddenCondition){
      return computeFormula(field.hiddenCondition,{entity:props.form.getFieldsValue()})
    }
    return false;
  };

    const field =(fieldName,config)=>{
      const rules=[];
      if(config&&config.rules) rules.push(...config.rules);

      //custom fields catalog rules
      const fieldRule=fieldRules.find(p=>p.field===fieldName);
      if(fieldRule){
        //required
        if(fieldRule.required){
          if(fieldRule.required.toLowerCase()==="true") rules.push({required:true,message:t(fieldName)+" "+t("is required") });
          else if(fieldRule.required.includes("this.")){
            try {
              const result=evalInContext(fieldRule.required, {entity:values});
              if(result) rules.push({required:true,message:t(fieldName)+" "+t("is required") });
            } catch (error) {
              console.error("Error parsing required rule for " + fieldName, error);
            }
          }
        }

        //front validation
        if(fieldRule.frontValidation){
          try {
            fieldRule.frontValidation=evalInContext(fieldRule.frontValidation, {}); //parsing string to function
            const parsed = fieldRule.frontValidation;
            parsed[0].entity=values;//injecting values to rule
            const validator = parsed[0].validator;
            if (validator && typeof validator === "string" && validator.slice(0, 1) == "_") {
              //custom validator
              const validatorName = validator.split("_").pop().split('"')[0];
              const customValidator = customValidators.find((p) => p.name == validatorName);
              if (customValidator) parsed[0].validator = customValidator.function;
            }
            rules.push(...parsed);
          } catch (error) {
            console.error("Error parsing configured rule for " + fieldName, error);
          }
        }
      }
      return props.form.getFieldDecorator(fieldName,{...config,rules});
  }
  
  return (
    <DefaultPage
      title={t("Commission Contract") + " " + (id ? id : "")}
      icon="snippets"
      loading={loading}
      tags={<Status process={process} reload={() => load(id)} />}
      extra={
        <div>
          <Button icon={<ReloadOutlined />} type="link" onClick={() => load(values.id)} disabled={!values.id}>
            {t("Reload")}
          </Button>
          <Button icon={<FileOutlined />} type="link" onClick={() => (window.location = "#/comContract/0")}>
            {t("New")}
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => save()}>
            {t("Save")}
          </Button>
        </div>
      }>
      {field("processId")}
      <Tabs>
        {/* ////////////////////////////////Contract  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Contract")} key="contract">
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                {field("id")}
                <FormItem isHidden={isHidden} label={t("Name")}> {field("name", { rules: [{ required: true }] })(<Input />)}</FormItem>
                <FormItem isHidden={isHidden} label={t("Contact")}> {field("contactId", { rules: [{ required: true }] })(<SimpleContactSelect allowNew/>)}</FormItem>
                <FormItem isHidden={isHidden} label={t("Periodicity")}> {field("periodicity")(<Periodicty />)}</FormItem>
                <FormItem isHidden={isHidden} label={t("Notes")}>{field("notes")(<Input.TextArea />)}</FormItem>
                  <div style={{ display: "flex",flexDirection:"column",  marginBottom: 4 }}>
                    <FormItem isHidden={isHidden} label={t("Active")}>{field("active", { valuePropName: "checked" })(<Switch disabled={!values.id || (process && process.entityState !== "APROVED")} />)}
                    </FormItem>
                    <FormItem isHidden={isHidden} label={t("Umbrella Contract")}>{field("isUmbrella", { valuePropName: "checked" })(<Switch />)}
                    </FormItem>
                    <FormItem isHidden={isHidden} label={t("Ad-hoc Commission")}>{field("isAdHoc", { valuePropName: "checked" })(<Switch />)}
                    </FormItem>
                  </div>
              </Col>
              <Col span={12}>
               
                <FormItem isHidden={isHidden} label={t("Code")}>{field("code")(<Input />)}</FormItem>
                <FormItem isHidden={isHidden} label={t("Operating Account")}>{field("operatingAccountId", { rules: [{ required: false }] })(<AccountSelect />)}</FormItem>
                <div style={{ display: "flex" }}>
                  <FormItem isHidden={isHidden} label={t("Start")}>{field("start")(<DatePickerW />)}</FormItem>
                  <FormItem isHidden={isHidden} label={t("End")} style={{ marginLeft: 5 }}>{field("end")(<DatePickerW />)}</FormItem>
                </div>
                <FormItem isHidden={isHidden} label={t("Multi Level Scheme")}>{field("comTreeId")(<ComTreeSelect />)}</FormItem>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        {/* ////////////////////////////////Behaviour  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Behaviour")} key="behaviour" forceRender>
          <Title level={4}>{t("Contract Behaviour")}</Title>
          <CodeEditorW />
        </Tabs.TabPane>
        {/* ////////////////////////////////Participants  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Participants")} key="Participants">
          <Title level={4}>{t("Contract Participants")}</Title>
          <ComParticipants contractId={values.id} isUmbrella={values.isUmbrella} />
        </Tabs.TabPane>
        {/* ////////////////////////////////Commissions  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Commissions")} key="commissions">
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: -20 }}>
            <Statistic style={{ marginRight: 40 }} title={t("Current Month")} value={data.currentMonth} />
            <Statistic style={{ marginRight: 40 }} title={t("Last Month")} value={data.lastMonth} />
            <Statistic style={{ marginRight: 40 }} title={t("Total")} value={data.total} />
          </div>
          <Button type="link" icon={<DollarOutlined />} onClick={() => setLiquidationVisible(true)} style={{ float: "left" }} disabled={selected.length == 0}>
            {t("Liquidation")}
          </Button>
          <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)} style={{ float: "left" }}>
            {t("Filter")}
          </Button>
          <Button type="link" icon={<ReloadOutlined />} onClick={() =>doReloadComData(c=>c+1)} style={{ float: "left" }}>
            {t("Reload")}
          </Button>
          <ComData data={data} selectable onSelectChange={(s) => setSelected(s)} comContractId={id} reload={()=>doReloadComData(c=>c+1)} pagination={pagination} onChange={pag=>setPagination(pag)}  />
          <ComFilter
            filter={{ contract: id }}
            visible={filterVisible}
            onClose={() => setFilterVisible(false)}
            setLoading={(v) => setLoading(v)}
            reload={reloadComData}
            onResults={onResults} pagination={pagination}
          />
          <ComLiquidation
            visible={liquidationVisible}
            onClose={(success) => {setLiquidationVisible(false);if(success) doReloadComData(c=>c+1)}}
            selected={selected}
            contract={props.form.getFieldsValue()}
          />
        </Tabs.TabPane>
        {/* ////////////////////////////////Liquidations  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Liquidations")} key="liquidations" disabled={!id}>
          <ComLiquidationList comContractId={id} />
        </Tabs.TabPane>
        {/* ////////////////////////////////Changes  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane
          tab={
            <span>
              <EditOutlined />
              {t("Changes")}
            </span>
          }
          key="changes">
          <Title level={4}>{t("Change Requests")}</Title>
          {field("Changes", {})(<ComContractChanges onRefresh={() => load(id)} />)}
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Form.create()(ComContract);
