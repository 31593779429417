import React, { Component } from "react";

import {
  BranchesOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  ShareAltOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  Tag,
  Popover,
  Button,
  Modal,
  Tooltip,
  message,
  notification,
  Divider,
  Descriptions,
} from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import FormRenderModal from "../FormRenderModal";
import Text from "antd/lib/typography/Text";
import { withTranslation } from "react-i18next";

const ButtonGroup = Button.Group;
const colors = { green: "#87d068", blue: "blue", red: "#f50" };

//props: bool blocked, str blockedMsg, obj process, fn reload
class Status extends Component {
  state = {
    formVisible: false,
    reasonText: null,
    next: 0,
    loading: false,
  };
  evalInContext(js, context) {
    return function () {
      return eval(js);
    }.call(context);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.props.process) return;
    if (!prevProps.process || this.props.process.entryLogic !== prevProps.process.entryLogic) {
      if (this.props.process.entryLogic)
        this.evalInContext(this.props.process.entryLogic, { message: message, notification: notification, exe: exe, process: this.props.process });
    }
  }
  onNext = () => {
    if (this.checkBlocked()) return;
    this.setState({ loading: true });
    let dto = { procesoId: this.props.process.id, estado: "_next" };
    exe("GotoStep", dto, undefined, true).then((r) => {
      this.setState({ loading: false });
      this.props.reload();
    });
  };
  checkBlocked = () => {
    if (this.props.blocked) {
      notification.error({
        message: this.props.t("Unmet requirements"),
        description: this.props.blockedMsg,
      });
      return true;
    }
  };
  onPrevious = () => {
    let dto = { procesoId: this.props.process.id, estado: "_previous" };
    this.setState({ loading: true });
    exe("GotoStep", dto, undefined, true).then((r) => {
      this.setState({ loading: false });
      this.props.reload();
    });
  };
  onFormOk = (f) => {
    //fired when state.next=true
    const dto = { procesoId: this.props.process.id, estado: "_next", userValues: JSON.stringify(f) };
    this.setState({ loading: true });
    exe("GotoStep", dto).then((r) => {
      this.setState({ loading: false });
      if (!r.ok) {
        message.error(r.msg);
        return;
      } else {
        this.setState({ formVisible: false });
        this.props.reload();
      }
    });
  };

  loadEntityStateReason(reasonCode) {
    exe("RepoEntityStateReason", { operation: "GET", filter: `code='${reasonCode}'` }).then((r) => {
      if (r.ok) {
        if (r.outData.length == 0) {
          this.setState({ reasonText: reasonCode });
        } else {
          this.setState({ reasonText: r.outData[0].entityStateReason });
        }
      } else {
        message.error(r.msg);
        this.setState({ reasonText: reasonCode });
      }
    });
  }
  onPopoverVisible(visible) {
    if (visible && this.props.process.entityStateReason && !this.state.reasonText) {
      this.loadEntityStateReason(this.props.process.entityStateReason);
    }
  }

  render() {
    const { t } = this.props;
    if (!this.props.process) {
      //not yet loaded (undefined) or new (undefined) or old data (null)
      return <Tag>{t("No Workflow")}</Tag>;
    }

    let ViewForm = "";
    if (this.props.process.formId) {
      //step has asocieted form
      ViewForm = (
        <Tooltip title={t("Form required by workflow state")}>
          {/* <Button type="danger" icon="warning" onClick={()=>this.setState({formVisible:true})}></Button> */}
          <Button
            loading={this.state.loading}
            style={{ width: "100px" }}
            onClick={() => {
              if (!this.checkBlocked()) this.setState({ formVisible: true });
            }}>
            <WarningOutlined />
            {t("Next")}
            <RightOutlined />
          </Button>
        </Tooltip>
      );
    }

    const userActions = this.props.process && this.props.process.userActions ? JSON.parse(this.props.process.userActions) : [];
    if (userActions.length > 0) {
      //WF blocked. Next step must be triggered by a user action
      ViewForm = (
        <Tooltip title={t("Next step must be triggered by a user action")}>
          <BranchesOutlined />
          {userActions.map((action) => (
            <Tag key={"ac" + action}>{action}</Tag>
          ))}
        </Tooltip>
      );
    }

    const hasForm = ViewForm != "";

    const ended = safeGet(["process", "finalizado"], this.props, false);
    const blockedBy = safeGet(["process", "blockedBy"], this.props, false);

    return (
      <div style={{whiteSpace:"nowrap"}}>
        <Popover
          title={t("Workflow Status")}
          content={
            <div style={{whiteSpace:"nowrap"}}>
              <Button type="link" size="small" icon={<ShareAltOutlined />} onClick={() => (window.location.href = "#/activity/" + this.props.process.id)}>
                {t("Open Workflow")}
              </Button>

              <br />
              {hasForm && userActions.length == 0 && (
                <Button type="link" size="small" icon={<WarningOutlined />} onClick={() => this.setState({ formVisible: true })}>
                  {t("Required form")}
                </Button>
              )}
              {blockedBy && (
                <Text type="warning" style={{ paddingLeft: 7 }}>
                  <WarningOutlined style={{ marginRight: 7 }} />
                  {t("Blocked by process")}
                </Text>
              )}
              <div display="flex">
                <Button size="small" style={{ margin: 5, width: 100 }} onClick={() => this.onPrevious()} loading={this.state.loading}>
                  <LeftOutlined />
                  {t("Previous")}
                </Button>
                <Button disabled={hasForm} type="primary" size="small" style={{ margin: 3, width: 100 }} loading={this.state.loading} onClick={() => this.onNext()}>
                  {t("Next")}
                  <RightOutlined />
                </Button>
              </div>
            </div>
          }>
          <Button type="link">
            {t("Workflow")}
            <DownOutlined />
          </Button>
        </Popover>
        <Popover
          onVisibleChange={(v) => this.onPopoverVisible(v)}
          content={
            <Descriptions size="small" bordered layout="vertical">
              <Descriptions.Item label={t("Workflow State")}>{this.props.process.estado}</Descriptions.Item>
              <Descriptions.Item label={t("Entity State")}>{this.props.process.entityState}</Descriptions.Item>
              <Descriptions.Item label={t("Entity State Reason")}>{t(this.state.reasonText)}</Descriptions.Item>
            </Descriptions>
          }>
          <Tag color={this.props.color ? colors[this.props.color] : this.props.process.finalizado ? colors.green : colors.blue}>
            {this.props.process.estado}
          </Tag>
        </Popover>
        {!hasForm && (
          <Button type="link" style={{ width: "70px" }} onClick={() => (hasForm ? this.setState({ formVisible: true }) : this.onNext())} disabled={ended} loading={this.state.loading}>
            {!this.props.small&&t("Next")}
            <RightOutlined />
          </Button>
        )}
        {ViewForm}

        <Modal
          title={t("Workflow Form")}
          destroyOnClose={true}
          visible={this.state.formVisible}
          onCancel={() => this.setState({ formVisible: false })}
          okButtonProps={{loading:this.state.loading}}
          onOk={() => this.setState({ next: this.state.next + 1 })}>
          <FormRenderModal
            modal
            formId={this.props.process.formId}
            next={this.state.next}
            onFormOk={this.onFormOk}
            valores={this.state.valores}
            processId={this.props.process.id}
          />
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(Status);
